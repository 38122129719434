/* CPT 2.0/2021 CSS Dev Template ~ Chris Gerikh */

@import url("https://use.typekit.net/dxb8qpq.css");

/********
Global UI
********/
body {
    background-color: black;
    background-image: url(https://www.windstreamwholesale.com/wp-content/uploads/2022/04/Waves-AdobeStock_250203850-1536x621.jpg);
    overflow: hidden;
    margin: 0;
    line-height: 1.7 !important;
}

.content * {
    font-family: "montserrat", sans-serif !important;
    font-weight: 400;
    font-style: normal;
}

.shell {
    min-height: 100vh;
    position: relative;
}

/**************
Type/Font/Color
**************/
body, .ts-label {
    font-family: "montserrat", sans-serif !important;
    font-weight: 400;
    font-style: normal;
}

h1, h2, .warning, .top h1, .ring-innr .amt {
    font-family: "montserrat", sans-serif !important;
    font-weight: 300 !important;
    font-style: normal;
}

button, .btn {
    font-family: "montserrat", sans-serif !important;
    font-weight: 400 !important;
    font-style: normal;
}

.h2, h2 {
    font-size: 1.5rem;
}

.left-align {
    text-align: left !important;
}

.testfont {
    font-family: 'MoreLinecons';
}

a {
    text-decoration: none !important;
}

a:focus {
    outline: none;
}

/* Type Size */
.f11, .ftr {
    font-size: 11px;
    font-weight: 600;
}

.f12, .grid .qm-product span, .om-pon span {
    font-size: 12px;
}

.f13 {
    font-size: 13px;
}

.f14, .callout-msg > span {
    font-size: 14px;
}

.f15 {
    font-size: 15px;
}

body, select, .ts-label, .supertip .tip, .crumb, label {
    font-size: 16px;
}

.tile-hdr h2, h3 {
    font-size: 18px;
}

.pagination, .supertip.panel .tip, button, a.btn, .tile-content h2 {
    font-size: 22px;
}

.page-title span {
    font-size: 24px;
}

#manage-tally {
    font-size: 36px;
}

/* Type Style/Weight/Spacing */
a, a:hover {
    text-decoration: none;
}

.pagination a.active {
    text-decoration: underline;
}

strong, h3, .callout-msg > span, .page-title span, .add-quote-panel .tally, .tally-max, div.tour-popover h2, h4, .record-set-bottom span, .rsg-label span {
    font-weight: 400;
}

button, a.btn, #manage-tally {
    font-weight: 400;
}

h2 {
    line-height: 100%;
}

p {
    margin: 0 0 16px 0;
}

.wide-text {
    letter-spacing: 2px;
}

/* Type Colors */
html body {
    color: #75787B
}

body a, button.dark-button, .subcat span, .wgt-content a, .nice-select.dark-select span, .co-ico-link strong {
    color: #ffffff; /* used on dark UI */
}

.link, .light-button, .content a, .nice-select.light-select span {
    color: #753BBD; /* used on light UI */
}

.link:hover, .light-button:hover, .wgt-content a:hover, .section a:hover, .internal a:hover {
    color: #7202E1;
    text-decoration: none;
}

.light-button {
    cursor: pointer;
    color: #753bbd !important;
}

.light-button:hover {
    color: #7202E1 !important;
}

.callout-msg > span, div.tour-popover h2, .rsg-label span, h2.alt-hdr, .ll-timestamp div {
    color: #753BBD; /* pink */
}

.ico-link path,
.txt-ico-link path,
.rollout.dark-rollout svg path,
.corner-ico path,
.subcat .ico path,
a.dark-button svg path,
button.dark-button svg path,
.ll-bracket-tx svg path, .ll-bracket-rx svg path {
    fill: #753BBD;
}

.subcat:hover > .ico path,
.carc path,
.add-toggle svg.ico-check path,
.ico.requested path {
    fill: white; /* white */
}

.ico-link.light-ico path,
.txt-ico-link.light-ico path,
.light-button svg path,
svg.ico-plus path,
.notch-fg svg path {
    fill: #753BBD;
}

a.dark-button:hover svg path, button.dark-button:hover svg path {
    fill: white;
}

.ico-link:hover path,
.txt-ico-link path,
.rollout.dark-rollout svg:hover path,
.corner-ico:hover path {
    fill: white;
}

.ico-link.light-ico:hover path,
.txt-ico-link.light-ico:hover path,
.light-button:hover svg path {
    fill: #7202E1;
}

.btn-cancel:hover svg path, .quote-itm .quote-itm-close:hover path,
.callout-actions .dismiss .ico-close:hover path,
.add-toggle.no-add svg.ico-check path {
    fill: #ff1744; /* red */
}

.top h1 span, span.ok, strong.ok, .green, .supertip.panel strong, .on, .page-title span, .subcat-user span, .subcat-row span.highlight, .circuit-cell-info .id-reference, .circuit-console .account-info strong, .page-title .account-info, .ll-totals .ok {
    color: #6BBE59; /* green */
}

.orange, .devices-panel .views h2, span.issue, strong.issue, .warning, .dc-loading-notice, #manage-tally span, .ring-innr .amt, .pagination a.active, .record-set.alert-set span {
    color: #753BBD; /* orange */
}

.red, span.error, strong.error, .btn-cancel:hover, .off {
    color: #ff1744; /* red */
}

.callout-msg h2 {
    color: #EF9123; /* orange */
}

.subcat:hover > span {
    color: white; /* white */
}

.record-set .record-ico path {
    fill: #202022;
}

.record-set.na .record-ico path {
    fill: #75787b;
}

.black {
    color: black;
}

.white,
.ftr a:hover,
.panel-filters .col,
.supertip.panel td,
.sub-filters .ts-label,
.btn.dark-button:hover,
button.dark-button:hover,
.page-title,
.subcat-nav h2,
.nav-title,
.tab-opt:hover span,
.tile-hdr h2,
.quote-tabs h4,
.callout-msg p,
.tile-content h2,
div.input-field.dark input,
div.input-field.dark span,
.dark-panel h2, .ll-loc, .ll-time, .ll-timestamp span, .widescreen .grid-headings strong {
    color: white;
}

.grid .qm-product span,
.grid .om-pon span,
.subcat-user,
input[type=date] {
    color: #75787b;
}

.add-quote-panel .tally, .tally-max, .add-quote-panel .grid span, .record-set-top h4, .record-set-bottom span {
    color: #202022;
}

.left-0 {
    left: 0 !important;
}

/* Icon Misc */
.ico-pop path {
    fill: #753BBD !important;
}

.ico-pop:hover path {
    fill: #FFFFFF !important;
}

svg path {
    fill: #D0D0D0; /* default fill */
}

.ico {
    max-width: 36px;
    max-height: 36px; /* default */
}

.ico.size-xs {
    transform: scale(.5);
}

.ico.size-s {
    transform: scale(.85);
}

.ico.size-m {
    transform: scale(1.2);
}

.ico.size-l {
    transform: scale(1.8);
}

.ico-link { /*width:36px;*/
    cursor: pointer;
}

.ico-link path, .corner-ico path {
    transition: fill .4s;
}

/******
Helpers
******/
.rel {
    position: relative;
}

.abs {
    position: absolute;
}

.pad1 {
    padding: 10px 0 1% 2%;
}

.pad2 {
    padding: 10px 1% 10px 2%;
}

.c {
    text-align: center;
}

img {
    margin: 0;
    display: inline-block;
    border: 0;
}

.invis {
    visibility: hidden;
}

.bg-w {
    background-color: white;
}

.hide {
    display: none;
}

.b {
    font-weight: 700;
}

.block {
    display: block;
}

.no-trans {
    transition: none;
}

.fr {
    float: right;
}

.fl {
    float: left;
}

.overflow {
    overflow: visible !important;
}

.grad1, .nav .itm.active .trim, .supertip .trim, .pantip .trim, .sub > .trim, .panel-filters .trim, .tile-hdr::before {
    background: #5f277e;
    background: -moz-linear-gradient(to left, #5f277e 0%, #753BBD 100%);
    background: -webkit-linear-gradient(to left, #5f277e 0%, #753BBD 100%);
    background: linear-gradient(to right, #5f277e 0%, #753BBD 100%);
}

.z1 {
    z-index: 1;
}

.z2 {
    z-index: 2;
}

.z3 {
    z-index: 3;
}

.z4 {
    z-index: 4;
}

.z4 {
    z-index: 4;
}

.z5 {
    z-index: 5;
}

.z6 {
    z-index: 6;
}

.z7 {
    z-index: 7;
}

.z8 {
    z-index: 8;
}

.z9 {
    z-index: 9;
}

/*****************
Primary Navigation
*****************/

.corner-nav .corner-ico, .ws-logo {
    cursor: pointer;
}

.nav-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    left: 0;
    z-index: 2;
    background-color: black;
    opacity: 0;
    transition: height .4s ease, opacity .4s;
}

.nav-overlay.show {
    opacity: 0.8;
    height: 100%;
}

.corner-nav {
    background-color: #202022;
    height: 101px;
    position: absolute;
    width: 120px;
}

.topLeft .corner-nav {
    top: 0;
    left: 0;
}

.topRight .corner-nav {
    top: 0;
    right: 0;
}

.bottomLeft .corner-nav {
    bottom: 0;
    left: 0;
}

.bottomRight .corner-nav {
    bottom: 0;
    right: 0;
}

.corner-nav-edge {
    position: absolute;
    width: 256px;
    height: 101px;
    pointer-events: none;
}

.topLeft .corner-nav-edge {
    top: 0;
    right: -256px;
}

.topRight .corner-nav-edge {
    top: 0;
    left: -256px;
}

.bottomLeft .corner-nav-edge {
    bottom: 0;
    right: -256px;
}

.bottomRight .corner-nav-edge {
    bottom: 0;
    left: -256px;
}

.corner-nav-edge subcat {
    width: 256px;
    height: 101px;
}

.corner-nav-edge svg path {
    fill: #202022;
}

.corner-trim {
    position: absolute;
    right: 0;
    width: 130px;
    height: 14px;
    transition: right .4s, left .4s;
}

.topLeft .corner-trim {
    right: 0;
    bottom: -6px;
}

.topRight .corner-trim {
    left: 0;
    bottom: -6px;
}

.bottomLeft .corner-trim {
    right: 0;
    top: -21px;
}

.bottomRight .corner-trim {
    left: 0;
    top: -21px;
}

.topLeft .corner-trim.shift, .bottomLeft .corner-trim.shift {
    right: 30px;
}

.topRight .corner-trim.shift svg, .bottomRight .corner-trim.shift svg { /* left:30px; */
    width: 0;
}

.corner-trim svg {
    width: 130px;
    height: 14px;
}

.corner-trim svg path {
    fill: #292a2c;
}

.topRight .corner-trim,
.bottomRight .corner-trim,
.topRight .corner-trim svg,
.bottomRight .corner-trim svg {
    width: 100%;
}

.corner-border {
    position: absolute;
    height: 8px;
    width: 50%;
    background-color: #292a2c;
}

.topLeft .corner-border {
    right: 0;
    margin-right: 130px;
    bottom: -8px;
}

.topRight .corner-border {
    left: 0;
    bottom: -8px;
    width: 0;
}

.bottomLeft .corner-border {
    right: 0;
    margin-right: 130px;
    top: -8px;
}

.bottomRight .corner-border {
    left: 0;
    top: -8px;
    width: 0;
}

.corner-fg {
    background-color: #292a2c;
    height: 101px;
    position: absolute;
}

.topLeft .corner-fg {
    top: 0;
    left: 0;
}

.topRight .corner-fg {
    top: 0;
    right: 0;
}

.bottomLeft .corner-fg {
    bottom: 0;
    left: 0;
}

.bottomRight .corner-fg {
    bottom: 0;
    right: 0;
}

.corner-fg-edge {
    position: absolute;
    width: 256px;
    height: 101px;
}

.topLeft .corner-fg-edge {
    top: 0;
    right: -255px;
}

.topRight .corner-fg-edge {
    top: 0;
    left: -256px;
}

.bottomLeft .corner-fg-edge {
    bottom: 0;
    right: -255px;
}

.bottomRight .corner-fg-edge {
    bottom: 0;
    left: -256px;
}

.corner-fg-edge svg {
    width: 256px;
    height: 101px;
}

.corner-fg-edge svg path {
    fill: #292a2c;
}

.corner-bg {
    background-color: #753BBD;
    height: 109px;
    position: absolute;
}

.topLeft .corner-bg {
    top: 0;
    left: 0;
}

.topRight .corner-bg {
    top: 0;
    right: 0;
}

.bottomLeft .corner-bg {
    bottom: 0;
    left: 0;
}

.bottomRight .corner-bg {
    bottom: 0;
    right: 0;
}

.corner-bg-edge {
    position: absolute;
    height: 109px;
}

.topLeft .corner-bg-edge {
    top: 0;
    right: -264px;
}

.topRight .corner-bg-edge {
    top: 0;
    left: -264px;
}

.bottomLeft .corner-bg-edge {
    bottom: 0;
    right: -264px;
}

.bottomRight .corner-bg-edge {
    bottom: 0;
    left: -264px;
}

.corner-bg-edge svg {
    width: 264px;
    height: 109px;
}

.corner-bg-edge svg path {
    fill: #753BBD;
}

/* if 10%/dybamic corner width */
/*
.tl-nav-sub { width:calc(80% - 264px); margin-left:calc(-70% + 264px); transition: margin .4s ease; }
.tl-nav-sub.hvr { margin-left:calc(10% - 264px); }
*/
/* if fixed 120 corner width / 264 + 120 = 384 sub offset */
.topLeft .nav-sub,
.bottomLeft .nav-sub {
    width: calc(100% - 264px);
    margin-left: calc(-100% + 384px);
    transition: all .4s ease;
}

.topLeft .nav-sub.hvr,
.bottomLeft .nav-sub.hvr {
    margin-left: -384px;
}

/*
.tr-nav-sub { width:calc(80% - 264px); margin-right:calc(-70% + 264px); transition: margin .4s ease; }
.tr-nav-sub.hvr { margin-right:calc(10% - 264px); }
*/

.topRight .nav-sub,
.bottomRight .nav-sub {
    width: calc(100% - 264px);
    margin-right: calc(-100% + 384px);
    transition: all .4s ease;
}

.topRight .nav-sub.hvr,
.bottomRight .nav-sub.hvr {
    margin-right: -384px;
}

.corner-ico {
    margin: 28px 0 0 32px;
    transform: scale(1.22);
}

.ico-cog.corner-ico {
    margin: 32px 0 0 42px; /* right now 36x36 not 48x48 */
}

.topRight .nav-sub .right-subs,
.bottomRight .nav-sub .right-subs {
    transition-delay: .2s;
    visibility: hidden;
}

.topRight .nav-sub.hvr .right-subs,
.bottomRight .nav-sub.hvr .right-subs {
    visibility: visible;
}

/*****************************
Primary Navigation: Title/Name
*****************************/

.corner-nav .left-nav-title, .corner-nav .right-nav-title {
    position: fixed;
    text-align: center;
    width: 380px;
    z-index: 9;
    visibility: hidden;
    opacity: 0;
}

.topLeft .corner-nav .nav-title {
    right: 150px;
    top: 48px;
}

.topRight .corner-nav .nav-title {
    left: 150px;
    top: 48px;
}

.bottomLeft .corner-nav .nav-title {
    right: 150px;
    bottom: 48px;
}

.bottomRight .corner-nav .nav-title {
    left: 150px;
    bottom: 48px;
}

.corner-nav.hvr, .left-nav-title.hvr, .corner-nav.hvr, .right-nav-title.hvr {
    opacity: 1;
    visibility: visible;
}

/*********************************
Primary Navigation: Sub Navigation
*********************************/

.left-subs {
    margin-left: 700px; /* left nav width of 120 + the parent panel offset of -384px *and* opp side nav width of 120px + additional buffer because of curve UI */
}

.right-subs {
    margin-right: 700px;
}

.right-subs .subcat {
    float: right;
}

/* 2nd level */
.left-subs .subcat {
    float: left;
}

/* 2nd level */
.subcat-nav .subcat {
    float: left;
}

/* 3nd level */

/* this enables/disables sub-sub navigation triggers (flickers) when we initially mouseover and slideout the corners by adding a short delay via js equal to the slideout animation */
.right-subs > .subcat, .left-subs > .subcat {
    pointer-events: none;
}

.subcat.enable-sub {
    pointer-events: all;
    position: relative;
}

.topLeft .nav-sub .subcat-nav .subcat, .topRight .nav-sub .subcat-nav .subcat {
    margin-top: 10px;
}

.subcat {
    width: 120px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    height: 101px;
    position: relative;
    cursor: pointer;
}

.subcat:hover {
    background-color: #202022;
}

.subcat-nav .subcat:hover {
    background-color: #111111;
}

a.subcat {
    display: block;
}

.subcat .ico {
    margin: 22px 0 2px 0; /*defaut margin */
}

.subcat span {
    display: block;
    position: absolute;
    top: 66px;
    width: 88%;
    left: 6%;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 18px;
}

.subcat-nav {
    cursor: auto !important;
    display: none;
    position: absolute;
    height: auto;
    overflow-y: auto;
    background-color: rgba(32, 32, 34, .96);
    width: 383px; /* margin:101px 0 0 -280px; 400px width - width of primary nav 120px = (-)280px */
    text-align: left;
    height: 60%;
    height: calc(100vh - 240px);
}

.topLeft .subcat-nav, .topRight .subcat-nav {
    top: calc(100% - 1px)
}

.bottomLeft .subcat-nav, .bottomRight .subcat-nav {
    bottom: calc(100% - 1px)
}

.subcat:hover .subcat-nav {
    display: block;
    cursor: inherit;
}

.subcat-trim-r {
    position: absolute;
    top: 0;
    left: 0; /*width:120px;*/
    width: 100%;
    height: 10px;
    background-color: #202022;
}

.subcat-trim-l {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 120px);
    height: 8px;
    background-color: #753BBD; /* TEMP!! hide trim for now */
    visibility: hidden;
}

.subcat-nav h2 {
    margin: 24px 0 0 0;
}

.subcat-item {
    padding: 16px 24px 16px 24px;
    border-bottom: 1px solid #222222;
}

.subcat-item span {
    line-height: 24px;
}

.subcat-row {
    padding: 16px 0 0 0;
}

.subcat-nav .subcat {
    margin-left: 12px;
    margin-right: -12px;
}

/* Notifications */
.sub-notifications > div {
    position: absolute;
    text-align: center;
    line-height: 30px;
    top: 3px;
    right: 26px;
    background-color: #753BBD;
    font-weight: 700;
    border: 1px solid #753BBD;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.sub-notifications > div > span {
    display: block;
    top: 24px;
    top: 7px;
    text-align: center;
    font-size: 18px;
    color: white;
}

.bottomRight.brTotl .left-subs {
    margin-left: 250px;
    width: 100%;
    margin-top: -109px;
}

.bottomRight.brTobl .left-subs {
    margin-left: 60px;
    width: 100%;
    margin-top: -109px;
}

.bottomRight.brTotl .subcat-nav {
    right: 0;
}

.topLeft.tlTobr .right-subs {
    margin-right: 500px;
    margin-top: -109px;
}

.bottomLeft.blTobr .right-subs {
    margin-right: 500px;
    margin-top: -109px;
}

.topLeft.tlTobr .right-subs {
    margin-top: 109px;
}

.topLeft.tlTobr .corner-nav:hover .nav-title,
.topLeft.tlTobr .corner-nav .nav-title.hvr,
.bottomLeft.blTobr .corner-nav:hover .nav-title,
.bottomLeft.blTobr .corner-nav .nav-title.hvr {
    right: 100px;
    left: auto;
}

.BL.topLeft .corner-nav-edge,
.BL.topLeft .corner-trim,
.BL.topLeft .corner-fg-edge,
.BL.topLeft .corner-bg-edge,
.TL.bottomLeft .corner-nav-edge,
.TL.bottomLeft .corner-trim,
.TL.bottomLeft .corner-fg-edge,
.TL.bottomLeft .corner-bg-edge {
    transform: rotateX(180deg);
}

.BL.topLeft .corner-trim {
    bottom: -22px;
}

.TL.bottomLeft .corner-trim {
    top: -6px;
}

.BR.bottomLeft .corner-nav-edge,
.BR.bottomLeft .corner-trim,
.BR.bottomLeft .corner-fg-edge,
.BR.bottomLeft .corner-bg-edge,
.BL.bottomRight .corner-nav-edge,
.BL.bottomRight .corner-trim,
.BL.bottomRight .corner-fg-edge,
.BL.bottomRight .corner-bg-edge {
    transform: rotateY(180deg);
}

.BL.bottomRight .corner-trim {
    top: -22px;
}

.BR.topLeft .corner-nav-edge,
.BR.topLeft .corner-trim,
.BR.topLeft .corner-fg-edge,
.BR.topLeft .corner-bg-edge,
.TL.bottomRight .corner-nav-edge,
.TL.bottomRight .corner-trim,
.TL.bottomRight .corner-fg-edge,
.TL.bottomRight .corner-bg-edge {
    transform: rotate(180deg);
}

.BR.topLeft .corner-trim {
    bottom: -22px;
    left: -11px;
}

.TL.bottomRight .corner-trim {
    top: -6px;
}

.BR.topLeft.tlTobr .right-subs {
    position: absolute;
    left: 450px;
    margin: 0;
    top: 110px;
}

/* user details */
.subcat-user {
    position: absolute;
    top: 8px;
    left: -400px;
    width: 400px;
    text-indent: 12px;
    line-height: 46px;
    background-color: #202022;
    transition: left .4s;
}

/* alignment tweaks */
.subcat > .ico.ico-dashboard {
    margin: 28px 0 2px 0;
}

.subcat > .ico.ico-search,
.subcat > .ico.ico-plus {
    margin: 26px 0 2px 0;
}

/* fancy nav corner guide */
.nav-guide {
    position: relative;
    width: 30px;
    height: 30px;
}

.nav-guide div {
    position: absolute;
    width: 13px;
    height: 13px;
    border: 1px solid #777777;
}

.ngb1 {
    top: 0;
    left: 0
}

.ngb2 {
    top: 0;
    right: 0;
}

.ngb3 {
    bottom: 0;
    left: 0;
}

.ngb4 {
    bottom: 0;
    right: 0;
}

.ngb3.ux-highlight {
    border: 2px solid #ff0000;
    bottom: -1px;
    left: -1px;
}

.ngb4.ux-highlight {
    border: 2px solid #ff0000;
    bottom: -1px;
    right: -1px;
}

.corner-guide-bl .nav-guide, .corner-guide-br .nav-guide {
    margin: 10px 0 0 0;
}

.corner-guide-bl:hover .ngb3.ux-highlight {
    animation: .4s linear highlightCorner;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.corner-guide-br:hover .ngb4.ux-highlight {
    animation: .4s linear highlightCorner;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.bottomLeft .corner-bg.ux-highlight {
    animation: .4s linear pulseBg;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.bottomRight .corner-bg.ux-highlight {
    animation: .4s linear pulseBg;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.bottomLeft .corner-bg-edge.ux-highlight path {
    animation: .4s linear highlightCurve;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.bottomRight .corner-bg-edge.ux-highlight path {
    animation: .4s linear highlightCurve;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes pulseBg {
    from {
        background-color: #ff0000;
    }
    to {
        background-color: #ff9e2e;
    }
}

@keyframes highlightCorner {
    from {
        border-color: #ff0000;
    }
    to {
        border-color: #ff9e2e;
    }
}

@keyframes highlightCurve {
    from {
        fill: #ff0000;
    }
    to {
        fill: #ff9e2e;
    }
}


/*****
Header
*****/
.hdr {
    width: 100%;
    height: 79px;
    top: 0;
    left: 0;
    transition: top .2s linear;
}

.hdr-dim {
    width: 100%;
    height: 79px;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: black;
    opacity: .5;
    z-index: 1;
}

.midsection .crumb {
    display: block;
    text-align: center;
    margin: 8px 0 -4px 0;
}

.midsection .page-title {
    display: block;
    text-align: center;
    margin: 24px 0 46px 0;
    opacity: 1;
    transition: opacity .4s;
}

.crumb + .page-title {
    margin: 0 0 36px 0;
}

.page-title img {
    height: 80px;
    transition: height .2s;
}

.crumb span {
    display: inline-block;
    width: 50px;
}

.page-title.alt-hdr {
    color: #ed2863;
}

.page-title.alt-hdr, h2.alt-hdr, .ll-time, .grid-presets button {
    font-family: "montserrat", sans-serif !important;
    font-weight: 400;
    font-style: normal;
}

.tab-view .section {
    position: relative;
}

/*********
Midsection
*********/

.midsection {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: auto;
    margin-bottom: 0;
    width: 100%; /*height:55%;*/
    background-color: rgba(0, 0, 0, 0.8); /*margin-top:167px;*/
    margin-top: 0px;
    overflow-x: hidden;
    overflow-y: auto;
    transition: margin-top .2s linear, margin-bottom .2s linear;
}

.mid-base {
    position: absolute;
    bottom: 0;
    left: 0;
    background: url(../images/mask-base.png) bottom repeat-x;
    width: 100%;
    height: 20px;
    z-index: 9;
    pointer-events: none;
}

.midsection { /*transition: height .2s linear, margin-top .2s linear;*/
    transition: margin-top .2s linear, margin-bottom .4s;
}

.midsection.no-height-trans {
    transition: margin-top .2s linear;
}

.warning {
    display: none;
    font-size: 24px;
    padding: 24px;
    text-align: center;
}

/************************
Dashboard: Callout Module
************************/

.callout {
    position: relative;
    width: 80%;
    height: 224px;
    margin: 64px 0 48px 2.7%;
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.65) 75%, rgba(255, 255, 255, 0.1) 100%);
}

.callout-msg {
    padding: 24px;
}

.callout-msg > span {
    text-transform: uppercase;
}

.callout-msg p {
    margin: 12px 0 0 0;
    font-size: 14px;
}

.callout-rhs {
    position: absolute;
    right: -85px;
    top: 0;
    width: 85px;
}

.callout-actions {
    position: relative;
    width: 85px;
    height: 224px;
}

.dismiss {
    position: absolute;
    top: 1px;
    right: 0;
    z-index: 2;
}

.callout-actions .dismiss {
    left: 38px;
}

.cbg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: .104;
}

.edge .cbg {
    opacity: .1;
}

.cbg svg {
    width: 85px;
    height: 224px;
}

.cbg svg path {
    fill: white;
}

.carc {
    position: absolute;
    z-index: 2;
    top: 83px;
    left: 0;
}

.carc svg {
    width: 118px;
    height: 160px;
}

.callout-actions .go-external {
    background-color: rgba(255, 255, 255, .1);
    z-index: 3;
    position: absolute;
    bottom: -11px;
    left: 20px;
    width: 135px;
    height: 135px;
    border-radius: 50%;
}

.go-external svg {
    margin: 42px 0 0 44px;
}


/**********************
Internal Tabbed Content
**********************/

.section {
    width: 96%;
    margin: 0 auto;
}

.content {
    background-color: white;
    padding: 24px;
    margin-bottom: 100px;
}

.content.has-actions {
    position: relative;
    padding: 24px 24px 100px 24px;
}

.content .sub-hdr .notch-bg path {
    fill: #DDDDDD;
}

.sub-hdr.alt1 .notch-fg path {
    fill: #753BBD;
}

.sub-hdr.alt2 .notch-fg path,
.ico.ordered path {
    fill: #753BBD; /* purple */
}

.sub-hdr.alt3 .notch-fg path {
    fill: #753BBD; /* purple */
}

.sub-hdr {
    position: relative;
    margin: 64px 0 0 0;
    padding: 0 0 0 12px;
}

.sub-hdr .trim {
    position: absolute;
    top: -36px;
    left: -24px;
    height: 1px;
    background-color: #ccc;
    border-bottom: 10px solid #eee;
    width: 100%;
    padding: 0 48px 0 0;
}

.sub-hdr:first-child {
    margin: 12px 0 0 0;
}

.sub-hdr:first-child .trim {
    display: none;
}

.sub-hdr .nib {
    position: absolute;
    left: -36px;
    top: -18px
}

.sub-hdr .nib-supplement {
    position: absolute;
    left: -48px;
    top: -18px
}

.sub-section {
    padding: 12px 0 0 12px;
}

.sub-section h3 {
    margin-bottom: 12px;
}

.sub-section h3 + .input-field, .sub-section h3 + .labeled-textarea {
    margin-top: 28px;
}

.tab-opt {
    float: left;
}

.tab-opt span {
    display: block;
    margin: 8px 3px 0px 3px;
    /*border-bottom: 4px solid transparent;*/
    border-radius: 7px;
    min-width: 150px;
    text-align: center;
    line-height: 48px;
    position: relative;
    background-color: #292A2C !important;
}

.tab-opt.active span:after {
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 1px;
    background-color: #292A2C !important;
}

.tab-opt:hover span {
    font-weight: 550 !important;
    background-color: #595A5C !important;
}

.tab-opt.active span {
    font-weight: 600 !important;
    background-color: #595A5C !important;
}

.tab-opt.disabled, .tab-opt.disabled:hover, .tab-opt.disabled span, .tab-opt.disabled:hover span {
    color: #75787B !important;
    cursor: not-allowed;
    pointer-events: none;
}

.quote-view .section {
    display: table;
}

.quote-view .tab-nav {
    display: table-row;
}

.quote-view .content, .quote-view .quote-tabs {
    display: table-cell;
    vertical-align: top;
}

.quote-view .content {
    width: 100%;
    height: 500px;
}

.bulk-quote-view .content {
    width: 100%;
    height: 100%;
}

.quote-view .quote-tabs {
    width: 25%;
    background: rgba(255, 255, 255, .1);
    position: relative;
}

.quote-tabs .add-opt {
    text-align: center;
    margin: 24px auto 0 auto;
}

.quote-tabs h4 {
    text-align: right;
    margin: 8px 48px 8px 0;
}

.quote-itm {
    width: 100%;
}

.quote-itm-name {
    text-align: right;
    height: 48px;
    width: auto;
    position: absolute;
    right: 48px;
    left: 0;
    line-height: 48px;
    padding: 0 12px 0 0;
}

.quote-itm-close {
    float: right;
    width: 48px;
    height: 48px;
    text-align: center;
}

.quote-itm-close svg {
    margin-top: 14px;
}

.quote-itm.active .quote-itm-name {
    background-color: white;
    color: #202022;
}

.quote-itm.active .quote-itm-close {
}

.overlay {
    position: fixed;
    z-index: 8;
    top: -1%;
    left: 0;
    width: 100%;
    height: 1%;
    background: rgba(0, 0, 0, .8);
}

.overlay.show {
    height: 100%;
    top: 0;
    transition: height .4s ease;
}

@media screen and (min-width: 676px) {
    .modal-dialog {
        max-width: 600px; /* New width for default modal */
    }
}

.panel-close {
    position: absolute;
    bottom: 48px;
    left: 50%;
    margin-left: -24px;
}

.page-actions {
    position: absolute;
    left: 0;
    bottom: 24px;
    width: 100%;
}

.page-actions > div {
    padding: 0 24px 0 24px;
}

.quote-actions {
    position: absolute;
    right: 0;
    top: 0;
}

.quote-actions .action {
    float: right;
    width: 100px;
    height: 100px;
    background-color: #f1f1f1;
    margin: 24px 24px 0 4px;
    text-align: center;
}

.quote-actions .action:last-child {
    margin: 24px 0 0 4px;
}

.quote-actions .action svg {
    margin: 0 0 0 -36px;
}


/*****************************
quote Manager: Add quote Panel
*****************************/

.add-quote-panel {
    display: none;
    position: fixed;
    z-index: 9;
    top: 0;
    right: 26%;
    height: 100%;
    width: 34.2%;
    background: rgba(255, 255, 255, .98);
}

.add-quote-panel h2 {
    margin: 24px;
}

.add-quote-panel p {
    margin: 0 16px 0px 24px;
}

.add-quote-panel .tally, .add-quote-panel .tally-max {
    margin: 0 24px 0 0;
}

.add-quote-panel .tally-max {
    margin: 30px 24px 13px 0;
}

.add-quote-panel .grid {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 120px;
    top: 220px;
    overflow: hidden;
    margin-top: 0;
}

.add-quote-panel #manage-tally {
    position: relative;
    top: 5px;
    left: 0;
    display: inline-block;
    margin: 0 12px 0 12px;
}

.add-quote-panel .grid .cf .col {
    height: 110px;
    line-height: inherit !important;
    transition: height .4s;
}

.add-quote-panel .grid .cf .col.collapse {
    height: 60px;
}

.add-quote-panel .grid .cf .col.collapse h3 {
    line-height: 40px;
}

.add-quote-panel .grid span .ico {
    position: relative;
    top: -4px;
    left: -2px;
    vertical-align: middle;
    max-height: 34px !important;
    margin: 0 -8px;
}

.qm-panel-grid {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.qm-panel-grid span,
.qm-summary-grid .qm-product span,
.om-summary-grid .om-pon span {
    height: 28px;
    float: none;
    display: inline-block;
    margin: 8px 8px 0px 0;
    background-color: #ddd;
    line-height: 28px;
    border-radius: 5px;
    padding: 0 8px 0 8px;

}

.qm-panel-grid .filter-requested.hide,
.qm-panel-grid .filter-ordered.hide,
.qm-panel-grid .filter-id.hide,
.qm-panel-grid .filter-name.hide {
    display: none;
}

.qm-panel-grid .pagination {
    padding-bottom: 24px;
}

.qm-manage .add-toggle {
    margin: 12px auto 0 auto;
}

.qm-summary-grid .qm-manage .add-toggle {
    margin: 20px auto 0 auto;
}

#manage-tally {
    position: absolute;
    width: 63px;
    height: 63px;
    border-radius: 50%;
    border: 2px solid #ff9e2e;
    text-align: center;
    line-height: 65px;
    top: -56px;
    left: 50%;
    margin-left: -35px;
}

#manage-tally img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
}

#manage-tally span {
    margin: 0;
    line-height: 65px;
}

/* filter sub-panel */
.grid-filters-toggle {
    margin: 24px 0 0 24px;
}

.add-quote-panel .grid-filters {
    position: absolute;
    top: 0;
    width: 90%;
    margin-left: -90%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: none;
}

.add-quote-panel .grid-filters .filters {
    padding: 0 24px 24px 24px;
}

/************
order Manager
************/
.om-summary .section {
    position: relative;
}

.om-search {
    right: 24px;
    top: 60px;
}

.om-search input {
    width: 300px;
}

/*********
Marketing
********/

.marketing {
    text-align: center;
    margin: 24px 0 0 0;
}

.marketing .promo {
    display: inline-block;
    width: 30%;
    margin: 0 1% 0 1%;
    position: relative;
}

.marketing .promo img {
    width: 100%;
    z-index: 1;
    min-height: 300px;
}

.promo .go-external,
.promo .go-internal {
    background-color: rgba(0, 0, 0, .8);
    z-index: 3;
    position: absolute;
    bottom: 6%;
    left: 50%;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    margin-left: -60px;
    transition: bottom .2s;
}

.promo .go-internal svg {
    margin: 43px 0 0 4px;
}

.promo .go-external svg {
    margin: 35px 0 0 6px;
}

.promo:hover .ux-ring {
    opacity: 1;
    transition: opacity .4s;
}

.promo .ux-ring {
    opacity: 0;
    top: inherit;
    bottom: 6%;
    left: 50%;
    right: inherit;
    margin-left: -60px;
    width: 110px;
    height: 110px;
    border-top: 4px solid #753BBD;
    animation: rotate 2s infinite;
    animation-timing-function: linear;
    transition: opacity .4s;
}

.promo:hover svg path {
    fill: #753BBD;
}

/*********
Quote Milestones
*********/

.milestones {
    text-align: center;
    height: 100px;
}

.milestones .step {
    display: inline-block;
    text-align: center;
    position: relative;
}

.milestones .step div {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    border: 1px solid #ccc;
    color: #75787B;
    top: -16px;
    left: 50%;
    margin-left: -15px;
    box-sizing: content-box;
}

.milestones .step.active div {
    background-color: #753BBD;
    color: white;
    font-weight: 550;
    border: 1px solid #753BBD;
}

.milestones .step span {
    display: block;
    position: absolute;
    top: 24px;
    text-align: center;
    font-size: 18px;
}

.milestones .step.active span {
    font-weight: 550;
    color: #753BBD;
}

.milestones .gap {
    display: inline-block;
    border-bottom: 2px solid #ccc;
}

/* sizing: default for 3 steps minimum */
.milestones .gap {
    width: 28%;
    margin: 0 -100px 0 -100px;
    height: 20px;
}

.milestones .step, .milestones .step span {
    width: 300px;
}

/* sizing: 4 steps */
.milestones.four-steps .gap {
    width: 22%;
    margin: 0 -72px 0 -72px;
    height: 20px;
}

.milestones.four-steps .step, .milestones.four-steps .step span {
    width: 200px;
}

/* sizing: 5 steps */
.milestones.five-steps .gap {
    width: 15%;
    margin: 0 -72px 0 -72px;
    height: 20px;
}

.milestones.five-steps .step, .milestones.five-steps .step span {
    width: 200px;
}

/*********
Order Milestones
*********/

.o_milestones {
    text-align: center;
    height: 100px;
}

.o_milestones .step {
    display: inline-block;
    text-align: center;
    position: relative;
}

.o_milestones .step div {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    border: 1px solid #ccc;
    color: #75787B;
    top: -16px;
    left: 50%;
    margin-left: -15px;
    box-sizing: content-box;
}

.o_milestones .step.active div {
    background-color: #753BBD;
    color: white;
    font-weight: 700;
    border: 1px solid #753BBD;
}

.o_milestones .step span {
    display: block;
    position: absolute;
    top: 24px;
    text-align: center;
    font-size: 18px;
}

.o_milestones .step.active span {
    font-weight: 700;
    color: #753BBD;
}

.o_milestones .gap {
    display: inline-block;
    border-bottom: 2px solid #ccc;
}

.o_milestones .gap.active {
    display: inline-block;
    border-bottom: 2px solid #753BBD;
}

/* sizing: default for 3 steps minimum */
.o_milestones .gap {
    width: 15%;
    margin: 0 -100px 0 -100px;
    height: 20px;
}

.o_milestones .step, .o_milestones .step span {
    width: 300px;
}

/* sizing: 4 steps */
.o_milestones.four-steps .gap {
    width: 22%;
    margin: 0 -72px 0 -72px;
    height: 20px;
}

.o_milestones.four-steps .step, .o_milestones.four-steps .step span {
    width: 200px;
}

/* sizing: 5 steps */
.o_milestones.five-steps .gap {
    width: 15%;
    margin: 0 -72px 0 -72px;
    height: 20px;
}

.o_milestones.five-steps .step, .o_milestones.five-steps .step span {
    width: 200px;
}

/* sizing: 6 steps */
.o_milestones.six-steps .gap {
    width: 8%;
    margin: 0 -72px 0 -72px;
    height: 20px;
}

.o_milestones.six-steps .step, .o_milestones.six-steps .step span {
    width: 200px;
}

/*!*********
   Order Milestones
   *********!
!* sizing: default for 3 steps minimum *!
.o_milestones .gap {
    width: 30%;
    margin: 0 -70px 0 -70px;
    height: 20px;
}

.o_milestones .step, .o_milestones .step span {
    width: 200px;
}

!* sizing: 4 steps *!
.o_milestones.four-steps .gap {
    width: 20%;
    margin: 0 -44px 0 -44px;
    height: 20px;
}

.o_milestones.four-steps .step, .o_milestones.four-steps .step span {
    width: 150px;
}

!* sizing: 5 steps *!
.o_milestones.five-steps .gap {
    width: 13%;
    margin: 0 -44px 0 -44px;
    height: 20px;
}

.o_milestones.five-steps .step, .o_milestones.five-steps .step span {
    width: 150px;
}*/


/**********************
Collapse/Expand Content
**********************/

.expander {
    cursor: pointer;
    float: right;
    max-width: 48px;
    margin: -12px 0 0 0; /* will typically be used with notch/nib sub-headings so let's line it up */
}

.collapsed-content {
    visibility: hidden;
    overflow: hidden;
    height: 1px;
    opacity: 0;
    transition: opacity .4s;
}

.collapsed-content.show {
    visibility: visible;
    overflow: visible;
}

/*****
Widget
*****/
.wgt-content {
    margin: 0 0 0 2.7%;
    font-size: 14px;
}

.wgt-content p {
    color: white;
}

.wgt-content.bg-w p {
    color: #75787B;
}

.wgt-content .sub-tile {
    float: left;
    width: 30.83%;
    margin: 24px 2.35% 0 0;
}

/*.wgt-content .sub-tile:nth-child(2) { margin-right:10px; margin-left:10px; }*/
.sub-tile .tile-content {
    min-height: 300px;
    max-height: 300px;
    background-color: #292a2c;
}

.tile-hdr {
    background-color: #1a1a1c;
    min-height: 50px;
    position: relative;
}

.tile-hdr h2 {
    line-height: 49px;
    margin-left: 16px;
}

.tile-hdr::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    top: -2px;
    left: 0;
}

.tile-content {
    padding: 10px 16px 10px 16px;
}

.tile-content.flush {
    padding: 0;
}

/* if we are scrolling (using .os class), some witchcraft to make sure our nib arrows offset/show up despite the hidden overflow */
.tile-content.wgt-news {
    padding: 10px 0 10px 0;
}

.os {
    height: 220px;
    padding: 10px 16px 12px 36px;
    margin: 0 0 0 -20px; /* offsets are for OS/scrollbar */
}

.validator-results {
    height: 300px;
    overflow: hidden;
    padding-right: 24px;
    margin-right: -16px; /* offsets are for OS/scrollbar */
}

/***************
Content "Record"
***************/

.record-set {
    display: inline-block;
    margin: 0 16px 16px 0;
    overflow: hidden;
}

.record-set-top, .record-set-bottom {
    width: 100%;
    line-height: 50px;
}

.record-set-top {
    width: 100%;
    border-bottom: 1px solid #dddddd;
    border-top: 1px solid white;
    border-left: 1px solid white;
    border-right: 1px solid white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: #dddddd;
}

.record-set-bottom {
    border-bottom: 1px solid white;
    border-top: none;
    border-left: 1px solid white;
    border-right: 1px solid white;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: #f1f1f1;

}

.record-set-top h4 {
    display: block;
    margin: 0 16px 0 16px;
}

.record-set-bottom span {
    display: block;
    margin: 0 16px 0 16px;
}

.record-set.with-ico {
    position: relative;
}

.record-set.with-ico .record-set-top {
    margin-left: 100px;
    border-top-left-radius: 24px;
    width: calc(100% - 100px);
}

.record-set.with-ico .record-set-bottom span {
    margin: 0 16px 0 116px;
}

.record-ico {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 100px;
    height: 99px;
    background-color: rgba(255, 255, 255, .8);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
}

.record-ico .ico {
    position: relative;
    top: 50%;
    left: 50%;
    margin-left: -20px;
    margin-top: -18px;
}

.record-set.na {
    opacity: .5;
}

.record-set.na .record-set-top {
    background-color: #eeeeee;
    border-bottom: 1px solid white;
}

.record-set-group {
    width: 100%;
    margin: 24px 0 0 0;
}

.rsg-label, .rsg-brace-left, .rsg-brace-right {
    float: left;
}

.rsg-label {
    width: 29%;
    text-align: center;
    padding: 0px 0 24px 0;
}

.rsg-label span {
    position: relative;
    top: -12px;
}

.rsg-brace-left {
    width: 35%;
    border-top-left-radius: 24px;
    border-left: 1px solid #dddddd;
    border-top: 2px solid #dddddd;
    padding: 12px 0 12px 0;
}

.rsg-brace-right {
    width: 35%;
    border-top-right-radius: 24px;
    border-top: 2px solid #dddddd;
    border-right: 1px solid #dddddd;
    padding: 12px 0 12px 0;
}

/***********
Widget: News
***********/
.news-entry {
    position: relative;
    margin: 12px 0 0 0;
    padding: 0 0 0 12px;
}

.news-entry .nib {
    position: absolute;
    left: -28px;
    top: -15px
}

.wgt-news ul {
    margin-left: 20px;
}

/************************
Widget: Address Validator
************************/
.wgt-validator div.input-field {
    padding-bottom: 12px;
}

.wgt-validator .nice-select {
    margin-bottom: 24px;
}

.wgt-validator .tile-content {
    background: #292a2c url(../images/wgt-validator-bg.png) no-repeat bottom right;
    position: relative;
}

.wgt-validator .validator-search {
    position: absolute;
    bottom: 32px;
    right: 48px;
}

.validator-search div {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border-color: #753BBD;
    border-width: 8px;
    border-style: solid;
    background-color: rgba(0, 0, 0, .5);
}

.validator-search svg {
    margin: 34px 0 0 30px;
}

.validator-results {
    display: block;
}

.validator-results .btn {
    margin-top: 12px;
}

.validator-results .fr {
    margin: 18px 8px 0 0;
}

/*******************
Widget: Recent quote
*******************/
.wgt-recent-quote {
    position: relative;
    overflow: hidden;
}

.wgt-recent-quote svg.ico {
    transform: scale(4);
    position: absolute;
    bottom: 72px;
    left: 42px;
}

.wgt-recent-quote svg.ico path {
    fill: #222224;
}

.wgt-recent-quote span {
    line-height: 36px;
    display: block;
}

.ring-innr, .ring-outr {
    position: absolute;
}

.ring-innr {
    z-index: 2;
    background-color: #111111;
    width: 320px;
    height: 320px;
    border-radius: 50%;
    bottom: -120px;
    right: -60px;
    text-align: center;
}

.ring-outr {
    z-index: 1;
    background-color: #1a1a1c;
    width: 360px;
    height: 360px;
    border-radius: 50%;
    bottom: -135px;
    right: -75px;
}

.ring-innr .amt {
    line-height: 240px;
    font-size: 42px;
    margin: 0 0 0 -42px;
}

.ring-innr .amt span {
    font-size: 16px;
    display: inline-block;
    position: relative;
    top: -16px;
    left: 6px;
}


/************
Notch (nib?)!
************/
.the-notch {
    position: relative;
    width: 36px;
    height: 36px;
}

.the-notch svg {
    width: 36px;
    height: 36px;
}

.notch-fg, .notch-bg {
    position: absolute;
}

.notch-fg {
    z-index: 2;
    top: 0;
    left: 0;
}

.notch-bg {
    z-index: 1;
    top: 3px;
    left: 1px;
}

.notch-bg svg path {
    fill: #202022;
}

.sub-hdr h2 {
    display: inline-block;
}

/***
Grid
***/
.grid .col {
    border-bottom: 1px solid #cccccc;
}

.grid .cf .col {
    height: 76px;
    line-height: 76px; /*overflow:hidden;*/
}

.grid .grid-headings .col {
    height: 60px;
    line-height: 60px;
}

.grid .qm-product > div,
.grid .om-pon > div {
    padding: 12px 0 0 16px;
}

.grid .qm-product > div > a,
.grid .om-pon > div > a {
    display: block;
    line-height: 18px;
    margin-bottom: 6px;
}

.grid .qm-product h3,
.grid .om-pon h3 {
    line-height: 18px;
}

.grid .qm-product span,
.grid .om-pon span {
    display: block;
    float: left;
}

.grid .cf.w100:nth-child(even) .col {
    background-color: #efefef;
}

.pagination {
    margin-top: 12px;
}

.pagination a {
    display: inline-block;
    margin: 12px 12px 0 12px;
}

.pagination select {
    position: relative;
    top: -2px;
}

.pagination .cal-range, .pagination .history {
    opacity: .2;
    transition: opacity .4s;
}

.pagination .cal-range {
    margin: 0 0 0 24px;
    border: 1px solid #ccc;
    border-radius: 12px;
    padding: 6px 12px 6px 0;
}

.pagination .cal-range.active {
    opacity: 1;
}

.pagination .cal-range:hover {
    opacity: 1;
}

.pagination .history {
    border: 1px solid #ccc;
    border-radius: 12px;
}

.pagination .history:hover {
    opacity: 1;
}

.pagination .history.active {
    opacity: 1;
}

.pagination .time-select {
    width: 200px;
    padding: 14px 12px 8px 42px !important;
}

.qm-summary-grid .col span {
    margin: 0px 4px 0 0;
}

.qm-summary-filters {
    margin-right: 200px;
}

.om-summary-grid .col span {
    margin: 0px 4px 4px 0;
}

.grid .col .requested,
.grid .col .ordered {
    top: 11px;
    left: -36px;
}

.qm-summary-grid .sort, .om-summary-grid .sort {
    position: relative;
    top: 12px;
}

.om-summary-grid .col {
    transition: height .4s;
}

.simple-grid .col:first-child div.b {
    padding-left: 12px;
}

.simple-grid .col:last-child div.b {
    padding-right: 12px;
}

.simple-grid .col:first-child span {
    display: block;
    padding-left: 12px;
}

.simple-grid .col:last-child span {
    display: block;
    padding-right: 12px;
}

/***************
Scrollbar Tweaks
***************/
.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
    background: #ff9e2e;
}


/*****
Footer
*****/

.ftr {
    text-align: center;
    margin: 120px 0 0 0;
    padding: 0 0 24px 0;
}


/*********************************************
Supertip (and Paneltip) ~ a cgerikh special ;)
*********************************************/
.supertip, .pantip {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #1a1a1c;
    color: white;
    z-index: 10;
    text-align: left;
    display: none;
    box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, .7);
}

.supertip .trim, .pantip .trim {
    position: relative;
    width: 100%;
    top: 0px;
    height: 3px;
    left: 0;
    z-index: 2;
}

.supertip .base, .pantip .base {
    position: relative;
    width: 100%;
    bottom: 1px;
    height: 3px;
    left: 0;
    z-index: 2;
    background-color: black;
}

.supertip .tip, .pantip .tip {
    padding: 8px 16px 8px 16px;
}

.supertip.static {
    transition: none;
}

.supertip.panel {
    height: auto;
    bottom: 0;
    width: 30%;
    left: 0;
    background-color: rgba(0, 0, 0, .94);
}

.supertip.supersize {
    width: 60%;
}

.supertip.panel.rhs {
    left: inherit;
    right: 0;
}

.supertip .content {
    height: 80%;
    margin-left: 16px;
}

.supertip .content .pad {
    padding: 10px 12px 0 0;
}

/* corner version: UI */
.supertip .tip-ico, .supertip .cor1, .supertip .cor2 {
    display: none;
}

.supertip.corner {
    width: 320px;
    height: 100px;
}

.supertip.corner .base {
    display: none;
    position: absolute;
}

.supertip.corner .trim {
    display: none;
}

.supertip.corner .cor1, .supertip.corner .cor2 {
    display: block;
    position: absolute;
    background-color: #ff9e2e;
}

.supertip.corner .tip-ico {
    display: block;
    position: absolute;
    width: 69px;
    height: 69px;
    border-radius: 50%;
    border-color: #222222;
    border-width: 2px;
    border-style: solid;
    background-color: rgba(0, 0, 0, 1);
}

.supertip.corner .tip-ico img {
    margin: 17px 0 0 17px;
}

/* corner version: positioning */
.supertip.corner.topleft .tip-ico {
    right: -38px;
    top: -38px;
}

.supertip.corner.topleft .cor1 {
    left: 0;
    bottom: 0;
    width: 24px;
    height: 4px;
}

.supertip.corner.topleft .cor2 {
    left: 0;
    bottom: 0;
    width: 4px;
    height: 24px;
}

.supertip.corner.topleft .tip {
    padding: 8px 42px 8px 16px;
}

.supertip.corner.topright .tip-ico {
    top: -38px;
    left: -38px;
}

.supertip.corner.topright .cor1 {
    bottom: 0;
    right: 0;
    width: 24px;
    height: 4px;
}

.supertip.corner.topright .cor2 {
    bottom: 0;
    right: 0;
    width: 4px;
    height: 24px;
}

.supertip.corner.topright .tip {
    padding: 8px 16px 8px 42px;
}

.supertip.corner.bottomleft .tip-ico {
    right: -38px;
    top: -38px;
}

/* for now same as topleft */
.supertip.corner.bottomleft .cor1 {
    left: 0;
    bottom: 0;
    width: 24px;
    height: 4px;
}

.supertip.corner.bottomleft .cor2 {
    left: 0;
    bottom: 0;
    width: 4px;
    height: 24px;
}

.supertip.corner.bottomleft .tip {
    padding: 8px 42px 8px 16px;
}

.supertip.corner.bottomright .tip-ico {
    top: -38px;
    left: -38px;
}

/* for now same as topright */
.supertip.corner.bottomright .cor1 {
    bottom: 0;
    right: 0;
    width: 24px;
    height: 4px;
}

.supertip.corner.bottomright .cor2 {
    bottom: 0;
    right: 0;
    width: 4px;
    height: 24px;
}

.supertip.corner.bottomright .tip {
    padding: 8px 16px 8px 42px;
}


/* the close ico/UI is shard with the Device Panel close UI */
.supertip .close {
    display: none;
}

.supertip.sticky .close {
    display: block;
    position: absolute;
    width: 56px;
    margin: 0 auto 0 48%;
    bottom: 76px;
}

.supertip.panel .base, .supertip.panel .trim {
    display: none;
}

.supertip .close .ux-ring {
    top: 0px;
}

.supertip .close #close-supertip {
    top: 20px;
}

/* paneltip is used inside supertip/inside the popover panels */
.pantip {
    z-index: 11;
}

/* overlay option to block out the content */
.side-overlay {
    width: 70%;
    background-color: rgba(0, 0, 0, .3);
    height: 100%;
    z-index: 7;
    position: fixed;
    backdrop-filter: blur(3px);
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: opacity .4s;
}

.supertip.panel .side-overlay {
    right: 0;
}

.supertip.panel.rhs .side-overlay {
    left: 0;
}

.with-overlay .side-overlay {
    display: block;
    visibility: hidden;
    opacity: 0; /* we set opacity to 1 in the js */
}

@supports not ((backdrop-filter: blur(3px))) {
    .supertip.panel .side-overlay {
        background-color: rgba(0, 0, 0, .8);
    }
}

/******************************************
Custom Toggle Switch ~ a cgerikh special ;)
******************************************/

.toggle-opt {
    display: inline-block;
    vertical-align: top;
}

.toggle-switch {
    width: 20px;
    cursor: pointer;
    display: inline-block;
    margin: 0 12px 0 12px;
}

.ts-bar {
    position: relative;
    width: 12px;
    height: 8px;
    background-color: #75787B;
}

.ts-left-edge, .ts-right-edge {
    height: 8px;
    width: 8px;
    background-color: #75787B;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
}

.ts-left-edge {
    left: -4px;
}

.ts-right-edge {
    right: -4px;
}

.ts-switch {
    position: absolute;
    z-index: 2;
    left: -12px;
    top: -4px;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: #4dc7cc;
    transition: left .2s, background-color .2s;
}

.toggle-switch.on .ts-switch {
    left: 10px;
    /*background-color: #FF1744;*/
    background-color: #753BBD;
    transition: left .2s, background-color .2s;
}

.ts-label {
    display: inline-block;
    margin-bottom: 4px;
    vertical-align: middle;
}

.ts-label-new {
    bottom: -17px;
    left: 60px;
    width: auto;
    color: #753BBD;
}

/**********************************************
Custom Add Toggle Switch ~ a cgerikh special ;)
**********************************************/

.add-toggle {
    width: 36px;
    height: 36px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.add-toggle .ico-check, .add-toggle .ico-plus {
    position: absolute;
    transition: left .2s;
    z-index: 1;
}

.add-toggle .ico-check {
    top: 9px;
    left: 36px;
}

.add-toggle.on .ico-check {
    top: 9px;
    left: 8px;
}

.add-toggle .ico-plus {
    top: 6px;
    left: 6px;
}

.add-toggle.on .ico-plus {
    top: 6px;
    left: -36px;
}

/**********************
Icons & SVG Adjustments
**********************/
.ico.ok path, svg.ok path, .ico.ok svg path {
    fill: #1ebd6d;
}

.ico.issue path, svg.issue path, .ico.issue svg path {
    fill: #ff9e2e;
}

.ico.error path, svg.error path, .ico.error svg path {
    fill: #ff1744;
}

.ico-check {
    width: 24px;
    height: 17px;
}

.ico-alert {
    width: 38px;
    height: 32px;
}

.ico-alert.small-ico {
    transform: scale(.7, .7);
}

.ico-x {
    width: 18px;
    height: 18px;
}

.ico-right {
    width: 18px;
    height: 18px;
}

.ico-left {
    width: 18px;
    height: 18px;
}

.ico-config {
    width: 32px;
    height: 32px;
}

.ico-info {
    width: 36px;
    height: 36px;
}

.ico-device {
    width: 46px;
    height: 21px;
}

.goto-device-settings svg {
    width: 32px;
    height: 32px;
}

.ico-arrow.up {
    transform: rotate(-90deg);
}

.ico-arrow.down {
    transform: rotate(90deg);
}

.ico-arrow.left {
    transform: rotate(180deg);
}

.ico-arrow svg, svg.ico-arrow {
}

/* facing right by default */

.ico-arrow.up.size-xs {
    transform: rotate(-90deg) scale(.5);
}

.ico-arrow.up.size-s {
    transform: rotate(-90deg) scale(.8);
}

.ico-arrow.down.size-xs {
    transform: rotate(90deg) scale(.5);
}

.ico-arrow.down.size-s {
    transform: rotate(90deg) scale(.8);
}

.ico-arrow.left.size-xs {
    transform: rotate(180deg) scale(.5);
}

.ico-arrow.left.size-s {
    transform: rotate(180deg) scale(.8);
}

.ico-arrow.right.size-xs {
    transform: rotate(360deg) scale(.5);
}

.ico-arrow.right.size-s {
    transform: rotate(360deg) scale(.8);
}

.ico-arrow.size-xs {
    transform: scale(.5);
}

/* right */
.ico-arrow.size-s {
    transform: scale(.8);
}

/* right */

.ico-arrow.up.size-m {
    transform: rotate(-90deg) scale(1.2);
}

.ico-arrow.down.size-m {
    transform: rotate(90deg) scale(1.2);
}

.ico-arrow.left.size-m {
    transform: rotate(180deg) scale(1.2);
}

.ico-arrow.size-m {
    transform: scale(1.2);
}

/* right */

.ico-arrow.up.size-l {
    transform: rotate(-90deg) scale(1.8);
}

.ico-arrow.down.size-l {
    transform: rotate(90deg) scale(1.8);
}

.ico-arrow.left.size-l {
    transform: rotate(180deg) scale(1.8);
}

.ico-arrow.size-l {
    transform: scale(1.8);
}

/* right */


svg.ico-logout {
    width: 24px;
}

svg.ico-plus {
    width: 24px;
    height: 24px;
}

svg.ico-add, svg.ico-external {
    width: 48px;
    height: 48px;
    max-width: 48px;
    max-height: 48px;
}

svg.ico-add.ico-close {
    transform: rotate(45deg);
}

img.ico-search {
    width: 26px;
    height: 26px;
}

.hdr svg.ico-logout {
    margin-right: -8px;
}

/* tweak */

.txt-ico-link .ico {
    position: relative;
    top: 11px;
    left: 4px;
}

.page-alert {
}

.page-alert.no-access .ico-alert {
    float: left;
    margin: 12px 12px 12px 0;
}

.page-alert.no-access svg path {
    fill: #FF9E2E;
}

.page-alert span {
    display: inline;
    vertical-align: top;
}

.tile-hdr .sub-filters .ico-link {
    float: right;
    margin: 8px 16px 0 16px;
}

.icon-resources svg {
    margin: 0 24px 0 0;
}

.ico-home {
    width: 34px;
    height: 32px;
}

.ico-insights {
    width: 36px;
    height: 32px;
}

.ico-gear {
    width: 32px;
    height: 32px;
}

.ico-cog {
    width: 36px;
    height: 36px;
}

.ico-monitoring {
    height: 36px;
    width: 34px
}

.ico-calendar {
    max-width: 32px;
}

.ico-web {
    max-width: 34px;
}

.ico-reporting {
    width: 34px;
    height: 36px;
}

.ico-user, svg.ico-user {
    width: 26px;
    height: 32px;
}

.ico-help, .ico-info {
    width: 32px;
    height: 32px;
}

svg.ico-dashboard {
    width: 25px;
    height: 25px;
}

svg.ico-page {
    width: 22px;
    height: 32px;
}

.ico-profile {
    width: 33px;
    height: 32px;
}

/************
Form Controls
************/

select {
    line-height: 1;
    height: 44px;
    -webkit-appearance: none;
}

select:focus, input:focus, textarea:focus {
    outline: 1px dotted #753BBD;
    outline-offset: 10px
}

.labeled-select select {
    width: 100%;
}

select.dark-select,
select.light-select {
    padding: 9px 5px 9px 0px;
    border-top: none;
    border-left: none;
    border-right: none;
}

select.dark-select {
    border-bottom: 1px solid #5F6062;
}

select.light-select {
    border-bottom: 1px solid #A6A7A9;
}

.tile-hdr select.light-select {
    border: none;
}

select.dark-select {
    background: url(../images/svg/ico-select.svg) no-repeat right;
    background-size: 18px 9px;
    background-position-x: 100%;
    color: #753BBD;
    padding-right: 45px;
    font-size: 14px;
}

.utility_contacts .contact_select .contact_dropdown > select.dark-select {
    background-position-x: 96%;
}

select.light-select {
    background: url(../images/svg/ico-select-light.svg) no-repeat right;
    background-size: 18px 9px;
    background-position-x: 100%;
    color: #753BBD;
}

select.time-select,
select.time-select.light {
    padding: 10px 12px 8px 42px;
    border: none;
}

select.time-select {
    background: url(../images/svg/ico-time-sm.svg) no-repeat right;
    background-position-y: 11px;
    background-position-x: 12px;
    background-size: 20px 20px;
    color: #753BBD;
}

select.time-select.light {
    background: url(../images/svg/ico-time-sm-light.svg) no-repeat right;
    background-position-y: 14px;
    background-position-x: 12px;
    background-size: 20px 20px;
    color: #753BBD;
}

select.time-select::after {
    content: '';
    display: block;
    background: white url(../images/svg/ico-down-sm-light.svg) no-repeat right;
    background-size: 80%;
    width: 36px;
    height: 36px;
    position: absolute;
    right: 0;
    pointer-events: none;

}


/* input[type=date] not supported by IE11 */
input[type=date] {
    position: relative;
    border: none;
    width: 170px;
    padding-left: 12px;
}

input[type=date]::after {
    content: '';
    display: block;
    background: white url(../images/svg/ico-calendar-on-light.svg) no-repeat right;
    background-size: 80%;
    width: 36px;
    height: 36px;
    position: absolute;
    right: 0;
    pointer-events: none;
    cursor: pointer;

}

.pagination label {
    margin: 0 0 0 24px;
}

.search {
    border-left: none;
    border-top: none;
    border-right: none;
    -webkit-appearance: none;
    padding: 0px 0 0px 36px;
    line-height: 42px;
    margin-top: 12px;
    outline: none;

}

.search {
    border-bottom: 1px solid #D0D0D0;
    background: url(../images/svg/ico-search-filled-light.svg) no-repeat right white;
    background-position-x: 4px;
    background-size: 24px 24px;
}

.search.dark-search {
    border-bottom: 1px solid #5F6062;
    background: url(../images/svg/ico-search-filled.svg) no-repeat right #1a1a1c;
    background-position-x: 4px;
    background-size: 24px 24px;
    color: white;
}

div.input-field, div.labeled-select, div.labeled-textarea {
    position: relative;
    margin-top: 14px;
}

div.input-field input {
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #5F6062;
    line-height: 38px;
    color: #282829;
}

div.labeled-textarea textarea {
    margin-top: 14px;
    width: 100%;
    height: 64px;
    border: 1px solid #A6A7A9;
}

div.input-field.light input {
    border-bottom: 1px solid #5F6062;
}

div.input-field.dark input {
    border-bottom: 1px solid #5F6062;
    background-color: transparent;
    color: white;
}


div.input-field > span,
div.labeled-select > span,
div.labeled-textarea > span {
    position: absolute;
    top: -14px;
    left: 0;
    color: #75787B;
    font-weight: 600;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

div.input-field.error > span,
div.input-field.error input,
div.labeled-select.error > span,
div.labeled-select.error select {
    color: #FF1744;
}

div.input-field.disabled > span,
div.input-field.disabled input {
    color: #D0D0D0;
    background: none;
}

div.input-field.disabled input {
    border-bottom: 1px solid #D0D0D0;
}

a.btn {
    display: inline-block;
}

div.btn, span.btn {
    display: inline-block;
    cursor: pointer;
}

.btn, button {
    position: relative;
    background-color: transparent;
    border: none;
    line-height: 46px;
    border-radius: 4px;
}

.btn-secondary {
    background-color: transparent !important;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    padding: 0 16px 0 16px !important;
    line-height: 46px !important;
}

button.dark-button, .btn.dark-button {
    border: 2px solid #753BBD;
}

.btn.dark-button:hover, button.dark-button:hover {
    border: 2px solid white;
}

.btn.light-button, button.light-button {
    border: 2px solid #753BBD;
}

.btn.light-button:hover, button.light-button:hover {
    border: 2px solid #7202E1;
}

button.btn-cancel:hover {
    border: 2px solid #ff1744;
}

/* defaults/right-hand side icon defaults */
.btn svg, button svg {
    position: absolute;
    right: 12px;
    top: 0; /* top default */
}

.btn, button {
    padding: 0 16px 0 16px;
}

.btn.ico-button, button.ico-button {
    padding: 0 58px 0 16px;
}

/* lhs = left-hand side (for icons on the left side of the button) */
.ico-button.lhs {
    padding: 0 16px 0 58px;
}

.ico-button.lhs svg {
    right: inherit;
    left: 0;
}

.btn.disabled, button.disabled, .btn.disabled:hover, button.disabled:hover {
    border: 2px solid #D0D0D0;
    color: #D0D0D0;
    cursor: inherit;
    outline: none;
}

.btn.disabled svg path, button.disabled svg path, .btn.disabled:hover svg path, button.disabled:hover svg path {
    fill: #D0D0D0;
}

/* button icon positioning */
.ico-button .ico {
    top: 7px; /*default */
}

.ico-button.rhs .ico {
    right: 10px;
}

.btn .ico-x, button .ico-x {
    /*top: 14px;*/
}

.ico-button .ico-arrow {
    top: 7px;
}

.ico-button .ico-arrow.left {
    top: 4px;
}

.ico-button.lhs .ico-arrow {
    left: 14px;
}

.qm-summary .ico-button.lhs .ico-arrow {
    left: -28px;
    top: 2px;
}

.well-formed div {
    padding-bottom: 16px;
}

.well-spaced div {
    line-height: 40px;
}

/* Misc */
.zip-input {
}

/* Nice Select */
.nice-select {
    line-height: 24px;
    font-family: "montserrat", sans-serif !important;
    font-weight: 400;
    font-style: normal;
    padding-left: 5px;
}

.nice-select.light-select:after {
    border-bottom: 2px solid #753BBD;
    border-right: 2px solid #753BBD;
}


/******
Finesse
******/
.dismiss {
    position: relative;
}

.ux-ring {
    position: absolute;
    z-index: 1;
    top: -34px;
    right: 14px;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border-top: 2px solid #753BBD;
    animation: rotate 2s infinite;
    animation-timing-function: linear;
}

.dismiss .ico-link {
    position: relative;
    z-index: 2;
}

.dismiss .ux-ring {
    top: -5px;
    left: -4px;
    right: inherit;
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg)
    }
    to {
        -webkit-transform: rotate(360deg)
    }
}

.callout-actions .dismiss .ico-close {
    transition: all .4s;
    transform-origin: 50% 50%;
}

.callout-actions .dismiss .ico-close:hover {
    transform: rotate(136deg);
    transform-origin: 50.2% 50.2%; /* there is a weird fill shift visual anomaly - the icon is not actually moving but the fill makes it look like it does! */
}

/*************
Specific Pages
*************/


/*****
Themes
*****/

body.wild-theme .topLeft .corner-bg-edge svg path {
    fill: #ED2863;
}

body.wild-theme .topRight .corner-bg-edge svg path {
    fill: #6BBE59;
}

body.wild-theme .bottomLeft .corner-bg-edge svg path {
    fill: #EF9123;
}

body.wild-theme .bottomRight .corner-bg-edge svg path {
    fill: #753BBD;
}

body.wild-theme .topLeft .corner-bg {
    background-color: #ED2863;
}

body.wild-theme .topRight .corner-bg {
    background-color: #6BBE59;
}

body.wild-theme .bottomLeft .corner-bg {
    background-color: #EF9123;
}

body.wild-theme .bottomRight .corner-bg {
    background-color: #753BBD;
}

body.wild-theme .sub-hdr .trim {
    background-color: black;
    border-bottom: 10px solid #222;
}

body.wild-theme .content {
    background-color: #333;
}

body.wild-theme .sub-hdr h2 {
    color: #ccc;
}

body.wild-theme {
    color: #aaa;
}

body.wild-theme .btn.light-button, body.wild-theme button.light-button {
    border: 2px solid #753BBD;
}

body.wild-theme .light-button {
    color: #753BBD;
}

body.wild-theme .light-button.disabled {
    color: #666;
}

body.wild-theme .btn.light-button.disabled, body.wild-theme button.light-button.disabled {
    border: 2px solid #666;
}

body.wild-theme .ico-link.light-ico path {
    fill: #753BBD;
}

body.wild-theme .ico-sample {
    border: 1px dotted #444 !important;
}

body.wild-theme .ico-sample-alt {
    border: 1px dotted #333 !important;
}

body.wild-theme .ico-sample.scale-example {
    border: 1px dotted #333 !important;
}

body.wild-theme .ico-sample svg path {
    fill: #ccc !important;
}

body.wild-theme div.input-field input, body.wild-theme textarea {
    background: none;
}

body.wild-theme div.input-field.disabled input {
    color: #666;
    border-bottom: 1px solid #666;
}

body.wild-theme div.input-field.disabled > span {
    color: #666;
}

body.wild-theme input[type=date] {
    background-color: transparent;
}

body.wild-theme input[type=date]::after {
    background-color: #333333;
}

body.wild-theme em.code {
    color: #6BBE59;
}


body.wild-theme.superdark .content {
    background-color: rgba(0, 0, 0, .7);
}

body.wild-theme.superdark .sub-hdr .trim {
    background-color: #333;
    border-bottom: 10px solid #222;
}

body.wild-theme.superdark .ico-sample-alt {
    border: none !important;
}

body.wild-theme.superdark .ico-sample.scale-example {
    border: none !important;
}

body.wild-theme.superdark input[type=date]::after {
    background-color: black;
}

/***
Misc
***/

.changelog .cf.w100 {
    margin-bottom: 24px;
}

a.external-link .ico {
    position: relative;
    top: 4px;
}

/*******************************************
WE Connect Loader - CSS/HTML from We Connect
*******************************************/
@keyframes lds-double-ring {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes lds-double-ring {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes lds-double-ring_reverse {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

@-webkit-keyframes lds-double-ring_reverse {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

.lds-double-ring {
    position: relative;
    background-color: transparent !important;
    z-index: 100000 !important;
}

.lds-double-ring div {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 70px;
    left: 70px;
    border-radius: 50%;
    border: 4px solid black;
    border-color: #64dadd transparent #64dadd transparent;
    -webkit-animation: lds-double-ring 0.6s linear infinite;
    animation: lds-double-ring 0.6s linear infinite;
}

.lds-double-ring div:nth-child(2) {
    width: 48px;
    height: 48px;
    top: 76px;
    left: 76px;
    border-color: transparent #1e929c transparent #1e929c;
    -webkit-animation: lds-double-ring_reverse 0.6s linear infinite;
    animation: lds-double-ring_reverse 0.6s linear infinite;
}

.lds-double-ring {
    /*width: 200px !important;
    height: 200px !important;
    -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
    transform: translate(-100px, -100px) scale(1) translate(100px, 100px);*/
    /* fixed UI Team CSS ;) ~cgerikh */
    width: 100px;
    height: 100px;
    -webkit-transform: translate(-100px, -100px) scale(1) translate(50px, 50px);
    transform: translate(-100px, -100px) scale(1) translate(50px, 50px);

}

/* my stuff */
.ww-loader {
    position: fixed;
    top: 50%;
    z-index: 5;
    display: none;
    left: 50%;
    margin-left: -50px;
    margin-top: -60px;
}

.ww-loader.loading {
    display: block;
}

.ww-loader::after {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.45);
}

/*********
Responsive
*********/

@media screen and (max-width: 1680px) {


}

@media screen and (max-width: 1440px) {
    /*****************
    Primary Navigation
    *****************/
    .topLeft .corner-nav, .bottomLeft .corner-nav {
        left: -100px;
    }

    .topRight .corner-nav, .bottomRight .corner-nav {
        right: 0;
        width: 23px;
    }

    .ws-logo {
        width: 52px;
        margin: 10px 0 0 112px;
        z-index: 1;
        position: relative;
    }

    .topLeft .corner-nav .corner-ico,
    .topRight .corner-nav .corner-ico,
    .bottomRight .corner-nav .corner-ico,
    .bottomLeft .corner-nav .corner-ico {
        z-index: 1;
        position: relative;
        width: 30px;
    }

    .topLeft .corner-nav .corner-ico {
        margin: 24px 0 0 114px;
        transform: scale(1);
    }

    .topRight .corner-nav .corner-ico {
        margin: 22px 0 0 -34px;
        transform: scale(1);
    }

    .bottomRight .corner-nav .corner-ico {
        margin: 42px 0 0 -30px;
        transform: scale(1);
    }

    .bottomLeft .corner-nav .corner-ico {
        margin: 42px 0 0 116px;
        transform: scale(1);
    }

    .topLeft .nav-sub, .bottomLeft .nav-sub {
        width: calc(100% - 264px);
        margin-left: calc(-100% + 284px);
    }

    .topLeft .nav-sub.hvr, .bottomLeft .nav-sub.hvr {
        margin-left: -284px;
    }

    .topRight .nav-sub, .bottomRight .nav-sub {
        width: 2%;
        margin-right: 2px;
    }

    .topRight .nav-sub.hvr, .bottomRight .nav-sub.hvr {
        margin-right: 6px;
        width: calc(100% - 550px);
    }

    /***********************
    Primary Navigation: Subs
    ***********************/
    .left-subs {
        margin-left: 500px;
    }

    .right-subs {
        margin-right: 250px;
    }

    .topRight .nav-sub .right-subs .subcat:first-child .subcat-nav {
        width: 144px;
    }

    .topRight .nav-sub .subcat-nav .subcat {
        float: none;
    }

    .topRight .nav-sub .right-subs {
        visibility: hidden;
        transition: unset;
    }

    .topRight .nav-sub.hvr .right-subs {
        visibility: visible;
        width: 100%;
        margin-right: 0;
        position: absolute;
        right: 50%;
        transition-delay: .2s;
    }

    /************************
    Primary Navigation: Title
    ************************/
    .topLeft .corner-nav .left-nav-title, .bottomLeft .corner-nav .left-nav-title {
        right: 100px;
    }

    .topRight .corner-nav .right-nav-title, .bottomRight .corner-nav .right-nav-title {
        left: 100px;
    }

    .topLeft .corner-nav:hover .left-nav-title,
    .bottomLeft .corner-nav:hover .left-nav-title,
    .left-nav-title.hvr {
        right: 100px;
    }

    .topRight .corner-nav:hover .right-nav-title,
    .bottomRight .corner-nav:hover .right-nav-title,
    .right-nav-title.hvr {
        left: 100px;
    }

    /*********
    Marketing
    ********/
    .promo .ico-arrow.size-m {
        transform: scale(1);
    }

    .promo .ico-external {
        transform: scale(.8);
    }

    /******
    Widgets
    ******/
    .news-entry:last-child { /*display: none;*/
    }


    /* old responsive helpers */
    .inline-on2 {
        display: inline-block;
    }

    .hide-on2 {
        display: none !important;
    }

    .stack-bp2 .col {
        float: none;
        width: 100%;
        margin: 0 !important;
    }

    .show-on2 {
        display: block;
    }

    .page-title img {
        height: 60px;
    }


}

@media screen and (max-width: 1280px) {


    /***********************
    Primary Navigation: Subs
    ***********************/
    .topLeft .nav-sub .left-subs,
    .topRight .nav-sub .right-subs,
    .bottomLeft .nav-sub .left-subs,
    .bottomRight .nav-sub .right-subs {
        visibility: hidden;
    }

    .topRight .nav-sub .right-subs {
        position: absolute;
        left: 0;
        right: 0;
        top: 109px;
        transition-delay: unset;
    }

    .topLeft .nav-sub.hvr .left-subs,
    .bottomLeft .nav-sub.hvr .left-subs {
        visibility: visible;
        margin-left: 320px; /* 280 default */
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
    }

    .topLeft .nav-sub.hvr .left-subs {
        top: 109px;
    }

    .bottomLeft .nav-sub.hvr .left-subs {
        bottom: 109px;
    }

    .topRight .nav-sub.hvr .right-subs, .bottomRight .nav-sub.hvr .right-subs {
        visibility: visible;
        margin-left: 0px;
        position: absolute;
        top: 109px;
        background: rgba(0, 0, 0, .1);
        left: 0;
        right: 0;
        width: 100%;
        transition-delay: .2s;
    }

    .bottomRight .nav-sub.hvr .right-subs {
        top: auto;
        bottom: 109px;
        right: 0;
    }

    .topLeft .nav-sub.hvr .left-subs .subcat-nav,
    .topRight .nav-sub.hvr .right-subs .subcat-nav {
        top: 100px;
        bottom: auto;
        margin: 0;
        position: absolute;
        /*height:auto; */
        min-height: 340px;
    }

    .topLeft .nav-sub.hvr .left-subs .subcat-nav {
        left: 0;
    }

    .topRight .nav-sub.hvr .right-subs .subcat-nav {
        right: 0;
    }


    .subcat-trim-l {
        width: 1%;
        top: -109px;
        background-color: white;
        border-left: 4px solid black;
    }

    .topRight .nav-sub.hvr .subcat-trim-l {
        width: 100%;
        transition: width .4s ease;
    }

    .right-subs {
        margin-right: 550px;
    }

    .topRight .nav-sub .subcat-nav .subcat {
        float: left;
    }

    .subcat-user {
        top: 0;
    }

    /************************
    Primary Navigation: Title
    ***********************/
    .topLeft .corner-nav .left-nav-title, .bottomLeft .corner-nav .left-nav-title {
        right: 100px;
    }

    .topRight .corner-nav .right-nav-title, .bottomRight .corner-nav .right-nav-title {
        left: 100px;
    }

    .topLeft .corner-nav:hover .left-nav-title,
    .bottomLeft .corner-nav:hover .left-nav-title,
    .left-nav-title.hvr {
        right: 100px;
    }

    .topRight .corner-nav:hover .right-nav-title,
    .bottomRight .corner-nav:hover .right-nav-title,
    .right-nav-title.hvr {
        left: 100px;
    }

    /*********
    Quote Milestones
    *********/
    /* sizing: default for 3 steps minimum */
    .milestones .gap {
        width: 30%;
        margin: 0 -70px 0 -70px;
        height: 20px;
    }

    .milestones .step, .milestones .step span {
        width: 200px;
    }

    /* sizing: 4 steps */
    .milestones.four-steps .gap {
        width: 20%;
        margin: 0 -44px 0 -44px;
        height: 20px;
    }

    .milestones.four-steps .step, .milestones.four-steps .step span {
        width: 150px;
    }

    /* sizing: 5 steps */
    .milestones.five-steps .gap {
        width: 13%;
        margin: 0 -44px 0 -44px;
        height: 20px;
    }

    .milestones.five-steps .step, .milestones.five-steps .step span {
        width: 150px;
    }

    /********
    Marketing
    ********/
    .promo .go-external,
    .promo .go-internal {
        bottom: 3%;
        width: 90px;
        height: 90px;
        margin-left: -45px;
    }

    .promo .go-internal svg {
        margin: 29px 0 0 2px;
    }

    .promo .go-external svg {
        margin: 19px 0 0 7px;
    }

    .promo .ux-ring {
        bottom: 3%;
        width: 90px;
        height: 90px;
        margin-left: -45px;
    }

    /******
    Widgets
    ******/
    .wgt-content .sub-tile {
        float: none;
        width: 70%;
        margin: 24px auto 0 auto;
    }

    .wgt-content {
        margin: 0;
    }

    .wgt-validator .state-select {
        width: 40%;
    }

    .wgt-validator .zip-input {
        width: 30%;
    }

    /***
    Misc
    ***/
    .qm-panel-grid .qm-product h3 {
        font-size: 16px;
    }

    .om-summary-grid .cf .col {
        height: 106px;
    }

    .om-summary-grid .grid-headings .col {
        height: 60px;
    }

    /* old responsive helpers */
    .inline-on3 {
        display: inline-block;
    }

    .hide-on3 {
        display: none !important;
    }

    .stack-bp3 .col {
        float: none;
        width: 100%;
        margin: 0 !important;
    }

    .show-on3 {
        display: block;
    }

    .collapsed-content.show {
        height: auto !important;
    }

}

@media screen and (max-width: 1024px) {

    .quote-itm-name {
        text-overflow: ellipsis;
    }

    .add-quote-panel {
        right: inherit;
        left: 408px;
        width: 350px;
    }

}

/* Height Adjustments */
@media screen and (max-height: 900px) {

}

@media screen and (max-height: 850px) {

}

@media screen and (max-height: 800px) {

}

@media screen and (max-height: 750px) {
    .nav .itm li {
        line-height: 150%;
    }
}

@media screen and (max-height: 700px) {
    /*
    .midsection { margin-bottom:96px; }
    .midsection.expand { margin-bottom:96px; }
    .midsection.fullpage { margin-bottom:96px; }
    .midsection.fullpage.expand { margin-bottom:96px; }
    */

}

@media screen and (max-height: 650px) {
    /*.midsection { height: 58%; }
    .midsection.expand { height:73%; }
    */
}

@media screen and (max-height: 600px) {
    /*.midsection { height: 58%; }
    .midsection.fullpage { height: 58%; }
    .midsection.expand { height:71%; }
    .midsection.expand.fullpage { height:71%; }
    */
}

@media screen and (max-height: 500px) {
    /*.midsection {  height: 50%; }
    .midsection.fullpage {  height: 50%; }
    .midsection.expand {  height: 60%; }
    .midsection.expand.fullpage {  height: 65%; }
    */
    .wrap {
        display: none !important;
    }

    .warning {
        display: block;
    }

    /* more cleanup */
    .add-quote-panel, .overlay {
        display: none !important;
    }

    /* if a tour was running */
    .popover {
        visibility: hidden;
    }

    .div.tour-cutout, div.tour-mask, .div.tour-popover {
        visibility: hidden;
    }
}


/***********************************************************************************
Tour Framework by K Carbone & updaed by moi (cgerikh) to use CSS transitions instead
***********************************************************************************/
div.tour-cutout {
    position: absolute;
    z-index: 101;
    top: 0;
    left: 0;
    transition: all .4s;
}

div.tour-cutout.raised {
    box-shadow: 2px 2px 6px 0 rgba(13, 26, 52, .8);
    background: none;
}

div.tour-mask {
    position: absolute;
    z-index: 100;
    background-color: #f0f3f9;
    transition: all .4s;
}

div.tour-mask.north {
    top: 0;
    width: 100%;
}

div.tour-mask.east {
    left: 0;
}

div.tour-mask.south {
    top: 0;
    width: 100%;
}

div.tour-mask.west {
    left: 0;
}

div.tour-popover {
    padding: 15px;
    max-width: 350px;
    transition: all .4s;
}

div.tour-popover h2 {
    font-size: 20px;
}

div.tour-popover h3 {
    padding: 0 0 10px 0;
}

div.tour-popover .popover-content {
    font-size: 15px;
    padding: 0 0 15px 0;
}

div.tour-popover .btn {
    padding-top: 3px;
    padding-bottom: 3px;
}

div.tour-popover .btn-back.btn {
    float: right;
}

div.tour-popover .btn-next.btn {
    margin-left: 3px;
    float: right;
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1010;
    /*z-index: 1100;*/
    display: none;
    min-width: 200px;
    max-width: 300px;
    padding: 8px;
    color: #75787b;
    text-align: left;
    text-decoration: none;
    background-color: white;

    text-align: left;
    white-space: normal;

    opacity: 0;
    transition: opacity .4s, top .2s, left .2s;

    -webkit-box-shadow: 0px 0px 3px 2px rgba(000, 000, 000, .2);
    box-shadow: 0px 0px 3px 2px rgba(000, 000, 000, .2);

    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
}


/***********
Misc/Helpers
***********/
.cf:before, .cf:after {
    content: " ";
    display: table;
}

.cf:after {
    clear: both;
}

.cf {
    zoom: 1;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #cccccc;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #cccccc;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #cccccc;
}

.input-field.dark ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
}

.input-field.dark :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
}

.input-field.dark ::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
}


/* scrollbar */
/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #ff9e2e transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    /* border: 1px solid #d5d5d5; */
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: #ff9e2e;
    border-radius: 20px;
    border: 1px solid #ff9e2e;
    height: 250px;
}

::-webkit-scrollbar-thumb:horizontal {
    border-radius: 10px;
}

.color-box {
    width: 130px;
    height: 125px;
    margin: 24px 24px 0 0;
    text-align: center;
    color: white;
    padding: 25px 10px 0 10px;
}

.table-style-wrap .table thead tr th {
    border-top: 0;

}

.table-style-wrap .table thead tr:first-child th:first-child,
.table-style-wrap .table tbody tr:first-child td:first-child {
    width: 25%;
}

.table-style-wrap .table thead tr th:first-child,
.table-style-wrap .table tbody tr td:first-child {
    text-align: left;
}

.table-style-wrap .table tbody tr td,
.table-style-wrap .table thead tr th {
    font-size: 13px;
    color: #666;
    vertical-align: middle;
    text-align: center;
    font-family: "montserrat", sans-serif !important;
    font-weight: 400;
    font-style: normal;
}

.network-table .table tbody tr td,
.userManagement-table .table tbody tr td,
.network-table .table thead tr th,
.userManagement-table .table thead tr th {
    font-size: 14px;
    font-weight: normal;
}

.table-style-wrap .table thead tr th {
    font-weight: 600;
    font-size: 14px;
}

.table-style-wrap .table select,
.table-style-wrap .table select option {
    padding: 10px;
    font-size: 13px;
    color: #753BBD;
    border: 0;
    position: relative;
}

.table-style-wrap .btn-group {
    border-bottom: 1px solid black;
    width: 100%;
}

.dropdown-wrap .dropdown-toggle,
.dropdown-wrap .dropdown-item {
    font-size: 13px;
    color: #753BBD;
}

.dropdown-toggle:hover, .dropdown-toggle:focus, .dropdown-toggle:active {
    box-shadow: none !important;
    outline: none !important;
}

.dropdown-wrap .dropdown-toggle::after {
    background: url(../images/svg/ico-select.svg) no-repeat right;
    background-size: 14px;
    background-position-x: 100%;
    border: 0;
    height: 15px;
    width: 15px;
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
}

.table-style-wrap .table .filter-label {
    margin: 0;
}

.table-style-wrap .table .filter.text-filter {
    border: 0;
    border-bottom: 1px solid #dee2e6;
    border-radius: 0;
    background: url(../images/svg/ico-search-fill.svg) no-repeat left center;
    background-size: 20px;
    padding-left: 30px;
}

.table-style-wrap .table .filter.text-filter:focus {
    box-shadow: none;
    outline: 0;
}

.table-style-wrap .table thead tr th:focus,
.table-style-wrap .table thead tr th:active {
    outline: 0;
    box-shadow: none;
}

.bandwidth-wrap ul {
    list-style-type: none;
    margin-bottom: 0;
}

.bandwidth-wrap ul li {
    display: inline-block;
    color: #75787b;
    font-size: 13px;
    border-radius: 5px;
    margin: 2px 4px 0 0;
    background-color: #ddd;
    padding: 1px 8px;
}

.pagination-table .pagination .page-item a {
    color: #753BBD;
    border: 0;
    padding: 0;
}

.pagination-table .pagination .page-item a:hover {
    background-color: transparent;
}

.pagination-table .pagination .active.page-item a {
    background-color: transparent;
    color: white;
    border-bottom: 2px solid black;
    border-radius: 0;
}

.light-theme .pagination-table .pagination .active.page-item a {
    color: #000;
    border-bottom: 2px solid #FFF;
}


.pagination-table-black .pagination .active.page-item a {
    background-color: transparent;
    color: black;
    border-bottom: 2px solid black;
    border-radius: 0;
}

.pagination-table .pagination .page-item a:focus,
.pagination-table .pagination .page-item a:active {
    outline: 0;
    box-shadow: none;
}

.pagination .page-item[title="previous page"] a,
.pagination .page-item[title="next page"] a {
    font-size: 0;
    height: 30px;
    width: 30px;
    background-position: center center;
    vertical-align: middle;
}

.pagination .page-item[title="previous page"] a {
    background: url(../images/svg/ico-prev-small-theme.svg) no-repeat;
    transform: rotate(-180deg);
}

.pagination .page-item[title="next page"] a {
    background: url(../images/svg/ico-next-small-theme.svg) no-repeat;
}

.pagination-table .pagination .active.page-item:first-child a,
.pagination-table .pagination .active.page-item:last-child a {
    border: 0;
}

.pagination-table .pagination .page-link {
    background-color: transparent;
}

.react-bs-table-sizePerPage-dropdown.dropdown .dropdown-toggle {
    position: relative;
    color: #753BBD;
    border-radius: 0;
}

.react-bs-table-sizePerPage-dropdown.dropdown .dropdown-toggle::after {
    content: 'Results / Page';
    color: #753BBD;
    border: 0;
    vertical-align: bottom;
}

.table-style-wrap thead th {
    position: relative;
}

.table-style-wrap .react-bs-table-sizePerPage-dropdown {
    display: none;
}

.order .dropdown .caret, .order .dropup .caret, .react-bootstrap-table-sort-order .caret,
.react-bootstrap-table-sort-order.dropup .caret, .order .dropup .caret {
    height: 16px;
    width: 16px;
    display: inline-block;
    position: absolute;
    top: 50%;
    margin: 0 !important;
    z-index: 9;
}

.order .dropdown .caret, .order .dropup .caret, .react-bootstrap-table-sort-order .caret {
    background: url(../images/svg/ico-down-white.svg) center center no-repeat;
    transform: translateY(-50%);
    margin-left: 7px !important;
}

.react-bootstrap-table-sort-order.dropup .caret, .order .dropup .caret {
    background: url(../images/svg/ico-down-white.svg) center center no-repeat;
    transform: translateY(-50%) rotate(180deg);
    margin-left: 7px !important;
}

.order .dropup .caret {
    display: none;
}

.expand-cell-header {
    width: 12% !important;
}

.login-form {
    background: white;
    padding: 25px;
    max-width: 300px;
    margin: 0 auto;
}

.login-btn {
    background-color: #753BBD;
    color: white;
    font-size: 14px;
    height: auto;
    line-height: inherit;
    padding: 6px 20px;
}

.login-form input, .login-form input:active, .login-form input:focus {
    outline: none;
    box-shadow: none;
    font-size: 14px;
    color: #5F6062;
}

.login-form a {
    color: #753BBD !important
}

.login-form .form-check-label {
    font-size: 13px;
    margin-left: 10px;
}

.login-page:after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .7);
}

.login-btn img {
    width: 12px;
    margin-right: 6px;
    vertical-align: middle;
}

.login-btn span {
    vertical-align: middle;
}


/* Preeti */
select.dark-select:focus {
    outline: none;
}

.document_section {
    margin: 0 auto;
}

.document_section table thead th {
    border-bottom: none;
}

.document_section tbody tr td {
    border-bottom: 2px solid #cccccc;
    text-align: left !important;
}

.document_section tbody tr:nth-child(1) td {
    border-top: none;
}

.document_section svg {
    width: 15px;
    right: 10% !important;
    left: auto !important;
    top: 25% !important;
}

.document_section td button {
    font-size: 15px;
    padding: 0 58px 0 16px !important;
}

.document_section .bold {
    font-weight: bold;
}

.table-style-wrap #document_section.table tbody tr td,
.table-style-wrap #document_section.table thead tr th {
    padding-top: 15px;
    padding-bottom: 15px;
}

.table-style-wrap #document_section.table thead tr:first-child th:first-child,
.table-style-wrap #document_section.table tbody tr:first-child td:first-child {
    width: 10%;
    padding-top: 0 !important;
}

.table-style-wrap #document_section.table thead tr:first-child th:nth-child(2),
.table-style-wrap #document_section.table tbody tr:first-child td:nth-child(2) {
    width: 45%;
}

.document_section tbody tr td p {
    margin-bottom: 0;
}

/* Utility contact page */
.utility_contacts p {
    font-size: 14px;
    color: #666;
    margin: 8px 0;
}

.utility_contacts .contact_select {
    display: flex;
}

.utility_contacts .contact_select .contact_dropdown {
    display: flex;
    margin-top: 1rem;
    width: 80%;
}

.utility_contacts .contact_select .save_report_sec {
    width: 20%;
    text-align: right;
    margin: auto 0;
    margin-top: 1.2rem;
}

.utility_contacts .contact_select .save_report_sec a {
    font-size: 14px;
}

.utility_contacts .contact_select .save_report_sec svg {
    margin-left: .5rem;
    margin-top: 5px;
    width: 15px;
}

.save_report_sec svg path {
    fill: #753BBD;
}

.utility_contacts .contact_select .full_border {
    border: 1px solid #666;
    border-radius: 1px;
    color: #666;
    padding: 0 5px;
    height: 35px;
    font-size: 14px;
    width: 250px;
    margin-left: 0;
    padding-right: 45px;
}

.utility_contacts .contact_select .full_border option {
    font-size: 14px;
}

.utility_contacts .green_text {
    color: green;
    margin-top: 2rem;
}

.utility_contacts .utility_table.table thead th,
.utility_contacts .utility_table.table tr td {
    text-align: left !important;
}

.utility_contacts .utility_section {
    margin-top: .5rem;
}

.utility_contacts .utility_section .add_contact_btn,
.editContacts .updateContactBtn {
    font-size: 12px;
    float: right;
    color: #753BBD;
    line-height: 35px;
    text-transform: uppercase;
    border: 1.5px solid #753BBD;
    border-radius: 5px;
    font-family: "montserrat", sans-serif !important;
    font-weight: 500;
    font-style: normal;
    margin-bottom: 20px;
}

.editContacts .updateContactBtn {
    background-color: white;
}

.updateContactBtnRow {
    /*flex-direction: row-reverse;*/
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactTypeSubHeadingRow {
    justify-content: space-around;
}

.utility_contacts .utility_table tr svg {
    width: 25px;
}

.utility_contacts p svg {
    width: 15px;
    margin: 0 5px;
}

.utility_contacts .utility_table td a {
    color: #666;
}

.utility_contacts .bandwidth-wrap {
    line-height: 0;
}

.utility_contacts .bandwidth-wrap ul li {
    width: 100%;
    color: #666;
}

.utility_contacts .underline {
    text-decoration: underline;
}

.utility_contacts .capitalize {
    text-transform: capitalize;
}

.utility_contacts .utility_table.table thead tr:first-child th:nth-child(1),
.utility_contacts .utility_table.table tbody tr:first-child td:nth-child(1),
.isCommTable thead tr th:nth-child(2),
.isCommTable tbody tr td:nth-child(2) {
    width: 4%;
}

.utility_section .bandwidth-wrap ul li {
    display: none;
}

.content .save_btn {
    display: flex;
    justify-content: flex-end;
    text-decoration: none;
    color: black;
    margin-top: .5rem;
}

.content .save_btn:hover {
    color: black;
}

.content .save_btn p {
    border: 1px solid #666;
    border-radius: 5px;
    padding: 2px 5px;
    font-size: 14px;
}

/* utility contact css ends here */

/* editcontacts/enrollment css */
.add_contacts_form form .all_sections {
    display: flex;
    justify-content: space-between;
}

.add_contacts_form form .form_sections.left {
    width: 55%;
}

.add_contacts_form form .form_sections.right {
    width: 40%;
}

.add_contacts_form form .form_sections label,
.add_contacts_form form .form_sections .radio_btns {
    font-weight: bold;
    margin-bottom: .8rem;
}

.add_contacts_form form .form_sections input {
    width: 40%;
    font-weight: normal;
}

.add_contacts_form form .form_sections input[type="number"]::-webkit-outer-spin-button,
.add_contacts_form form .form_sections input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.add_contacts_form form .form_sections input::placeholder {
    color: black;
}

.add_contacts_form form .form_sections .compulsory_field {
    display: contents;
    color: red;
}

.add_contacts_form form label,
.add_contacts_form form .radio_btns {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
}

.add_contacts_form form .submit_btn {
    display: flex;
    justify-content: center;
}

.add_contacts_form form .contact_submit_btn {
    background-color: transparent;
    border-style: none;
    border: 1px solid #666;
    border-radius: 5px;
    font-size: 14px;
    padding: 5px 10px;
    margin-top: 2rem;
}

.add_contacts_form form .form_sections .checkboxes p {
    font-size: 12px;
    font-weight: bold;
}

.add_contacts_form form .form_sections .radio_btns input,
.add_contacts_form form .form_sections .checkboxes input {
    width: auto !important;
}

.add_contacts_form form .form_sections .checkboxes label {
    justify-content: flex-start;
    margin-bottom: 0;
    width: fit-content;
    position: relative;
    cursor: pointer;
}

.add_contacts_form form .form_sections .checkboxes input {
    margin: auto 0;
}

.add_contacts_form form .form_sections input:focus,
.add_contacts_form form .form_sections .radio_btns input:focus,
.add_contacts_form form .form_sections .checkboxes input:focus,
.add_contacts_form form .contact_submit_btn:focus {
    outline: none;
}

.add_contacts_form form .form_sections .checkboxes span.checkbox_text,
.add_contacts_form form .form_sections .radio_buttons {
    margin-left: 5px;
    font-size: 12px;
    user-select: none;
}

.add_contacts_form form .form_sections .checkboxes span.checkbox_text {
    text-transform: capitalize;
}

.add_contacts_form form .warning_message_error,
.add_contacts_form form .warning_message_success {
    display: none;
}

.add_contacts_form form .warning_message_error {
    color: red;
    font-size: 12px;
}

.add_contacts_form form .warning_message_success {
    color: green;
    font-size: 12px;
}

/* custom checkbox */
.add_contacts_form .checkboxes,
.add_contacts_form .radio_buttons {
    display: block;
    position: relative;
    padding-left: 12px;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.add_contacts_form .checkboxes input,
.add_contacts_form .radio_buttons input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}

.checkmark {
    margin-top: 3px;
    height: 12px;
    width: 11px;
    border: 1px solid #666
}

.add_contacts_form .checkboxes input:checked ~ .checkmark {
    background-color: white;
    border: 1px solid #666;
    top: 20%;
    left: -10%;
}

.add_contacts_form .radio_buttons input:checked ~ .radio_mark {
    background-color: white;
    border: 1px solid #666;
}

.checkmark:after,
.radio_mark:after {
    content: "";
    position: absolute;
    display: none;
}

.add_contacts_form .checkboxes input:checked ~ .checkmark:after,
.add_contacts_form .radio_buttons input:checked ~ .radio_mark:after {
    display: block;
}

.add_contacts_form .checkboxes .checkmark:after {
    left: 3.5px;
    top: 4px;
    width: 4px;
    height: 8px;
    border: solid #666;
    border-width: 0 1.5px 1.5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.add_contacts_form form .form_sections .radio_buttons {
    margin-left: 0;
    margin-bottom: 0;
    cursor: pointer;
}

.radio_mark {
    position: absolute;
    top: 10%;
    left: -5%;
    height: 12px;
    width: 12px;
    border: 1px solid #666;
    border-radius: 50%;
}

.radio_buttons .radio_mark:after {
    top: 1px;
    left: 1px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #4d4d4d;
}

.circuit_list .available_circuits .all_checkboxes label {
    font-size: 14px;
    margin-bottom: 2px;
    cursor: pointer;
}

.circuit_list .available_circuits .all_checkboxes label input {
    margin-right: 5px;
}

.circuit_list .available_circuits .all_checkboxes .child {
    margin-left: 15px;
}

/* Enrollment page Css */
.enrollment_page .enrollfetchedData {
    width: 30%;
}

.enrollment_page .enrollfetchedData p {
    font-weight: bold;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}

.enrollment_page .enrollfetchedData p span {
    font-weight: normal;
}

.notofication_table tr td {
    position: relative;
}

.enroll_table .hoverable {
    position: absolute;
    left: 50%;
    width: 150%;
    text-align: left;
    background-color: white;
    z-index: 1;
}

.enroll_table .hoverable p {
    margin-top: .5rem;
    padding: 10px;
    font-size: 11px;
    border-radius: 5px;
    -webkit-box-shadow: -1px 2px 6px rgb(0 0 0 / 40%), 0px 0 0px rgb(0 0 0 / 5%) inset;
    -moz-box-shadow: -1px 2px 6px rgb(0 0 0 / 40%), 0px 0 0px rgb(0 0 0 / 5%) inset;
    box-shadow: -1px 2px 6px rgb(0 0 0 / 40%), 0px 0 0px rgb(0 0 0 / 5%) inset;
}

.enroll_table .common_hover + .hoverable {
    display: none;
}

.enroll_table .common_hover:hover + .hoverable {
    display: block;
}

.enroll_table .hoverable span {
    font-weight: bold;
}

.enroll_table .common_hover,
.notofication_table tr td .select_circuit {
    cursor: pointer;
    font-size: 14px;
}

.enroll_table svg.common_hover {
    margin-left: .2rem;
    margin-right: .2rem;
}

.enroll_table svg path {
    fill: #753BBD;
}

.notofication_table tr td input[type=checkbox]:focus {
    outline: none;
}

.notofication_table .table thead tr:first-child th:first-child,
.notofication_table .table tbody tr:first-child td:first-child {
    width: 27%;
}

.notofication_table .table thead tr:first-child th:nth-child(2),
.notofication_table .table tbody tr:first-child td:nth-child(2),
.notofication_table .table thead tr:first-child th:nth-child(3),
.notofication_table .table tbody tr:first-child td:nth-child(3) {
    width: 20%;
}

.notofication_table tr td p {
    margin-bottom: 0;
    font-size: 14px;
}

.notofication_table tr td p svg {
    width: 15px;
}

.notofication_table tr td p a .select_circuit {
    background-color: #ffffcc;
    border: 1.5px solid #666;
    color: #666;
    padding: 1px 4px;
    text-decoration: none;
}

.notofication_table tr td p a {
    text-decoration: none;
}

.notofication_table .submit_changes_btn {
    text-transform: uppercase;
    font-size: 12px;
    float: right;
    background-color: #753BBD;
    color: white;
    padding: 5px 10px;
}

.circuit_selection .selection_options p {
    margin-bottom: 0;
    font-size: 14px;
    margin-top: 5px;
}

.circuit_selection .selection_options .note p {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 1rem;
}

.circuit_selection {
    border-bottom: 1px solid #75787B;
}

.circuit_section {
    /* width: 50%; */
}

.circuit_selection .circuit_head {
    position: relative;
    background-color: #753BBD;
    color: white;
    padding: 5px 3px;
}

.circuit_selection .circuit_head .close_circuit {
    position: absolute;
    right: 1%;
    top: 15%;
    background-color: white;
    border-radius: 7px;
}

.circuit_selection .circuit_head .close_circuit a {
    text-decoration: none;
}

.circuit_selection .circuit_head .close_circuit a p {
    color: black;
    font-weight: bold;
    margin-bottom: 0;
    padding: 2px 5px;
    font-size: 16px;
}

/* custom radio button in circuit page */
.circuit_selection .radio_buttons {
    display: block;
    position: relative;
    padding-left: 20px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.circuit_selection .radio_buttons input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.circuit_selection .radio_mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 12px;
    width: 12px;
    background-color: #eee;
    border-radius: 50%;
    margin-top: 5px;
}

.circuit_selection .radio_buttons input:checked ~ .radio_mark {
    background-color: white;
    border: 1px solid #666;
}

.circuit_selection .radio_mark:after {
    content: "";
    position: absolute;
    display: none;
}

.circuit_selection .radio_buttons input:checked ~ .radio_mark:after {
    display: block;
}

.circuit_selection .radio_buttons .radio_mark:after {
    top: 1px;
    left: 1px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #4d4d4d;
}

/* custom radio button in circuit page end */

/* custom checkbox in enrollment page */
.notofication_table tr td .enroll_custom_chkbox {
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.notofication_table tr td .enroll_custom_chkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.notofication_table tr td .enroll_custom_chkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 12px;
    width: 12px;
    /* background-color: #eee; */
}

.notofication_table tr td .enroll_custom_chkbox input:checked ~ .checkmark {
    background-color: white;
    border: 1px solid #666;
    top: 20%;
    left: -10%;

}

.notofication_table tr td .enroll_custom_chkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.notofication_table tr td .enroll_custom_chkbox input:checked ~ .checkmark:after {
    display: block;
}

.notofication_table tr td .enroll_custom_chkbox .checkmark:after {
    left: 3.5px;
    top: .5px;
    width: 4px;
    height: 8px;
    border: solid #666;
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(45deg);
}

/* custom checkbox in enrollment page end */
.circuit_selection .circuit_list {
    display: flex;
    justify-content: center;
    /* margin-bottom: 1rem; */
    margin: 40px 0;
}

.circuit_selection .circuit_list .circuits {
    border: 1px solid #666;
    padding: 1rem;
    /* width: 40%; */
    width: 350px;
    margin: 0 40px;
}

/* editcontacts/enrollment css ends here */

/* Preeti css ends here */

.wrapper {
    display: grid;
    grid-template-columns: 100px 100px 100px;
    grid-gap: 10px;
    background-color: white;
    color: #444;
}

.box {
    background-color: #444;
    color: white;
    border-radius: 5px;
    padding: 20px;
    font-size: 150%;
}

#topRow::after {
    content: "";
    clear: both;
    display: table;
    margin-bottom: 20px;
}

button.svg-download-arrow-btn {
    padding: 0 20px 0 16px;
}

.downloadArrow {
    /*transform: translateY(-50%);*/
    width: 22px;
    left: auto;
    /*right: 10px !important;*/
    top: 50% !important;
    right: -20px;
}

.downloadArrow-circuitHealth {
    left: 44px !important;
}

[class*="col-"] {
    /* not use direct bootstrap class
    /* margin-bottom: 15px; */
}

.row-grid + .row-grid {
    margin-top: 15px;
}

.row.row-grid [class*="col-"] + [class*="col-"] {
    margin-top: 15px;
}

@media (min-width: 768px) {
    .row.row-grid [class*="col-sm-"] + [class*="col-sm-"] {
        margin-top: 0;
    }
}

.billing-page.billing-payment-history .select-wrap {
    width: 25%;
}

.billing-page.billing-payment-history .select-wrap:after {
    content: '';
    height: 15px;
    width: 15px;
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    background: url(../images/svg/ico-select.svg) center center no-repeat;
    background-size: 100%;
}

.billing-page.billing-payment-history .select-control {
    border: 0;
    border-bottom: 1px solid black;
    color: #753BBD;
    width: 100%;
}

.billing-page.billing-payment-history .table-style-wrap .react-bs-table-csv-btn,
.contactsTable .table-style-wrap .react-bs-table-csv-btn {
    position: absolute;
    top: 30px;
    right: 25px;
    border: 1.5px solid #753BBD;
    line-height: initial;
    padding: 4px 8px;
    color: #753BBD;
    padding-right: 25px;
}

.contactsTable .table-style-wrap .react-bs-table-csv-btn {
    top: -50px;
    right: 0;
}

.billing-page.billing-payment-history .table-style-wrap .react-bs-table-csv-btn svg,
.contactsTable .table-style-wrap .react-bs-table-csv-btn svg {
    width: 15px;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
}

.billing-page.billing-payment-history .table-style-wrap .react-bs-table-csv-btn svg .cls-1,
.contactsTable .table-style-wrap .react-bs-table-csv-btn svg .cls-1 {
    fill: #753BBD;
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999999;
}

.loader-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 240px;

}

@media screen and (max-width: 992px) {
    .table-style-wrap .table tbody tr td, .table-style-wrap .table thead tr th {
        font-size: 12px;
    }

}

@media screen and (max-width: 768px) {
    .react-bootstrap-table table {
        table-layout: auto;
    }

    .table-style-wrap .table tbody tr td, .table-style-wrap .table thead tr th {
        padding: 10px 5px;
        position: relative;

    }

    .table-style-wrap .table thead tr th {
        padding-left: 24px;
        border-right: 1px solid #dee2e6;
        border-top: 1px solid #dee2e6;
    }

    .table-style-wrap .table thead tr th:last-child {
        border-right: 0;
    }

    .table-style-wrap .table thead tr th .order {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }

    .billing-page.billing-payment-history .table-style-wrap .react-bs-table-csv-btn {
        font-size: 12px;
    }

    .billing-page.billing-payment-history .select-control {
        font-size: 12px;
    }
}

@media (max-width: 576px) {
    .billing-page.billing-payment-history .select-wrap {
        width: 50%;
    }
}

/* Dropdown Button */
.dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    text-align: center;
    position: relative;
    cursor: pointer;
    width: 110px;
    padding: 10px 0 10px 0;
}

.dropdown:hover {
    background-color: rgba(32, 32, 34, .96);
}

.dropdown2 {
    text-align: center;
    position: relative;
    cursor: pointer;
    width: 60px;
    margin: 0 auto;
    /*display: inline-block;transform: translateZ(0);*/
}

.proactive-check {
    text-align: center;
    position: relative;
    /*display: inline-block;transform: translateZ(0);*/
}

.proactive-check .ico {
    margin: 10px 10px 10px 10px;
}

.proactive-check svg {
    -webkit-mask-image: url('../images/svg/ico-check-mark-17.svg');
    mask-image: url('../images/svg/ico-check-mark-17.svg');
    background-color: #753BBD;
}

.healthicon {
    width: 50px;
    /*height: 50px;*/
    margin: 0 auto;
    position: relative;
    /*display: inline-block;transform: translateZ(0);*/
}

.healthicon .ico {
    margin: 10px 10px 10px 10px;
}

.healthicon-orange {
    background-color: #EF9123;
}

.healthicon-green {
    background-color: #6BBE59;
}

.healthicon-red {
    background-color: #ff0000;
}

.healthicon-orange svg {
    -webkit-mask-image: url('../images/svg/ico-warning.svg');
    mask-image: url('../images/svg/ico-warning.svg');
    background-color: white;
}

.healthicon-green svg {
    -webkit-mask-image: url('../images/svg/ico-arrow-up-thin.svg');
    mask-image: url('../images/svg/ico-arrow-up-thin.svg');
    background-color: white;
}

.healthicon-red svg {
    -webkit-mask-image: url('../images/svg/ico-arrow-down-thin.svg');
    mask-image: url('../images/svg/ico-arrow-down-thin.svg');
    background-color: white;
}

.dropdown2:hover {
    background-color: white;
    /*background-color: rgba(32, 32, 34, .96);*/
}

.dropdown2 .ico {
    margin: 10px 10px 10px 10px;
}

.dropdown2 svg {
    -webkit-mask-image: url('../images/svg/ico-eye-thick.svg');
    mask-image: url('../images/svg/ico-eye-thick.svg');
    background-color: #753BBD;
}

.dropdown2:hover svg {
    -webkit-mask-image: url('../images/svg/ico-eye-thick.svg');
    mask-image: url('../images/svg/ico-eye-thick.svg');
    background-color: white;
}

.dropdown2 ul {
    cursor: auto !important;
    display: none;
    /*position:fixed;*/
    position: absolute;
    top: -101px;
    right: 60px;
    /*height: 200px;*/
    /*height:auto; height:60%;*/
    overflow-y: scroll;
    background-color: rgba(32, 32, 34, .96);
    width: 105px;
    margin: 101px 0 0 -12px;
    list-style-type: none;
    text-align: right;
    z-index: 1;
}

.dropdown2 ul li div a {
    color: white;
}

.dropdown2 ul li div a:hover {
    color: #c0c0c0;
}

.accountDropDown ul {
    right: -100px;
    width: 100px;
    text-align: left;
}

.event-content {
    cursor: auto !important;
    display: none;
    position: absolute;
    top: 0;
    right: 100%;
    width: 500px;
    height: 70px;
    overflow-y: scroll;
    color: white;
    background-color: rgba(32, 32, 34, .96);
    /* margin:101px 0 0 -12px;  */
    list-style-type: none;
    text-align: left;
    z-index: 1;
}

.multi-li {

}

.multi-li {
    /*right: calc( 100% - -105px);*/
}

.event-content .row, .dropdown2 li .row {
    margin-right: 0;
}

.event-content span, .dropdown2 li span {
    font-weight: bold;
    padding-left: 10px;
}

.dropdown2 ul li {
    padding: 12px 20px 0 0;
    min-height: 45px;
    border-bottom: 1px solid #666666;
}

.dropdown2 ul li:last-child {
    border-bottom: none;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    cursor: auto !important;
    display: none;
    position: absolute;
    bottom: 0;
    right: 110px;
    width: 300px;
    height: 70px;
    overflow-y: scroll;
    border-top: 5px solid #EF9123;
    color: white;
    background-color: rgba(32, 32, 34, .96);
    margin: 101px 0 0 -12px;
    list-style-type: none;
    text-align: left;
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content p {
    padding: 0;
    margin: 9px 0 0 12px;
}

.dropdown-content span {
    font-weight: bold;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown2:hover ul {
    display: block;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .event-content, .dropdown2 li:hover .multi-li {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}

.health-alert svg {
    -webkit-mask-image: url('../images/svg/ico-alert.svg');
    mask-image: url('../images/svg/ico-alert.svg');
    background-color: #753BBD;
}

.health-down svg {
    -webkit-mask-image: url('../images/svg/ico-eye-thick.svg');
    mask-image: url('../images/svg/ico-eye-thick.svg');
    background-color: #753BBD;
}

.health-up svg {
    -webkit-mask-image: url('../images/svg/ico-eye-thick.svg');
    mask-image: url('../images/svg/ico-eye-thick.svg');
    background-color: #753BBD;
}

.footer-info-panel {
    /* margin: -50px 300px 30px 300px; */
    border-radius: 50px;
    border: 6px solid;
    border-color: black #753BBD;
    padding: 20px 0 20px 25px;
    height: 100px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    max-width: 85%;
    margin: 30px auto;
}

.dot {
    height: 60px;
    width: 60px;
    padding-top: 11px;
    margin-right: 7px;
    background-color: #bbb;
    border-radius: 50%;
    color: white;
    display: inline-block;
    background: none;
}

.info-left-col {
    color: white;
}

.info-left-col span {
    margin-right: 7px;
}

.info-center-col {
    padding-top: 11px;;
}

.info-center-col span {
    margin-right: 7px;
}

.info-center-col svg {
    margin-top: 18px;
}

.info-right-col {
    padding-top: 11px;
    color: #753BBD;
}

.info-right-col span {
    margin-right: 7px;
}

.info-right-col path {
    fill: #753BBD;
}

.dot-green {
    border: 3px solid #3e8e41;
}

.dot-orange {
    border: 3px solid white;
}

.dot-red {
    border: 3px solid #ff0000;
}

.dot-blue {
    border: 3px solid #081872;
}

.circuithealth-content {
    min-height: auto;
    margin-bottom: 180px;
}

.circuithealth-content ul {
    font-size: 13px;
}

.btn-style {
    font-size: 16px;
    padding: 4px 10px;
    line-height: inherit;
    border-radius: 0;
}

.btn-cancel {
    background-color: transparent;
    border: 1px solid #999;
    color: #999;
}

.btn-theme {
    background-color: transparent;
    border: 1px solid #753BBD !important;
    color: #753BBD;
    font-size: 16px !important;
}

.btn-cancel {
    background-color: transparent;
    border: 1px solid #75787B !important;
    color: #75787B !important;
    font-size: 16px !important;
}

a {
    cursor: pointer;
}

.productSubTypeGroup button {
    margin: 5px;
}

.productSubTypeGroup:first-child button {
    /* margin-left:0; */
}

#troubleTicket,
#userManage {
    background-color: white;
}

#troubleTicket th,
#userManage th {
    background-color: black;
    color: white;
}

.exportBtnTroubleTicket span {
    font-size: 11px;
    position: absolute;
    top: 50%;
    /* left: -40px; */
    transform: translateY(-50%);
    font-weight: bolder;
    text-transform: uppercase;
    color: #753bbd !important;
    left: 0 !important;
}

.exportBtnCircuitInventory span {
    font-size: 14px;
}

.exportBtnCircuitInventory .downloadArrow {
    right: -8px !important;
}

.exportBtnTroubleTicket-circuitHealth span {
    /* left: -1px !important;
    top: -4px; */
}

.exportBtnTroubleTicket svg path {
    fill: #753BBD;
}

.borderless td, .borderless th {
    border: none;
}

.ticketDetailsTable tr td {
    width: auto;
    padding: 10px;

}

.ticketDetailsTable tr td:nth-child(2) {
    color: #999;
}

.page-list .pagination.react-bootstrap-table-page-btns-ul {
    justify-content: flex-end;
}

.page-list #troubleTicket-pageDropDown {
    border-bottom: 1px solid white;
    border-radius: 0;
    padding-left: 0;
}

.page-list #troubleTicket-pageDropDown .caret {
    display: none;
}

.page-list.table-style-wrap .react-bs-table-sizePerPage-dropdown {
    display: block;
}

.page-list.table-style-wrap .react-bs-table-sizePerPage-dropdown:hover,
.page-list.table-style-wrap .react-bs-table-sizePerPage-dropdown button:hover {
    background-color: transparent;
}

.page-list .react-bootstrap-table-pagination {
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0;
}

.myWorklistTableWrap .page-list .react-bootstrap-table-pagination {
    background-color: #292a2c !important;
    margin: 0;
}

.quoteSummaryTableWrap .page-list .react-bootstrap-table-pagination {
    background-color: white !important;
    margin: 0;
}

.troubleTicketHistoryTable .react-bootstrap-table-pagination {
    background-color: black;
}

.contactsTable .react-bootstrap-table-pagination {
    background-color: black;
}

.page-list #troubleTicket,
.page-list #userManage {
    margin-bottom: 0;
}

.react-bootstrap-table-pagination .react-bs-table-sizePerPage-dropdown.dropdown.show
.dropdown-menu.show li {
    padding: 0;
}

.react-bootstrap-table-pagination .react-bs-table-sizePerPage-dropdown.dropdown.show,
.react-bootstrap-table-pagination .react-bs-table-sizePerPage-dropdown.dropdown.show
.dropdown-menu.show li a {
    display: block;
    width: 100%;
}

.react-bootstrap-table-pagination .react-bs-table-sizePerPage-dropdown.dropdown.show
.dropdown-menu.show li a {
    padding: 0.25rem 1.5rem;
}

.page-list.table-style-wrap .react-bs-table-sizePerPage-dropdown {
    width: 100% !important;
}

.toggle-switch-sm.toggle-switch {
    margin: 0;
    margin-left: 10px;
}

.toggle-switch-sm .ts-bar {
    width: 8px;
    height: 6px;
}

.toggle-switch-sm .ts-left-edge, .toggle-switch-sm .ts-right-edge {
    height: 6px;
    width: 6px;
}

.toggle-switch-sm .ts-switch {
    left: -10px;
    top: -3px;
    height: 12px;
    width: 12px;
}

.toggle-switch-sm.toggle-switch.on .ts-switch {
    left: 6px;
}

.table-style-wrap tr td {
    padding: 8px 10px;
}

.TroubleTicket-page .react-bootstrap-table-pagination,
.UserManagement-page .react-bootstrap-table-pagination {
    flex-direction: row-reverse;
    justify-content: flex-end;
}

.TroubleTicket-page .react-bootstrap-table-pagination div,
.quoteSummaryPage .react-bootstrap-table-pagination div,
.orderSummaryPage .react-bootstrap-table-pagination div {
    width: auto !important;
    flex: none;
}

.circuitInventoryPage .react-bootstrap-table-pagination div:first-child,
.quoteSummaryPage .react-bootstrap-table-pagination div:first-child,
.orderSummaryPage .react-bootstrap-table-pagination div:first-child {
    position: absolute;
    right: 40px;
}

.UserManagement-page .pagination-table .pagination .active.page-item a {
    color: white;
}

.UserManagement-page .react-bootstrap-table-pagination .react-bootstrap-table-pagination-list ul {
    float: none !important;
    justify-content: start;
}

.UserManagement-page .react-bootstrap-table-pagination div:first-child .react-bs-table-sizePerPage-dropdown {
    text-align: right;
}

.footer-search-bar input[type=date], .footer-search-bar input[type=date]::after {
    background-color: transparent;
}

.troubleTicketTable .filter-label {
    display: none;
}

.troubleTicketTable.searchableEnableTable .filter-label {
    display: block;
}

.troubleTicketTable.searchableEnableTable .order .dropdown .caret,
.troubleTicketTable.searchableEnableTable .react-bootstrap-table-sort-order .caret,
.troubleTicketTable.searchableEnableTable .react-bootstrap-table-sort-order.dropup .caret,
.troubleTicketTable.searchableEnableTable .order .dropup .caret {
    display: none !important;
}

.troubleTicketTable.searchableEnableTable tr th {
    font-size: 0 !important;
}

.troubleTicketTable.searchableEnableTable tr th input {
    font-size: 12px;
    color: white;
}

.troubleTicketTable.searchableEnableTable tr th input::placeholder {
    opacity: 1;
    color: #753BBD;
}

.troubleTicketTable.searchableEnableTable tr th input:-ms-input-placeholder {
    color: #753BBD;
}

.troubleTicketTable.searchableEnableTable tr th input::-ms-input-placeholder {
    color: #753BBD;
}

.troubleTicketTable.searchableEnableTable tr th .order {
    display: none;
}

.troubleTicketTable.table-style-wrap .table thead tr:first-child th:first-child,
.troubleTicketTable.table-style-wrap .table tbody tr:first-child td:first-child {
    width: auto;
}

.userManagementTable.table-style-wrap .table thead tr th:first-child,
.userManagementTable.table-style-wrap .table tbody tr td:first-child {
    width: 10%;
}

.userManagementTable.table-style-wrap .table thead tr th:nth-child(2),
.userManagementTable.table-style-wrap .table tbody tr td:nth-child(2),
.userManagementTable.table-style-wrap .table thead tr th:nth-child(3),
.userManagementTable.table-style-wrap .table tbody tr td:nth-child(3),
.userManagementTable.table-style-wrap .table thead tr th:nth-child(4),
.userManagementTable.table-style-wrap .table tbody tr td:nth-child(4) {
    width: 20%;
}

.troubleTicketTable tr th img {
    display: none;
}

.troubleTicketTable.desktopViewTable tr th img {
    display: block;
}

.troubleTicketTable.desktopViewTable tr th span {
    display: none;
}

.desktopViewTable tr th#Incident,
.desktopViewTable tr th#aloc,
.desktopViewTable tr th#zloc {
    position: relative;
}

.desktopViewTable tr th#aloc::after,
.desktopViewTable tr th#zloc::after {
    content: "A";
    display: block;
    position: absolute;
    top: 3px;
    left: 56%;
    height: 20px;
    width: 20px;
    color: white;
    font-size: 15px;
    background-color: #753BBD;
    border-radius: 50%;
}

.desktopViewTable tr th#zloc::after {
    content: "Z";

}

.troubleTicketTable #troubleTicket th, .troubleTicketTable #troubleTicket,
.userManagementTable #userManage th, .userManagementTable #userManage {
    background-color: transparent;
}

.troubleTicketHistoryTable #troubleTicket {
    background-color: black
}

.contactsTable #troubleTicket {
    background-color: black
}

.contactsTable #troubleTicket th:first-child,
.contactsTable #troubleTicket #enroll {
    width: 6%;
}

.troubleTicketTable #troubleTicket tbody,
.userManagementTable #userManage tbody {
    background-color: white;
}

.userManagement-table #userManage thead {
    position: sticky;
    top: 0;
    background-color: black;
}

.troubleTicketTable #troubleTicket {
    margin-top: 38px;
}

.troubleTicketHistoryTable #troubleTicket {
    margin-top: 0;
}

@media screen and (max-width: 992px) {

    .troubleTicketTable tr th img {
        display: block;
    }

    .troubleTicketTable tr th span {
        display: none;
    }
}

#health-header svg {
    -webkit-mask-image: url('../images/svg/ico-health-status.svg');
    mask-image: url('../images/svg/ico-health-status.svg');
    background-color: white;
}

#open-tickets-header svg {
    -webkit-mask-image: url('../images/svg/ico-open-tickets.svg');
    mask-image: url('../images/svg/ico-open-tickets.svg');
    background-color: white;
}

#events-header svg {
    -webkit-mask-image: url('../images/svg/ico-events.svg');
    mask-image: url('../images/svg/ico-events.svg');
    background-color: white;
}

#proactive-header svg {
    -webkit-mask-image: url('../images/svg/ico-pro-active.svg');
    mask-image: url('../images/svg/ico-pro-active.svg');
    background-color: white;
}

#location-header svg {
    -webkit-mask-image: url('../images/svg/ico-circuit-location.svg');
    mask-image: url('../images/svg/ico-circuit-location.svg');
    background-color: white;
}

#circuit-id-header svg {
    -webkit-mask-image: url('../images/svg/ico-circuit-id.svg');
    mask-image: url('../images/svg/ico-circuit-id.svg');
    background-color: white;
}

#account-header svg {
    -webkit-mask-image: url('../images/svg/ico-account.svg');
    mask-image: url('../images/svg/ico-account.svg');
    background-color: white;
}

#product-header svg {
    -webkit-mask-image: url('../images/svg/ico-circuit-product.svg');
    mask-image: url('../images/svg/ico-circuit-product.svg');
    background-color: white;
}

#bandwidth-header svg {
    -webkit-mask-image: url('../images/svg/ico-bandwidth.svg');
    mask-image: url('../images/svg/ico-bandwidth.svg');
    background-color: white;
}

.toggle-switch.green-toggle.on .ts-switch {
    background-color: #753BBD;
}

.tally-ico {
    position: relative;
    width: 36px;
}

.tally-ico > div {
    position: absolute;
    text-align: center;
    line-height: 30px;
    top: -14px;
    right: -7px;
    background-color: #753BBD;
    font-weight: 700;
    border: 1px solid #753BBD;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    z-index: 9;
}

.tally-ico > div > span {
    display: block !important;
    top: 24px;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    color: white;
    margin: inherit;
    background-color: inherit;
    border-radius: inherit;
    line-height: 24px;
    height: inherit;
    padding: inherit;
}

.orangeCircle {
    border: 2px solid white !important;
}

.greenCircle {
    border: 2px solid #3e8e41 !important;
}

.redCircle {
    border: 2px solid #ff0000 !important;
}

.blueCircle {
    border: 2px solid #081872 !important;
}

#countdown {
    display: inline-block;
    margin: 0 0 0 5px !important;
    position: relative;
    margin: auto;
    height: 40px;
    width: 40px;
    text-align: center;
}

#countdown-number {
    color: white;
    display: inline-block;
    line-height: 40px;
}

#countdown svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    transform: rotateY(-180deg) rotateZ(-90deg);
}

#countdown svg circle {
    stroke-dasharray: 113px;
    stroke-dashoffset: 0px;
    stroke-linecap: round;
    stroke-width: 2px;
    stroke: white;
    fill: none;
    animation: countdown 300s linear infinite forwards;
}

@keyframes countdown {
    0% {
        stroke-dashoffset: 0px;
    }
    100% {
        stroke-dashoffset: 113px;
    }
}

@media screen and (max-width: 1024px) {
    body {
        width: 1024px;
        overflow-x: auto;
    }
}

.productType_0, .productType_2 {
    background: linear-gradient(
            320deg, rgba(174, 33, 121, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.productType_1, .productType_3 {
    background: linear-gradient(
            320deg, rgba(255, 158, 46, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.productType_4 {
    background: linear-gradient(
            320deg, rgba(100, 158, 46, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.productType_5 {
    background: linear-gradient(
            320deg, rgba(250, 150, 50, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.productType_6 {
    background: linear-gradient(
            320deg, rgba(120, 150, 46, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.productType_7 {
    background: linear-gradient(
            320deg, rgba(70, 158, 150, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.productType_8 {
    background: linear-gradient(
            320deg, rgba(150, 158, 66, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.productType_9 {
    background: linear-gradient(
            320deg, rgba(190, 150, 99, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.productType_10 {
    background: linear-gradient(
            320deg, rgba(190, 150, 250, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.productType_11 {
    background: linear-gradient(
            320deg, rgba(10, 250, 99, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.productType_12 {
    background: linear-gradient(
            320deg, rgba(245, 150, 30, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.textuppercase {
    text-transform: uppercase;
}

.space-between {
    justify-content: space-between;
}

.text-top {
    vertical-align: text-top;
}

.autoCompleteStyle > div {
    outline: none !important;
    border: 0;
    border-bottom: 1px solid #cccccc !important;
    border-radius: 0 !important;
    font-size: 12px;
}

.react-select__indicators .react-select__indicator.react-select__dropdown-indicator,
.react-select__indicator-separator {
    display: none !important;
}

.react-select__control--is-focused {
    outline: none !important;
    border: 0 !important;
    box-shadow: none !important;
}

.react-select-nav__control {
    background: transparent !important;
    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
}

.react-select-nav__indicator-separator, .react-select-nav__indicator.react-select-nav__dropdown-indicator {
    display: none;
}

.react-select-nav__menu {
    z-index: 9 !important;
}

.react-select-nav__value-container .react-select-nav__single-value {
    color: #7202E1;
}

.react-select-nav__option.react-select-nav__option--is-selected {
    background: #7202E1;
}

/* checkbox */
.checkbox-label {
    display: block;
}

.checkbox-label, .radio-label {
    color: #753BBD;
}

.checkbox-label input {
    vertical-align: top;
    margin-right: 4px;
}

input[type=checkbox], input[type=radio] {
    width: 24px;
    height: 24px;
    vertical-align: middle;
}

input[type=checkbox], input[type=radio] {
    accent-color: #753BBD;
}

input[type="checkbox"], input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
    /* *height: 13px;
    *width: 13px; */
}

/* Quote Summary */
#quoteSummaryTable thead th {
    border-top: 0;
}

#quoteSummaryTable thead tr th .filter-label .form-control {
    background: url(../images/svg/ico-search-filled-light.svg) no-repeat right;
    background-position-x: 4px;
    background-size: 24px 24px;
    font-size: 16px;
    border: 0;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    padding-left: 40px;
    color: #A6A7A9;
}

/* This is commented out for Quote and Order Summary so the last columnis not right justified
#quoteSummaryTable thead th:last-child {
    color: #75787B;
    font-size: 16px;
    font-weight: 600;
    text-align: right;
}

#quoteSummaryTable tbody td:last-child {
    text-align: right;
}
*/

#quoteSummaryTable tbody td {
    vertical-align: middle;
    color: #75787B;
    font-size: 16px;
}

#quoteSummaryTable tbody tr td:not(:first-child) {
    padding-left: 50px;
}

#quoteSummaryTable tbody tr td:last-child {
    padding-right: 25px;
}

.quoteSummaryTableWrap .react-bootstrap-table-pagination-list {
    z-index: 1;
}

/* Bulk Quote Summary */
#bulkQuoteSummaryTable thead th {
    border-top: 0;
}

#bulkQuoteSummaryTable thead tr th .filter-label .form-control {
    background: url(../images/svg/ico-search-filled-light.svg) no-repeat right;
    background-position-x: 4px;
    background-size: 24px 24px;
    font-size: 15px;
    border: 0;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    padding-left: 40px;
    color: #A6A7A9;
}

#bulkQuoteSummaryTable thead th:last-child {
    color: #75787B;
    font-size: 16px;
    font-weight: 600;
    text-align: right;
}

#bulkQuoteSummaryTable tbody td:last-child {
    text-align: right;
}

#bulkQuoteSummaryTable tbody td {
    vertical-align: middle;
    color: #75787B;
    font-size: 16px;
}

#bulkQuoteSummaryTable tbody tr td:not(:first-child) {
    padding-left: 50px;
}

#bulkQuoteSummaryTable tbody tr td:last-child {
    float: left;
}


.bulkQuoteSummaryTableWrap .react-bootstrap-table-pagination-list {
    z-index: 1;
}

.bulk-quote-summary .react-bootstrap-table-pagination div:first-child {
    display: none;
}

.placeHolderInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #753BBD !important;
    opacity: 1; /* Firefox */
}

.placeHolderInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #753BBD !important;
}

.placeHolderInput::-ms-input-placeholder { /* Microsoft Edge */
    color: #753BBD !important;
}

.niceSelect {
    width: 100%;
    text-align: left;
}

.niceSelect:hover {
    background-color: transparent;
}

.niceSelect .niceSelectBtn {
    width: 100%;
    border-radius: 0;
    text-align: left;
    color: #753BBD;
    padding-left: 0 !important;
    border-bottom: 1px solid #753BBD;
    font-weight: 600;
}

.niceSelectBtn.dropdown-toggle::after {
    border-bottom: 2px solid #753BBD;
    border-right: 2px solid #753BBD;
    content: '';
    display: block;
    height: 10px;
    margin-top: -8px;
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform-origin: 66% 66%;
    -ms-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    width: 10px;
    border-top: 0 !important;
    border-left: 0 !important;
}

.niceSelect.show .niceSelectBtn.dropdown-toggle::after {
    -webkit-transform: rotate(-136deg);
    -ms-transform: rotate(-136deg);
    transform: rotate(-136deg);
}

.niceSelectBtn:hover, .niceSelectBtn:focus {
    color: #753BBD !important;
    outline: none !important;
    box-shadow: none !important;
}

.niceSelect .niceSelectMenu {
    left: 0 !important;
    right: 0 !important;
}

.niceSelectMenu {
    padding: 0px !important;
    border: 0 !important;
    border-radius: 0 !important;
    /* box-shadow: 0 0 0 1px rgb(68 68 68 / 27%) !important;     */
    box-shadow: 0px 1px 3px -1px rgba(127, 108, 108, 0.75) !important;
    -webkit-box-shadow: 0px 1px 3px -1px rgba(127, 108, 108, 0.75) !important;
    -moz-box-shadow: 0px 1px 3px -1px rgba(127, 108, 108, 0.75) !important;
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    -webkit-transform-origin: 50% 0;
    -ms-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transform: scale(0.75) translateY(-21px);
    -ms-transform: scale(0.75) translateY(-21px);
    transform: scale(0.75) translateY(-21px);
    -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
}

.niceSelectMenu.show {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: scale(1) translateY(0);
    -ms-transform: scale(1) translateY(0);
    transform: scale(1) translateY(-1rem) !important;
}

.niceSelectMenu button {
    background-color: #D0D0D0;
    color: #282829;
    padding: 10px 20px;
}

.niceSelectMenu button:hover, .niceSelectMenu .selected {
    background-color: white;
    color: #753BBD;
}

.niceSelect.disabled .niceSelectBtn {
    border-bottom: 1px solid #ccc;
    color: #999;
    pointer-events: none;
    cursor: not-allowed !important;

}

.tootipCustom {
    border-radius: 0 !important;
    position: relative;
    margin: 0 !important;
    border: 0 !important;
    font-size: 16px !important;
    background-color: black !important;
    box-shadow: 0px 5px 8px -2px rgb(0 0 0 / 75%) !important;
    -webkit-box-shadow: 0px 5px 8px -2px rgb(0 0 0 / 75%) !important;
    -moz-box-shadow: 0px 5px 8px -2px rgb(0 0 0 / 75%) !important;
    font-family: "montserrat", sans-serif !important;
    font-weight: 400;
    font-style: normal;
}

.tootipCustom::before {
    content: '';
    display: block;
    width: 100% !important;
    position: absolute;
    top: -3px !important;
    left: 0px !important;
    height: 3px !important;
    background: linear-gradient(to left, #00ffff 11%, #9900ff 100%);
    margin: 0 !important;
    border: 0 !important;
}

.custom-manage-info {
    font-size: 16px !important;
    border-radius: 0 !important;
    width: 300px;
    position: relative;
    background: black !important;
    line-height: 30px !important;
    height: 110px !important;
    box-shadow: 0px 5px 8px -2px rgb(0 0 0 / 75%) !important;
    -webkit-box-shadow: 0px 5px 8px -2px rgb(0 0 0 / 75%) !important;
    -moz-box-shadow: 0px 5px 8px -2px rgb(0 0 0 / 75%) !important;
}

.custom-manage-info img {
    position: absolute;
    top: -3px !important;
    left: -5px !important;
    background: black;
    padding: 15px;
    border-radius: 50%;
    width: 65px;
    background: black !important;
    border: 1px solid #ccc;
}

.manage-tp-corner-br {
    height: 25px;
    width: 25px;
    border: 5px solid #FF9E2E;
    position: absolute;
    bottom: -1px;
    right: -1px;
    border-left: 0;
    border-top: 0;
}

.quote-summary-search {
    font-size: 1rem !important;
    font-weight: 400;
    border: 0;
    background-color: transparent;
    border-bottom: 1px solid #5F6062 !important;
    margin-bottom: 5px;
    padding: 20px 0 6px 35px;
    color: white;
    background-image: url(../images/svg/ico-search-filled-light.svg);
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: left 25PX;
}

.quote-summary-search::placeholder {
    color: #75787B !important;
}

.quoteTableWrap {
    margin: 30px 0;
}

#quoteDetailsTable tr td {
    vertical-align: top;
    padding: 5px 15px;

}

#quoteDetailsTable tbody tr td:last-child {
    text-align: right;
}

#quoteDetailsTable thead, .quoteTableWrap .react-bootstrap-table-pagination div:first-child {
    display: none;
}

.quoteTableWrap .react-bootstrap-table-pagination-list {
    max-width: 100%;
    flex: 0 0 100%;
}

.quoteTableWrap .react-bootstrap-table-pagination-list .pagination {
    justify-content: center;
}

.darkPanel .toggle-comp span {
    color: white;
}

.filterList {
    font-size: 12px;
    color: #202022;
    line-height: 32px;
    font-weight: 100;
    display: none;
    margin: 0 !important;
}

.quoteDetailsTable.date-requested-show .table tbody tr td .filterListWrap .filterList:first-child,
.quoteDetailsTable.date-ordered-show .table tbody tr td .filterListWrap .filterList:nth-child(2),
.quoteDetailsTable.quote-ID-show .table tbody tr td .filterListWrap .filterList:nth-child(3),
.quoteDetailsTable.requestor-show .table tbody tr td .filterListWrap .filterList:nth-child(4) {
    display: block;
}


.compactView .gaid-account, .desktopViewTable .dropdown2.accountDropDown {
    display: block;
}

.compactView .dropdown2.accountDropDown, .desktopViewTable .gaid-account {
    display: none;
}

.networkPage .table-style-wrap .table .filter.text-filter {
    text-align: center;
}

.networkPage .exportBtnCircuitInventory span {
    color: #753BBD;
    top: 0;
    transform: translateY(-15px);
    left: -45px;
}

.networkPage .exportBtnCircuitInventory {
    padding-right: 10px;
}

.summary-ticket-page .react-bootstrap-table-pagination {
    display: none;
}

.summary-ticket-page table tbody tr {
    background-color: #eee;
}

.summary-ticket-page .downloadArrow {
    right: 0px !important;
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

#circuit-details-midsection .wrap .header-links,
#light-levels-midsection .wrap .header-links,
#tickets-history-midsection .wrap .header-links,
#maintenance-history-midsection .wrap .header-links {
    display: flex;
    justify-content: center;
}

#circuit-details-midsection .wrap .header-links span,
#light-levels-midsection .wrap .header-links span,
#tickets-history-midsection .wrap .header-links span,
#maintenance-history-midsection .wrap .header-links span {
    color: #753BBD;
    margin: 8px 0 -4px 0;
}

#circuit-details-midsection .wrap .page-title,
#light-levels-midsection .wrap .page-title,
#tickets-history-midsection .wrap .page-title,
#maintenance-history-midsection .wrap .page-title {
    margin: 0 0 46px 0;
}

.circuit-details-row {
    font-size: 13px;
}

@media screen and (max-width: 768px) {
    #circuit-details-row .col-md-4 {
        width: 55%;
    }
}

.circuit-details-table tr td:first-child {
    background: transparent;
}

.circuit-details-table tr td:nth-child(2) {
    background: #999999;
}

.circuit-details-table tr td:last-child {
    background-color: #eeeeee;
}

.circuit-details-table tr td svg path {
    fill: black;
}

.circuit-details-table tr td {
    vertical-align: middle;
}

#circuit-details-wrap .page-title span,
#light-levels-wrap .page-title span,
#tickets-history-wrap .page-title span,
#maintenance-history-wrap .page-title span {
    color: #ED2863;
}

.circuit-details-row #circuit-details-row-location {
    margin-bottom: 5px;
}

.circuit-details-row #circuit-details-row-location span {
    color: #753BBD;
}


/***********
Light Levels
***********/

.light-levels .ll-loc {
    background-color: rgba(255, 255, 255, .08);
    line-height: 70px;
}

.light-levels .ll-time {
    background-color: rgba(255, 255, 255, .16);
    line-height: 46px;
}

.light-levels .ll-panel {
    background-color: rgba(0, 0, 0, .20);
}

.ll-panel {
    position: relative;
}

.ll-panel.alt {
    background-color: rgba(255, 255, 255, .06);
}

.light-levels .ll-core, .light-levels .ll-panel {
    height: 500px;
}

.light-levels .ll-lanes {
    top: 55%;
}

/* adjust brackets if you manipulate this value */

/* brackets */
.ll-bracket-tx {
    left: -21px;
    top: 146px;
}

.ll-bracket-rx {
    right: -21px;
    top: 306px;
}

.ll-bracket-tx svg,
.ll-bracket-rx svg {
    width: 10px;
    height: 99px;
}

.ll-bracket-tx span, .ll-bracket-rx span {
    font-size: 11px;
    position: absolute;
    top: 41%;
    color: white;
}

.ll-bracket-tx span {
    left: -12px;
}

.ll-bracket-rx span {
    left: 12px;
}

/* lanes */
.ll-lanes .ll-tx {
    position: relative;
}

.ll-lanes .ll-tx .ll-lane {
    position: absolute;
}

.ll-lanes .ll-rx .ll-lane {
    position: relative;
    margin-top: -1px;
}

.ll-lane {
    height: 32px; /*if you change this, change the calc offsets to match */
    width: 100%;
}

.ll-tx .ll-lane {
    border-top: 2px solid #753BBD;
}

.ll-rx .ll-lane {
    border-bottom: 2px solid #753BBD;
}

.ll-tx .ll-lane:hover {
    border-top: 2px solid #59cbe8;
}

.ll-rx .ll-lane:hover {
    border-bottom: 2px solid #59cbe8;
}

.ll-lane-highlight {
    opacity: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 15%, rgba(89, 203, 232, .5) 50%, rgba(0, 0, 0, 0) 85%);
    width: 100%;
    height: 28px;
    z-index: 1;
    position: absolute;
    transition: opacity .4s;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 16px;
}

.ll-tx .ll-lane-highlight {
    top: -16px;
}

.ll-rx .ll-lane-highlight {
    top: 18px;
}

.ll-lane:hover .ll-lane-highlight {
    opacity: 1;
    transition: opacity .4s;
}

/* le = lane edge left & right trim */
.ll-le-l, .ll-le-r {
    position: absolute;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    border: 1px solid #753BBD;
}

.ll-tx .ll-le-l,
.ll-tx .ll-le-r {
    top: -6px;
}

.ll-rx .ll-le-l,
.ll-rx .ll-le-r {
    top: 26px;
}

.ll-le-l {
    left: -9px;
}

.ll-le-r {
    right: -9px;
}

.ll-lane:hover .ll-le-l,
.ll-lane:hover .ll-le-r {
    border: 1px solid #6bbe59;
}


/* offsets for all TX lanes based on .ll-lanes 50% vertical middle */
.ll-lanes .ll-tx .ll-lane:first-child {
    top: calc(-32px * 4);
}

/* lane 1 */
.ll-lanes .ll-tx .ll-lane:nth-child(2) {
    top: calc(-32px * 3);
}

/* lane 2 */
.ll-lanes .ll-tx .ll-lane:nth-child(3) {
    top: calc(-32px * 2);
}

/* lane 3 */
.ll-lanes .ll-tx .ll-lane:last-child {
    top: -32px;
}

/* lane 4 */

.ll-lane-reading {
    position: relative;
    z-index: 2;
}

.ll-tx .ll-lane-reading {
    margin-top: -14px;
}

.ll-rx .ll-lane-reading {
    top: 20px;
}

.ll-lane-reading span {
    float: right;
    margin: 0 16px 0 0;
    display: inline-block;
    background-color: #202022;
    padding: 1px 6px 1px 6px;
    color: white;
    font-size: 14px;
    border: 1px solid #202022;
    border-radius: 6px;
}

.ll-lane-reading .ll-gap span {
    margin: 0;
}

.ll-lane-reading span:hover {
    background-color: #59cbe8;
    color: #202022;
    border: 1px solid #753BBD;
}

.ll-lane-reading .col.c span {
    float: none;
}

/* labels/totals */
.ll-totals {
    color: white;
    font-style: italic;
}

.ll-totals .f14 {
    margin-top: -8px;
}

.ll-total-dbm {
    margin-top: -5px;
    color: #59cbe8;
}

.ll-totals-dbm span {
    font-size: 24px;
    font-weight: bold;
    color: #59cbe8;
}

.ll-delta {
    visibility: hidden;
}

.ll-lane:hover .ll-delta {
    visibility: visible;
}

.tx-totals {
    position: absolute;
    top: 64px;
}

.rx-totals {
    position: absolute;
    bottom: 12px;
}

/* timestamp */
.ll-timestamp {
    text-align: left;
    padding: 12px 0 0 12px;
}

.ll-timestamp div {
    margin-top: -4px
}

/* location options */
.ll-options {
    padding: 4px 8px 0 0;
}


@media screen and (min-width: 1441px) {
    .topLeft .corner-nav .left-nav-title, .bottomLeft .corner-nav .left-nav-title {
        right: 200px;
    }

    .topRight .corner-nav .right-nav-title, .bottomRight .corner-nav .right-nav-title {
        left: 200px;
    }

    .topLeft .corner-nav:hover .left-nav-title,
    .bottomLeft .corner-nav:hover .left-nav-title,
    .bottomLeft .corner-nav .right-nav-title.hvr {
        right: 200px;
    }

    .topRight .corner-nav:hover .right-nav-title,
    .bottomRight .corner-nav:hover .right-nav-title,
    .bottomRight .corner-nav .left-nav-title.hvr {
        left: 200px;
    }

    .bottomLeft.blTobr .corner-nav .nav-title.hvr {
        right: 200px;
        left: auto;
    }

    .topLeft.tlTobr .corner-nav:hover .nav-title, .topLeft.tlTobr .corner-nav .nav-title.hvr {
        right: 200px;
        left: auto;
    }

    .bottomLeft.blTobr .right-subs {
        margin-top: 0;
    }
}

@media screen and (max-width: 1440px) {
    /***********
    Light Levels
    ***********/
    .ll-loc.f22 {
        font-size: 16px;
    }

    .ll-totals .f22 {
        font-size: 18px;
    }

    .ll-totals .f36 {
        font-size: 24px;
        color: #59cbe8;
    }

    .ll-totals .ll-total-dbm {
        margin-top: 0;
        color: #59cbe8;
    }

    .ll-totals .f14 {
        font-size: 12px;
    }

    .ll-timestamp .f18 {
        font-size: 14px;
    }

    .ll-time.c {
        text-align: left;
    }

    .ll-time {
        text-indent: 8px;
    }

    .bottomRight.brTobl .left-subs {
        margin-left: 66px;
    }
}

@media screen and (max-width: 1280px) {
    .ll-loc.w40 {
        width: 44%;
    }

    .ll-gap.w20,
    .ll-lane-reading .ll-gap.w20 {
        width: 12%;
    }

    .ll-time.w20,
    .ll-panel.w20,
    .ll-lane-reading .w20 {
        width: 22%;
    }

}

.mr5 {
    margin-right: 5%;
}

.colFL {
    min-height: 1px;
    float: left;
}

.link, label {
    color: #753BBD;
}

.logo-img {
    position: absolute;
    top: 31px;
    left: 114px;
    width: 41px;
    z-index: 99;
}

.TL.bottomRight.brTotl .logo-img {
    top: 44px;
    left: -44px;
}

#utilitiesTable-pageDropDown {
    border: 0 !important;
}

#utilitiesTable thead #edit, #utilitiesTable thead #enroll {
    /*width: 75px;*/
}

.info-window {
    height: auto !important;
    /* overflow-y: auto; */
    z-index: 9999 !important;
    font-size: 13px !important;
    line-height: 20px !important;
    text-align: justify;
}

.info-window.hover {
    visibility: visible !important;
    opacity: 1 !important;
}

.info-window img {
    left: -54px !important;
    top: -15px !important;
}

.text-grey {
    color: #75787B;
}

.text-green {
    color: #6BBE59 !important;
}

.phone-number {
    left: 40px !important;
}

.page-list-bulk #bulkQuoteList-pageDropDown {
    border-bottom: 1px solid white;
    border-radius: 0;
    padding-left: 0;
}

.page-list-bulk #bulkQuoteList-pageDropDown {
    display: block;
}

.page-list-bulk #bulkQuoteList {
    margin-bottom: 0;
}

#bulkQuoteList .react-bootstrap-table-pagination {
    background-color: white;
}

#bulkQuoteList .react-bootstrap-table-pagination-list {
    z-index: 1;
}

#bulkQuoteList .react-bootstrap-table-pagination-list .pagination {
    justify-content: center;
}

#bulkQuoteList .react-bootstrap-table-pagination div:first-child {
    display: none;
}

.bulkQuoteLabel {
    font-size: 9px;
    word-wrap: normal;
    vertical-align: center;
    padding: 0 0 0 9px;
    background-clip: padding-box;
    background-color: transparent;
}

.bulkQuoteLink {
    color: #753BBD;
    font-size: 9px;
    display: block;
}

#bulkQuoteList thead tr th .filter-label .form-control {
    font-size: 10px;
    text-align: center;
    width: 125%;
    height: calc(1.5em + 0.75rem + 2px);
    word-wrap: normal;
    padding: 3px;
    background-clip: padding-box;
    background-color: white;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    color: #495057;
    display: block;
    font-weight: bold;
    line-height: 1.5;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.orderButton .downloadArrow {
    left: auto;
    right: -15px;
    top: 50% !important;
    width: 15px;
}

.orderButton .btn.light-button, button.light-button {
    border-radius: 5px;
    padding: 0 25px 0 10px;
    color: #753BBD !important;
}

.orderButton .btn.light-button:hover, button.light-button:hover {
    color: #7202E1 !important;
}

.orderButton svg {
    width: 15px;
    text-align: right;
}

.saveButton .downloadArrow {
    left: auto;
    right: -11px;
    top: 50% !important;
    width: 15px;
}

.saveButton .btn.light-button, button.light-button {
    border-radius: 5px;
    padding: 7px 30px 7px 10px;
    color: #753BBD !important;
}

.saveButton .btn.light-button:hover, button.light-button:hover {
    color: #7202E1 !important;
}

#bulkQuoteList tbody tr td {
    font-size: 10px;
    word-wrap: normal;
    vertical-align: center;
    padding: 7px 0 0 17px;
    background-clip: padding-box;
    background-color: transparent;
    margin-top: 10px;
}

bulkQuoteTableWrap .formik-form input, .formik-form select {
    border-radius: 0 !important;
}

#bulkQuoteList .dropdown {
    text-align: center;
    position: relative;
    cursor: pointer;
    width: 110px;
    padding: 10px 0 5px 0;
}

.formik-form input.is-invalid {
    border-color: #dc3545 !important;
}

.date-fld {
    border-bottom: 1px solid #5f6062 !important;
    width: 100% !important;
}

.bulkCounter {
    margin: 0 20px 20px 15px;
}

.bulkCounter #countdown {
    display: inline-block;
    position: relative;
    margin: 0 0 20px 15px;
    height: 40px;
    width: 40px;
    text-align: center;
}

.bulkCounter #countdown-number {
    color: #753bbd;
    display: inline-block;
    line-height: 40px;
}

.bulkCounter #countdown svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    transform: rotateY(-180deg) rotateZ(-90deg);
}

.bulkCounter #countdown svg circle {
    margin-left: 10px;
    stroke-dasharray: 113px;
    stroke-dashoffset: 0px;
    stroke-linecap: round;
    stroke-width: 2px;
    stroke: #753bbd;
    fill: none;
    animation: countdown 30s linear infinite forwards;
}

.orderableError {
    color: red;
    font-size: 10px;
}

.invoicesError {
    color: red;
    font-size: 14px;
}

.invoiceHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.invoiceTotal {
    margin: 0 150px 16px 0;
}

.pos-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
}

/* charts */
.recharts-cartesian-grid-vertical {
    display: none;
}

.recharts-cartesian-grid-horizontal line {
    stroke-dasharray: 0;
}

.recharts-layer.recharts-cartesian-axis-tick text {
    font-size: 12px;
}

.recharts-layer.recharts-cartesian-axis-tick line {
    display: none;
}

.custom-tooltip {
    background-color: #FFFFFF;
    padding: 3px;
    border-radius: 3px;
    box-shadow: none;
    outline: none;
    border: 1px solid #1a1a1c;
}

.custom-tooltip .label {
    margin: 0;
    padding: 0;
    font-size: 13px;
    font-weight: 600;
}

.custom-tooltip .label:last-child {
    font-weight: 700;
}

.recharts-curve.recharts-area-area {
    fill: #3366cc;
}

.recharts-curve.recharts-area-curve {
    fill: transparent;
}

.f_saveFile {
    font-family: "montserrat", sans-serif !important;
    font-weight: 400 !important;
    font-style: normal !important;
    margin: 20px 0 0 0 !important;
}

.f_fileName {
    font-size: 11px !important;
    min-width: 500px !important;
}

.f_filterDate {
    font-size: 11px !important;
    min-width: 100px !important;
    color: #999 !important;
}

.f_internalFlag {
    font-size: 11px !important;
    min-width: 100px !important;
    color: #999 !important;
}

.f_externalFlag {
    font-size: 11px !important;
    min-width: 80px !important;
    color: red !important;
}

.purpleIco path {
    fill: #753BBD !important;
}

#bulkTable .table {
    width: 100%;
}

#bulkTable thead th {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    border-top: 0px !important;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    padding: 0px;
    color: #753BBD !important;
}

#bulkTable td {
    font-size: 12px !important;
}

#bulkTable .page-list .react-bootstrap-table-pagination .react-bootstrap-table-pagination-list {
    background-color: white !important;
    margin: 0;
    z-index: 1;
}

.bulkQuoteSummaryTableWrap .page-list .react-bootstrap-table-pagination {
    background-color: white !important;
    margin: 0;
}

.react-grid-container {
    max-height: 450px; /* Set the max-height based on your design */
    overflow-y: auto; /* Enable vertical scrolling */
}

:not(:root):fullscreen::backdrop {
    background: white !important; /* Change the backdrop color to white */
}


.results-per-page {
    display: inline;
    margin: 0 10px 0 20px;
}


/* Targeting the footer container for vertical center alignment and background color */
/*.MuiTablePagination-root {*/
/*    display: flex;*/
/*    align-items: center !important; !* Centers items vertically *!*/
/*    justify-content: center; !* Optional: Centers items horizontally *!*/
/*}*/

/* If you want to change the text color of all elements within the footer */
.MuiDataGrid-footerContainer {
    min-height: 67px !important;
    max-height: 67px !important;
}

.MuiInputBase-root .MuiInputBase-colorPrimary .MuiTablePagination-input:hover {
    color: #FFFFFF;
    background-color: #753BBD;
}

.MuiIconButton-root {
    margin: 0 10px 0 10px !important;
}

.MuiIconButton-root svg {
    transform: translateX(-10px);
}

.MuiIconButton-root svg path {
    fill: #753bbd;
}

.MuiDataGrid-menuIcon svg {
    transform: translateX(-10px);
}

.MuiDataGrid-menuIcon svg path {
    fill: #753bbd;
}

.MuiListItemIcon-root svg path {
    fill: #743bbc;
}

.MuiTablePagination-displayedRows {
    margin: 0 0 0 0;
    display: flex;
    align-items: center !important; /* Centers items vertically */
    justify-content: center; /* Optional: Centers items horizontally */
}


/*Chatbot*/
.bp-widget-web {
    -webkit-font-smoothing: antialiased;
    background: none transparent;
    border: none;
    color-scheme: none;
    display: block;
    font-size: 16px;
    letter-spacing: 0;
    max-height: 100vh;
    max-width: 100vw;
    opacity: 1;
    padding: 0;
    position: fixed;
    transition: none;
    visibility: none;
    z-index: 20
}

.bp-widget-widget {
    bottom: 100px;
    height: 76px !important;
    left: auto;
    right: 10px;
    top: auto;
    width: 76px !important
}

.bp-widget-hidden {
    display: none
}

.bp-widget-convo {
    bottom: 24px;
    left: auto;
    max-height: 500px !important;
    min-height: 382px !important;
    right: 36px;
    top: auto;
    width: 420px !important
}

.bp-widget-side {
    bottom: 0;
    height: 100% !important;
    left: auto;
    right: 0;
    top: 0;
    width: 480px
}

@media only screen and (max-device-width: 768px) {
    .bp-widget-side {
        width: 100%
    }
}
