.circuitSummary {
    /* background: rgba(0, 0, 0, 0.8); */
    /* opacity: 0.8; */
    background: #02080b;
}
.circuitSummary, .ts-label {
    font-family: "montserrat", sans-serif !important;
    font-weight: 200;
    font-style: normal;
}
.circuitSummary h1 {
    font-size: 2em;
}
.contentCS {
    overflow-y: inherit;
}
.rowGrp {
    width: 51%;
}
.recordSetMini {
    width: 100%;
    margin:15px 0;

}
.recordSetMini tr td {
    border-bottom: 1px solid #FFF;
    border-top: 0;
    padding: 7px;
}
.recordSetMini tr:first-child td:last-child {
    border-top-right-radius: 10px;
}
.recordSetMini tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}
.recordSetMini tr:first-child td:first-child {
    border-top-left-radius: 10px;
}
.recordSetMini tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}


.rsIco {
    width: 70px;
    height: 50px;
    background-color: #fff;
    border-left: 1px solid #dddddd;
    vertical-align: middle !important;
    text-align: center;
}
.rsIco svg path {
    fill: #202022;
}
.rsField {
    width: 158px;
    background-color: #dddddd;
    color: #202022;
    vertical-align: middle !important;
    padding-left: 12px !important;
}
.rsField h4 {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
}
.rsValue {
    vertical-align: middle !important;
    width: 240px;
    background-color: #f1f1f1;
    border-left: 1px solid #f1f1f1;
    font-size: 14px;
    padding-left: 12px !important;
}
.rowGrp h2 {
    font-size: 1.5em;
}
.type100 {
    text-align: center;
    color: #FFF;
    display: none;
}
.res100 {
    position: relative;
    display: none;
}
.leftPos, .rightPos {
    position: absolute;
    top: -50px;
    width: 50%;
    margin: 0px 30px;
}
.leftPos {
    left: 0;
    text-align: left;
}
.rightPos {
    right: 0;
    text-align: right;
}
.labelLL, .labelLV {
    display: inline-block;
    font-size: 12px;
	width: 140px;
}
.labelLL, .leftBr path, .rightBr path {
    color: #ed2863;
    fill: #ed2863;
}
.labelLV {
    color: #FFF;
}
.leftBr, .rightBr {
    position: absolute;
    top: -15px;
}
.leftBr {
    left: -20px;
}
.rightBr {
    left: 106%;
}
.responsiveText {
    display: none;
}

@media screen and (min-width: 1500px) {
    .res100, .type100 {
        display: block;
    }
    .responsiveText {
        display: inline-block;
    }
}

/*Fiber Path CSS*/
.modalDialog {
    width: 425px;
}
.modalBody {
    height: 80vh;
}
.modalContent {
    border-radius: 0;
}
.modalBodyCapture, .modalBody {
    overflow-y: auto;
    overflow-x: auto;
}
.modalBodyCapture td {
	font-size: 12px !important;
	text-align: justify !important;
}
.downloadbtnWrap {
	position: relative;
	text-align: right;
	padding-top: 10px;
	padding-bottom: 10px;
}
.downloadbtnWrap button {
    border-radius: 5px;
    padding: 0 58px 0 16px;
}

.hidelightLevels .hidellCore, .hidelightLevels .hidellPanel {
	height: 250px;
}

.outageLabel {
	padding-left: 10px;
	position: relative;
}

.ll-totals-dbm span {
    font-size: 20px !important;
    font-weight: bold;
    color: #59cbe8;
}
