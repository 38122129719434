.mandatoryProfile, .errorMsg {
    color: #f00 !important;
    font-weight: bolder;
}

.errorMsg {
    font-size: 12px;
    font-weight: 400;
}

label {
    margin: 0;
}

.labelText {
    color: #753bbd;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 30px;
    text-transform: uppercase;
}

.userIdTextProfile {
    color: #753bbd;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
}

.inputFldTextProfile {
    border: 0;
    border-bottom: 1px solid #ccc;
    width: 400px;
    display: block;
    border-radius: 0;
    height: 40px;
    font-size: 16px !important;
}

.selectFldProfile {
    border: 0;
    border-bottom: 1px solid #ccc;
    width: 400px;
    font-size: 12px;
    color: #753BBD;
    height: 40px;
    appearance: auto;
    /*-webkit-appearance: auto;*/
    display: block;
}

.selectFld {
	width: 100%;
	height: 25px;
	border: 0;
	border-bottom: 1px solid #ccc;
	font-size: 16px !important;
	position: relative;
	background: url(../../images/svg/ico-select.svg) no-repeat center right 5px;
	background-size: 15px 7px;
	border-radius: 0;
	padding-left: 5px;
	padding-top: 0;
	padding-bottom: 0;
}

.inputFldPhoneProfile {
    width: 150px;
}

.quotingOptionProfile .labelText {
    margin-left: 10px;
    text-transform: none;
}

.agentLabelTextProfile {
    margin-left: 10px !important;
    text-transform: none;
}

.selectWrap {
    width: 350px;
}

.selectWrap svg path {
    fill: #753bbd;
}

.selectWrap .profile-autoComplete__indicator .profile-autoComplete__clear-indicator {
    display: none;
}

.overlyProfile {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: #000;
    opacity: 0.5;
}

.nextBtn {
    padding: 5px 10px !important;
    border-radius: 5px;
}

.nextBtn svg {
    position: unset;
    padding-top: 2px;
}

.cancelBtn {
    padding: 3px 10px !important;
    border-radius: 5px;
}

.cancelBtn svg {
    position: unset;
    margin: 11px 0 11px 10px;
}

.overlayProfile {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: #000;
    opacity: 0.5;
}

.downloadBtn {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-bottom: 20px;
}

.activeUserLinksContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}
.activeUserLinks {
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 0 20px 0 20px;
}

.listDisplayText {
    font-size: calc(8px + 0.5vw) !important;
}