.f16 {
	font-size: 16px;
}

.orderDetailsPage {
	background-color: #FFF;
	height: auto !important;
	overflow-y: inherit;
	min-height: auto;
}

.quoteTabs {
	background-color: rgba(255, 255, 255, .1);
	width: 25%;
	margin-bottom: 130px;
}

.dFlex {
	display: flex;
}

.spaceBetween {
	justify-content: space-between;
}

.sectionTitle {
	font-size: 1.5em;
	margin-bottom: 39px;
}

.sectionTitleSupplement {
	font-size: 1.5em;
	margin: 0 0 39px -10px;
}

.pageAction {
	left: auto;
	right: 0;
	width: auto;
}

	.pageAction button {
		line-height: 46px;
		font-size: 22px;
		border-radius: 4px;
		font-family: "montserrat", sans-serif !important;
		font-weight: 400;
		font-style: normal;
	}

.manageQuoteTitle {
	font-size: 16px;
}

.manageQuoteTitlePopup {
	font-size: 1.5em;
	font-family: "montserrat", sans-serif !important;
	font-weight: 400;
	font-style: normal;
}

.manageQuoteDesc {
	color: #75787B;
	font-size: 16px;
}

.filterWrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 25px;
}

	.filterWrap button svg {
		left: 20px;
		top: -7px;
	}

.quotetable {
	height: 45vh;
	overflow-y: auto;
	overflow-x: hidden;
}

	.quotetable::-webkit-scrollbar-thumb {
		height: 50px;
	}

.darkPanel h2 {
	font-family: "montserrat", sans-serif !important;
	font-weight: 400;
	font-style: normal;
	font-size: 1.5em;
}

.viewListFilter {
	margin: 5px 0;
}

.quoteName {
	font-size: 16px;
	color: #75787B;
	font-family: "montserrat", sans-serif !important;
	font-weight: 400;
	font-style: normal;
	margin: 0;
	line-height: 46px;
}

.rowGrp, .colGrp {
	margin: 10px 0;
}

.productList {
	margin: 0;
	list-style-type: none;
	width: 100%;
	display: flex;
	height: 100%;
}

	.productList li {
		display: inline-block;
		padding: 10px;
		background-color: #ccc;
		width: 40%;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		font-size: 16px;
		color: #000;
		font-weight: 700;
		height: 100%;
	}

		.productList li:last-child {
			background-color: #eee;
			width: 60%;
			border-radius: 0 5px 5px 0;
			font-weight: 400;
			height: 100%;
			word-break: break-word;
		}

.tableAlt tr {
	border-bottom: 1px solid #ddd;
}

.tableAlt td {
	font-size: 12px;
	color: #999;
	font-weight: 400;
}

.tableAlt tr:nth-child(odd) {
	background-color: #eee;
}

.headingS {
	font-size: 12px;
	color: #999;
	margin: 20px 0;
}

.uploadFile {
	position: absolute;
	left: 0;
	opacity: 0;
	top: 0;
	bottom: 0;
	width: 100%;
}

.uploadLabelText {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	border: 1.5px solid #753BBD;
	border-radius: 5px;
	color: #753BBD;
	font-size: 13px;
	text-transform: capitalize;
	cursor: pointer;
}

.uploadLabel {
	display: inline-block;
	position: relative;
	height: 40px;
	width: 80px;
	margin-left: 20px;
}

.uploadLabelText.dragover {
	background-color: #aaa;
}

.uplodadFileList a {
	display: block;
}

.themeBtn {
	border-radius: 4px;
	color: #753BBD;
	padding: 8px 16px;
	border: 2px solid #753BBD;
}

.selectFld {
	width: 100%;
	height: 25px;
	border: 0;
	border-bottom: 1px solid #ccc;
	font-size: 14px;
	position: relative;
	background: url(../../images/svg/ico-select.svg) no-repeat center right 5px;
	background-size: 15px 7px;
	border-radius: 0;
	padding-left: 5px;
	padding-top: 0;
	padding-bottom: 0;
}

	.selectFld option {
		font-size: 12px;
	}

.orderEditInputFld {
	width: 100% !important;
	font-size: 12px !important;
}

.uploadText {
	font-size: 12px;
	color: #753BBD;
	position: absolute;
}

.uploadBtn {
	border: 2px solid #753BBD;
	border-radius: 5px;
	color: #753BBD;
	font-size: 13px;
	text-transform: capitalize;
	cursor: pointer;
	height: 40px;
	width: auto;
	vertical-align: top;
	margin-left: 20px;
}

.uplodadFileList {
	margin-top: 30px;
}

.lableTextS {
	font-size: 12px;
	color: #753BBD;
	display: block;
	margin: 5px 0;
}

.filterDate {
	font-size: 12px;
	color: #999;
}

.noticesSec {
	border-radius: 5px;
	width: 88%;
}

	.noticesSec p {
		font-size: 13px;
		text-align: justify;
		margin-bottom: 0;
		padding: 15px 15px 0;
		background-color: #eee;
	}

		.noticesSec p:last-child {
			padding-bottom: 15px;
		}

.lastSec {
	margin-bottom: 100px;
}

.textTheme {
	color: #753BBD;
	font-weight: 500;
}

.progressBar {
	position: relative;
	display: flex;
	margin: 0 15px;
	color: #FFFFFF;
	background-color: #753BBD;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

	.progressBar li {
		width: calc(12.5% + 2px);
		padding: 5px 17px;
		font-size: 9px;
		font-weight: 400;
		font-style: normal;
		display: inline-block;
		background-color: #753BBD;
		color: #FFF;
		text-align: center;
		position: relative;
		margin: 0 -1px;
	}

		.progressBar li::after {
			content: '';
			display: block;
			height: 34px;
			width: 34px;
			position: absolute;
			top: 0px;
			right: 0;
			z-index: 9;
			transform: rotate(135deg);
			border: 1px solid #fff;
			border-right: 0;
			border-bottom: 0;
		}

		.progressBar li:last-child:after {
			display: none;
		}

		.progressBar li:first-child {
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
		}

		.progressBar li:last-child {
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
		}

		.progressBar li.active {
			background-color: #753BBD;
		}

			.progressBar li.active::after {
				display: none;
			}

.leaseTable thead {
	background-color: #ccc;
}

.leaseTable tbody {
	background-color: #eee;
}

.leaseTable thead th {
	border: 0;
}

.leaseTable thead th,
.leaseTable tbody td {
	font-size: 16px;
	border-bottom: 0;
}

.labelFld {
	font-size: 12px;
	display: block;
	text-transform: capitalize;
}

.textArea {
	width: 60%;
	height: 80px;
	border-color: #CCC;
	border-radius: 1px;
}

.notesTab {
	margin-left: 15px;
}

	.notesTab li {
		display: inline-block;
		list-style-type: none;
		background-color: #753BBD;
		font-size: 16px;
		padding: 5px 10px;
		width: 25%;
		text-align: center;
	}

		.notesTab li:first-child {
			border-top-left-radius: 15px;
			border-bottom-left-radius: 15px;
			border-right: 1px solid #FFF;
		}

		.notesTab li:last-child {
			border-top-right-radius: 15px;
			border-bottom-right-radius: 15px;
		}

		.notesTab li.active {
			background-color: #7202E1;
			/*background-color: #EF9123;*/
		}

		.notesTab li a {
			width: 100%;
			display: block;
		}

.notesBtn {
	background-color: #753BBD;
	color: #FFF;
	text-transform: uppercase;
	display: block;
	margin-top: 10px;
	font-size: 14px;
	padding: 8px 10px;
	border-radius: 5px;
}

.notesBtn2 {
	color: #FFF;
}

.noteList {
	background-color: #ccc;
	border-radius: 5px;
	margin-top: 10px;
}

	.noteList li {
		display: block;
		color: #000;
		font-size: 16px;
		padding: 8px 10px;
	}

		.noteList li:first-child {
			font-weight: 700;
		}

.hr {
	border-color: #ddd;
}

.textS {
	font-size: 10px;
	font-weight: 100;
	display: block;
}

.stepsOuter {
	margin: 25px 0;
}

.orderViewHeader {
	font-size: 24px;
	font-weight: 600;
	text-align: center;
	color: #753BBD;
}

@media screen and (max-width: 1545px) {
	.progressBar li {
		padding: 5px;
		font-size: 9px;
	}

		.progressBar li::after {
			height: 28px;
			width: 28px;
		}
}

.circuitBtn {
	border: 1.5px solid #753BBD;
	border-radius: 5px;
	color: #753BBD;
	font-size: 16px;
	text-transform: capitalize;
	cursor: pointer;
	height: 40px;
	vertical-align: top;
	margin-left: 20px;
}

/* Customer Letter Correspondance on OrderView */
.letterContainer {
	margin-bottom: 20px;
}

.letterHeader {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
}

	.letterHeader p {
		margin: 0;
		flex: 1 0 30%;
		white-space: nowrap;
	}

	.letterHeader b {
		font-weight: bold;
	}

.letterBody {
	margin-top: 10px;
}

.sub-hdr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.headerContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.headerRight {
	display: flex;
	align-items: center;
}