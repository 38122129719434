.rowGrp {
    margin: 10px 30px 30px 0px;
}
.subHeader {
    margin-top: 35px;
}
.sectionTitle {
    font-size: 24px;
    margin: 0;
    margin-bottom: 15px;
}
.mandatory {
    color: #f00 !important;
    font-weight: bolder;
}
.lableTextS {
    margin: 0;
    font-size: 16px;
    color: #753BBD;
}
.inputFld {
    width: 100%;
    height: 25px;
    font-size: 16px !important;
    border: 0;
    border-bottom: 1px solid #ccc;
    padding-left: 5px;
}
.selectFldState {
    width: 100%;
    height: 25px;
    border: 0;
    border-bottom: 1px solid #ccc;
    font-size: 16px !important;
    position: relative;
    background: url(../../images/svg/ico-select.svg) no-repeat center right 5px;
    background-size: 15px 7px;
    border-radius: 0;
    padding-left: 5px;
    padding-top: 0;
    padding-bottom: 0;
}
.selectFld option {
    font-size: 16px !important;
}

.avResultList {
    margin: 0;
    list-style-type: none;
    width: 100%;
    display: flex;
    height: 100%;

}

.avResultList li {
    display: inline-block;
    padding: 10px;
    background-color: #ccc;
    width: 40%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 16px;
    color: #000;
    font-weight: 700;
    height: 100%;
    word-break: break-word;
}

.avResultList li:last-child {
    background-color: #eee;
    width: 60%;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-weight: 400;
    height: 100%;
}
