.rotateArrow {
    transform: rotate(180deg);
    top: 3px !important;
}
.lableText {
    color: #222;
}
.stepThreeHeader {
    display: flex;
}
.textTheme {
    color: #753BBD;
}
.overly {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: #000;
    opacity: 0.5;
}
.noteList {
	background-color: #ccc;
	border-radius: 5px;
	margin-top: 10px;
}

.noteList li {
	display: block;
	color: #000;
	font-size: 16px;
	padding: 8px 10px;
}

.noteList li:first-child {
	font-weight: 700;
}

.inputField, .inputFieldHalf, .dateFld {
    border: 1px solid #999 !important;
    color: #999;
    border-radius: 0;
    width: 100%;
    height: 38px;
    padding-left: 10px;
}
.inputField:focus, .inputFieldHalf:focus, .dateFld:focus {
    outline: 0;
    box-shadow: none;
}
.inputFieldHalf {
    width:40%;
}
.textarea {
    height: 65px;
}
.dateField:after {
    width: 25px !important;
    height: 20px !important;
    right: 5px !important;
}

.btnTheme {
    background: #753BBD;
    line-height: inherit;
    border-radius: 0;
    color: #FFF;

}
.selectWrap::after {
    content: '';
    height: 15px;
    width: 15px;
    position: absolute;
    top:50%;
    right: 5px;
    transform: translateY(-50%);
    background: url(../../images/svg/ico-select.svg) center center no-repeat;
    background-size: 100%;
}
.mandatory {
    color: #f00;
}
.widthLess25px {
    width: calc(100% - 21px);
}
.disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}
.subHdrAlter {
    margin-top: 24px;
}
.sectionTitle {
    font-size: 1.5em;
    margin-bottom: 39px;
}
.rowGrp, .colGrp {
    margin: 10px 0;
}
.productList, .summaryList{
    margin: 0;
    list-style-type: none;
    width: 100%;
    display: flex;
    height: 100%;
}
.productList li {
    display: inline-block;
    padding: 10px;
    background-color: #ccc;
    width: 35%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 16px;
    color: #000;
    font-weight: 700;
    height: 100%;
}
.summaryList li {
    display: inline-block;
    padding: 10px;
    background-color: #ccc;
    width: 20%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 16px;
    color: #000;
    font-weight: 700;
    height: 100%;
}
.summaryList li:last-child {
    background-color: #eee;
    width: 100%;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-weight: 400;
    height: 100%;
}
.productList li:last-child {
    background-color: #eee;
    width: 60%;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-weight: 400;
    height: 100%;
}
.noticesSec {
    border-radius: 5px;
    /* width: 88%; */
}
.noticesSec p {
    font-size: 16px;
    text-align: justify;
    padding: 15px;
    margin-bottom: 0;
    padding-bottom: 0;
    background-color: #eee;
}
.noticesSec p:last-child {
    padding-bottom: 15px;
}
.checkboxLabel {
    font-size: 14px !important;
}
.checkbox {
    height: 16px !important;
    width: 16px !important;
}
.dropdownMenu {
    height: 85vh;
    overflow-y: auto;
    position: unset !important;
    width: calc(100% - 20px);
}
.ticketActionBtn {
	background-color: white;
	color: #753BBD;
	display: block;
	margin-top: 10px;
	font-size: 14px;
	padding: 8px 10px;
	border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.breakLine {
	white-space: pre-line;
}
