.coherentProbeTable thead {
    background-color: #753BBD !important;
    color:white;
    font-weight: bold;
    font-size: 14px;
}

.coherentProbeTable td:nth-child(odd) {
    color: #753BBD;
    text-align: center;
}

.coherentProbeTable td:nth-child(even) {
    text-align: center;
    border-right: 2px solid black !important;
}

.coherentProbeTable td:last-child {
    border-right: 1px solid #dee2e6 !important;
}

.coherentProbeDesc {
    color: black !important;
}

.coherentProbeClliHeader {
    color: black !important;
    font-weight: bold;
}

.dividers {
    height: 11px;
    background-color: #eee;
    margin: 0 -24px;
    border-top: 1px solid #ddd;
}
