.rowGrp, .colGrp {
	margin: 10px 0;
}

label {
    margin: 0;
}

.labelText {
    color: #753bbd;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 30px;
    text-transform: uppercase;
}
