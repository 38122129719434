.rowGrp {
    margin: 10px 30px 30px 0px;
}
label {
    text-transform: uppercase;
}
.lableTextS {
    margin: 0;
    font-size: 14px;
    color: #753bbd;
}
.labelSelectRequiredFld {
    font-size: 14px;
    color: #753bbd;
}
.mandatory {
    color: #f00;
}
.inputFld {
    width: 100%;
    height: 25px;
    font-size: 12px;
    border: 0;
    border-bottom: 1px solid #ccc;
    padding-left: 5px;
}
.checkboxText {
    margin-left: 5px;
    margin-right: 15px;
    font-size: 12px;
    color: #30A6AF;
}
.selectFld {
    width: 100%;
    height: 25px;
    border: 0;
    border-bottom: 1px solid #ccc;
    font-size: 12px;
    position: relative;
    background: url(../../images/svg/ico-select.svg) no-repeat center right 5px;
    background-size: 15px 7px;
    border-radius: 0;
    padding-left: 5px;
    padding-top: 0;
    padding-bottom: 0;
}
.selectFld option {
    font-size: 13px;
}
.nextBtn {
    padding-left: 16px !important;
}
.nextBtn svg {
    position: unset;
    margin-top: 4px;
    margin-right: 0;
    margin-left: 10px;
}
.cancelBtn {
    border: 2px solid #753bbd;
    padding: 8px 16px !important;
    border-radius: 6px;
}