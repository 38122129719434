.tableWrap {
	/* border-top: 1px solid #999; */
}

	.tableWrap table th {
		background-color: transparent;
	}

.noDataWrap {
	height: 150px;
}

.footerdateSearchWrap {
	position: absolute;
	bottom: 10px;
	right: 50px;
	background-color: transparent;
}

.footerCheckboxWrap {
	position: absolute;
	bottom: 10px;
	right: 200px;
	background-color: transparent;
}

#allUsers input[type="checkbox"] {
	margin-right: 5px;
}

.textTransformNone {
	text-transform: none;
}

.textColor {
	color: whitesmoke;
}

li {
	list-style-type: none;
}

.userManageTable {
	/* height: 50vh; */
	margin-top: 20px;
	/* overflow: auto; */
}

.UserManagement-page .react-bootstrap-table-pagination div:first-child,
.UserManagement-page .react-bootstrap-table-pagination div {
	position: absolute;
	top: -50px;
	height: 40px;
}

.addUserButton {
	font-weight: 400;
	background-color: #595A5C;
	cursor: pointer;
	color: white;
	border: 2px solid #333333;
	border-radius: 5px;
	height: 45px;
	width: 120px;
}

	.addUserButton:hover {
		font-weight: 500;
		border: 2px solid white;
	}

	.addUserButton:disabled {
		color: #777;
		border: 2px solid #777;
	}
