.tableWrap {
    /* border-top: 1px solid #999; */
}

.tableWrap table th {
    background-color: transparent;
}

.noDataWrap {
    height: 150px;
}

.selectWrap {
    display: flex;
    align-items: center;
}

.label {
    font-size: 12px;
    color: #000;
    font-weight: 600;
    width: 130px;
}

.utilityTable table tr td, .utilityTable table tr th {
    padding: 10px;
    word-break: break-word !important;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: #000;
    opacity: 0.5;
}

.enrollmentToolTipInfo {
    font-size: 12px !important;
    border-radius: 0 !important;
    width: 300px;
    position: relative;
    background: black !important;
    line-height: 30px !important;
    height: 110px !important;
}

.enrollmentToolTipInfo img {
    position: absolute;
    top: -3px !important;
    left: -5px !important;
    background: black;
    padding: 15px;
    border-radius: 50%;
    width: 65px;
    border: 1px solid #ccc;
}

.enrollmentInfoWindow {
    height: auto !important;
    font-size: 12px !important;
    line-height: 20px !important;
}

.enrollmentInfoWindow.hover {
    visibility: visible !important;
    opacity: 1 !important;
}

.enrollmentInfoWindow img {
    left: -54px !important;
    top: -15px !important;
}

.selectCircuitBtn {
    color: #753BBD;
    font-size: 14px;
}

.enrollUpdateBtn {
    padding-left: 16px !important;
}

.enrollUpdateBtn svg {
    position: unset;
    margin-top: 4px;
    margin-right: 0;
    margin-left: 10px;
}

.enrollmentTable th, td {
	font-size: 14px !important;
	/*text-align: center !important;*/
}
