/* Helpers */

/************************
Custom Column 'Framework'
************************/
.col, .fl, .pull-left { float: left; }
.fr, .pull-right { float: right; }
.fnone { float:none; }
.col { min-height:1px; }

.w1 { width:1%; }
.w2 { width:2%; }
.w3 { width:3%; }
.w4 { width:4%; }
.w5 { width:5%; }
.w6 { width:6%; }
.w7 { width:7%; }
.w8 { width:8%; }
.w9 { width:9%; }
.w10 { width:10%; }
.w11 { width:11%; }
.w12 { width:12%; }
.w13 { width:13%; }
.w14 { width:14%; }
.w15 { width:15%; }
.w16 { width:16%; }
.w17 { width:17%; }
.w18 { width:18%; }
.w19 { width:19%; }
.w20 { width:20%; }
.w21 { width:21%; }
.w22 { width:22%; }
.w23 { width:23%; }
.w24 { width:24%; }
.w25 { width:25%; }
.w26 { width:26%; }
.w27 { width:27%; }
.w28 { width:28%; }
.w29 { width:29%; }
.w30 { width:30%; }
.w31 { width:31%; }
.w32 { width:32%; }
.w33 { width:33%; }
.w34 { width:34%; }
.w35 { width:35%; }
.w36 { width:36%; }
.w37 { width:37%; }
.w38 { width:38%; }
.w39 { width:39%; }
.w40 { width:40%; }
.w41 { width:41%; }
.w42 { width:42%; }
.w43 { width:43%; }
.w44 { width:44%; }
.w45 { width:45%; }
.w46 { width:46%; }
.w47 { width:47%; }
.w48 { width:48%; }
.w49 { width:49%; }
.w50 { width:50%; }
.w51 { width:51%; }
.w52 { width:52%; }
.w53 { width:53%; }
.w54 { width:54%; }
.w55 { width:55%; }
.w56 { width:56%; }
.w57 { width:57%; }
.w58 { width:58%; }
.w59 { width:59%; }
.w60 { width:60%; }
.w61 { width:61%; }
.w62 { width:62%; }
.w63 { width:63%; }
.w64 { width:64%; }
.w65 { width:65%; }
.w66 { width:66%; }
.w67 { width:67%; }
.w68 { width:68%; }
.w69 { width:69%; }
.w70 { width:70%; }
.w71 { width:71%; }
.w72 { width:72%; }
.w73 { width:73%; }
.w74 { width:74%; }
.w75 { width:75%; }
.w76 { width:76%; }
.w77 { width:77%; }
.w78 { width:78%; }
.w79 { width:79%; }
.w80 { width:80%; }
.w81 { width:81%; }
.w82 { width:82%; }
.w83 { width:83%; }
.w84 { width:84%; }
.w85 { width:85%; }
.w86 { width:86%; }
.w87 { width:87%; }
.w88 { width:88%; }
.w89 { width:89%; }
.w90 { width:90%; }
.w91 { width:91%; }
.w92 { width:92%; }
.w93 { width:93%; }
.w94 { width:94%; }
.w95 { width:95%; }
.w96 { width:96%; }
.w97 { width:97%; }
.w98 { width:98%; }
.w99 { width:99%; }
.w100 { width:100%; }

.ml1 { margin-left: 1%; }
.ml2 { margin-left: 2%; }
.ml3 { margin-left: 3%; }
.ml4 { margin-left: 4%; }
.ml5 { margin-left: 5%; }
.ml10 { margin-left: 10%; }
.mr1 { margin-right: 1%; }
.mr2 { margin-right: 2%; }
.mr5 {  margin-right: 5%; }
.mr10 {  margin-right: 10%; }


/* helpers */
.centered { margin:0 auto; clear: both; }
.clear { clear: both; }
.clear-right { clear: right; }
.clear-left { clear: left; }
.left, .l { text-align: left; }
.r { text-align: right; }

.no-margin, .no-margin .checkbox, .no-margin .radio { margin:0 !important; }
.no-padding { padding:0 !important; }

.collapse { border-collapse: collapse; }
.no-border { border: none; }
.inset { border-style: inset; }

.table { display: table; }
.table-row { display: table-row; }
.cell, .show-cell { display: table-cell; }
.fix-table { table-layout:fixed;}

.fixed { position: fixed; }
.block, .ico-block::before { display: block; }
.inline { display: inline-block; }
.valign-top { vertical-align: top; }
.valign-middle { vertical-align: middle; }
.valign-bottom { vertical-align: bottom; }
.valign-inherit { vertical-align: inherit; }
.ellipsis { text-overflow: ellipsis; white-space: nowrap; }


.pointer { cursor: pointer }
.move { cursor: move; }
.not-allowed { cursor: not-allowed !important; }
.default-cursor { cursor: inherit !important; }

.noline, a.noline:hover { text-decoration: none; }
.italic { font-style: italic; }

.hide-overflow { overflow: hidden; }

.scrollbox, .codebox { overflow: hidden; -webkit-overflow-scrolling: touch; overflow-y: auto; }
.scrollbox { overflow-x: hidden; }
.codebox { overflow-x: auto; width:100%; height:250px; }

.broken-link { color: #d00; background-color: #ff0; }

.noselect { -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

.no-x::-ms-clear { display: none; }

.wrap { word-wrap: normal; }
.nowrap { white-space: nowrap; }
.forcewrap { word-break: break-all; }
.forcewrap-all { 
    /* JS solution/changeset Portal%20Portfolio/_versionControl/changeset/19395 */
    -ms-word-break: break-all; word-break: break-all; /*word-break: break-word;*/
    -webkit-hyphens: auto;
       -moz-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
}

.invisible { visibility: hidden; }
.affix { position: fixed; }

.full-height { height: 100%; }

/* Ported over from old Pre-2020 UI */
.gray { color: #8a8a8a; }
.indent-6 { padding-left: 6px; }
.mr3px { margin-right: 3px; }
.show-on1, .show-on2, .show-on3, .inline-on1, .inline-on2, .inline-on3, .show-table-on1, .show-table-on2, .show-table-on3 { display: none; }
.brdr-btm-gray { border-bottom: 1px solid #e1e1e1; }
