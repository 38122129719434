.ticketHeader {
    max-width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #000;
    margin-bottom: 25px;
    padding:10px 0;
}
.headerNav {
    margin: 10px;
}
.label {
    color: #fff;
    font-size:13px;
    margin:0;
    text-align: center;
    display: block;
}
.themeText {
    color: #753BBD;
}
.searchIcoInputField {
    position: absolute;
    top: 9px;
    /*left: 7px; */
    width: 20px;
    transform: rotate(-90deg);

}
.searchIcoInputFieldCirInventory {
    width: 24px;
    transform: none;
}
.inputField {
    padding-left: 30px;
    box-sizing: border-box !important;
    border-radius: 30px;
}

.overly {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: #000;
    opacity: 0.5;
}
.textDecoration, .textDecoration:hover {
    text-decoration: underline;
}
.textThemeSVG {
    fill: #753BBD;
}
.downloadIco {
    position: unset;
    height: 40px;
    width: 40px;
}
.filterWrap {
    margin-top:20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.tableWrap {
    /* border-top: 1px solid #999; */
}
.tableWrap table th {
    background-color: transparent;
}
.daysDropdown{
    background-color:rgba(0,0,0, 0.9);
    color: #753BBD;
    border: 1px solid #753BBD;
    font-size: 14px;
    padding: 8px;
}
.daysDropdown option{
    background-color:rgba(0,0,0, 0.9);
    color:#753BBD;
}
.dateFieldWrap {
    width: 145px !important;
    display: inline-block;
    background-color: transparent;
}
.dateField {
    width: 100% !important;
    font-size: 12px;
    padding-right: 0px;
}
.dateField:first-child {
    /* margin-right: 10px; */
}
.dateField:after {
    width: 20px !important;
    height: 20px !important;
    right: 7px !important;
    background-size: 80% !important;
}
.posCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.refreshSVG {
    width: 28px;
    margin-right: 10px;
    margin-top: 5px;
}
.refreshSVGPath {
    fill: #753BBD
}
.fixedFooter {
    position: fixed;
    bottom: 25px;
    width: 55%;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0,0,0, 0.9);
    border-color: transparent #753BBD;
    text-transform: none;
    border-radius: 70px;
    height: auto;
    padding: 0;
}
.fixedFooterForCircuitInventory {
    width: 54%;
    height: 122px;
}
.dInlineBlock {
    display: inline-block;
}
.footerGrid {
    /* margin: 0 5px; */
}
.labelText {
    font-size: 11px;
    text-transform: uppercase;
}
.circuitInventoryLabelText {
    font-size: 14px;
}

.menuLabelText {
    font-size: 14px;
    text-transform: uppercase;
}
.circleWrap {
    height: 40px;
    width: 40px;
    border: 2px solid #753BBD;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    vertical-align: middle;
    margin-left: 5px;
}
.posText {
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.btnTextDate {
    font-size: 11px;
    color: #fff;
    border: 1px solid #fff;
}
.footerdateSearchWrap {
    background-color: transparent;
}
.dateField, .btnTextDate {
    border: 1px solid #753BBD !important;
    border-radius: 5px;
    height: 30px;
    padding-left: 30px !important;
    font-size: 11px;
    font-weight: 700;
}
.btnTextDate {
    padding-left: 0px !important;
}
.borderRight0 {
    border-right: 0 !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.borderLeft0 {
    border-left: 0 !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.btnTextDate {
    color: #753BBD;
    padding: 6px 10px;
    margin-right: 10px;
    display: inline-block;
vertical-align: top;
}
.searchFld, ::placeholder {
    width: 100%;
    border: 0;
    border-bottom: 1px solid white;
    background-color: transparent;
    height: 38px;
    padding-left: 20px !important;
    font-size: 14px;
    color: white !important;
}
.circuitInventorySearchFld::placeholder {
    color: white;
}
.circuitInventorySearchFld {
	font-size: 16px;
	border-bottom: 1px solid white;
	text-indent: 20px;
}
.spaceBetween {
    justify-content: space-between;
    margin: 5px 30px;
}
.spaceBetweenForCircuitInventory {
    margin: 5px 30px;
}
.textTheme {
    color :#753BBD;
}
.pathTheme {
    fill: #753BBD;
}
.timeIcon {
    width: 25px;
    height: 15px;
    vertical-align: middle;
    margin-right: 2px;
}
.downloadIco {
    width: 20px;
    vertical-align: middle;
}
.createTicketIco {
    width: 35px;
    vertical-align: middle;
    margin-left: 5px;
}
.textCenter {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}
.exportText {
    position: absolute;
    top:50%;
    left: 40px;
    transform: translateY(-50%);
    display: inline-block !important;
}
.dateLabel {
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    font-size: 10px;
    font-weight: 700;
    color: #999;
}
input:focus, input:active {
    outline: 0;
    box-shadow: none !important;
}
.tsGreenBG {
    background-color: #753BBD;
}
.posRight {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
}
.infoIco {
    width: 22px;
}
.infoIcoCircuitInventory {
    width: 24px;
}
.toggleSwitch {
    margin: 0 5px 0 5px;
}
.InfoHoverWrap {
    width: 300px;
    background-color: #000;
    padding: 20px;
    position: relative;
    color: #fff;
}
.infoIcoPopup {
    position: absolute;
    top:-10px;
    left: -10px;

}
.pointer {
    cursor: pointer;
}
.btnActive {
    color: #FFF;
    background-color: #753BBD;
}

.footerdateSearchWrap input[type=date], .footerdateSearchWrap input[type=date]::after {
    background-color: transparent;
    font-size: 12px !important;
}
.noDataWrap {
    height: 150px;
}
.headerIcon {
    height: 30px;
    width: 30px;
    margin: 0 auto;
}
.headerIconForTroubleTicketsHistory {
    width: 44px;
}

.filterTextLabel {
    color: white;
}

.filterTextLabel:hover {
    cursor: pointer;
    color:#753BBD;;
}

.searchByIncident {
    width: 30% !important;
}

@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 992px) {
    .searchableParams, .desktopView {
        display: none;
    }
}

@media screen and (max-width: 768px) {

}

/* My Netork Circuit Inventory */
.centerContent {
	display: flex;
	justify-content: center;
	flex-wrap: nowrap !important;
	width: 100%;
}

.positionCenter {
	text-align: center;
	padding: 10px;
}

.filterInputFld {
	display: block;
	margin: 10px auto;
}

.buttonSpacing {
	margin-left: 10px;
}
