.quoteDetailsPage {
	background-color: #FFF;
	width: 75%;
	height: auto !important;
	overflow-y: inherit;
}

.quoteBulkDetailsPage {
	background-color: #FFFFFF;
	width: 75%;
	height: auto !important;
	overflow-y: inherit;
	overflow-x: scroll;
}

.quoteTabs {
	background-color: rgba(255, 255, 255, .1);
	width: 25%;
	margin-bottom: 130px;
}

.dFlex {
	display: flex;
}

.spaceBetween {
	justify-content: space-between;
}

.sectionTitle {
	font-size: 1.5em;
	margin-bottom: 39px;
}

.pageAction {
	left: auto;
	right: 0;
	width: auto;
}

	.pageAction button {
		line-height: 46px;
		font-size: 22px;
		border-radius: 4px;
		font-family: "montserrat", sans-serif !important;
		font-weight: 400;
		font-style: normal;
	}

.manageQuoteTitle {
	font-size: 16px;
}

.manageQuoteTitlePopup {
	font-size: 1.5em;
	font-family: "montserrat", sans-serif !important;
	font-weight: 400;
	font-style: normal;
}

.manageQuoteDesc {
	color: #75787B;
	font-size: 16px;
}

.filterWrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 25px;
}

	.filterWrap button svg {
		left: 20px;
		top: -7px;
	}

.quotetable {
	height: 45vh;
	overflow-y: auto;
	overflow-x: hidden;
}

	.quotetable::-webkit-scrollbar-thumb {
		height: 50px;
	}

.darkPanel h2 {
	font-family: "montserrat", sans-serif !important;
	font-weight: 400;
	font-style: normal;
	font-size: 1.5em;
}

.viewListFilter {
	margin: 5px 0;
}

.quoteName {
	font-size: 16px;
	color: #75787B;
	font-family: "montserrat", sans-serif !important;
	font-weight: 400;
	font-style: normal;
	margin: 0;
	line-height: 46px;
}

.rowGrp, .colGrp {
	margin: 10px 0;
}

.productList {
	margin: 0;
	list-style-type: none;
	width: 100%;
	display: flex;
	height: 100%;
}

	.productList li {
		display: inline-block;
		padding: 10px;
		background-color: #ccc;
		width: 40%;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		font-size: 16px;
		color: #000;
		font-weight: 700;
		height: 100%;
		word-break: break-word;
	}

		.productList li:last-child {
			background-color: #eee;
			width: 60%;
			border-radius: 0;
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
			font-weight: 400;
			height: 100%;
		}

.tableAlt {
	display: table-cell;
	width: auto !important;
}

	.tableAlt input[type=text] {
		font-size: 14px;
		width: 75%;
	}

	.tableAlt thead {
		background-color: #FFF;
		/* border-top: 1px solid #dee2e6; */
	}

	.tableAlt tbody {
		background-color: #eee;
	}

	.tableAlt tr {
		border-bottom: 1px solid #ddd;
	}

	.tableAlt td {
		font-size: 16px;
		color: #212529;
		font-weight: 400;
		white-space: nowrap;
		height: 50px !important;
	}

	.tableAlt th {
		border: 0 !important;
		white-space: nowrap;
	}


.headingS {
	font-size: 16px;
	color: #212529;
	margin: 20px 0;
	margin-top: 80px;
}

.uploadFile {
	position: absolute;
	left: 0;
	opacity: 0;
	top: 0;
	bottom: 0;
	width: 100%;
}

.uploadLabelText {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	border: 1.5px solid #753BBD;
	border-radius: 5px;
	color: #753BBD;
	font-size: 16px;
	text-transform: capitalize;
	cursor: pointer;
}

.uploadLabel {
	display: inline-block;
	position: relative;
	height: 40px;
	width: 120px;
	margin-left: 20px;
}

.saveFile {
	position: absolute;
	left: 0;
	opacity: 0;
	top: 0;
	bottom: 0;
	width: 100%;
}

.saveLabel {
	display: inline-block;
	position: relative;
	height: 40px;
	width: 200px;
	margin-left: 20px;
}

.makeOrderable {
	position: absolute;
	left: 0;
	opacity: 0;
	top: 0;
	bottom: 0;
	width: 100%;
}

.makeOrderableLabel {
	display: inline-block;
	position: relative;
	height: 40px;
	width: 200px;
}

.uplodadFileList a {
	display: block;
}

.themeBtn {
	border-radius: 4px;
	color: #753BBD;
	padding: 8px 16px;
	border: 1.5px solid #753BBD;
}

.selectFld {
	width: 100%;
	height: 25px;
	border: 0;
	border-bottom: 1px solid #ccc;
	font-size: 12px;
	position: relative;
	background: url(../../images/svg/ico-select.svg) no-repeat center right 5px;
	background-size: 15px 7px;
	border-radius: 0;
	padding-left: 5px;
	padding-top: 0;
	padding-bottom: 0;
	width: 200px;
}

	.selectFld option {
		font-size: 13px;
	}

.uploadText {
	font-size: 16px;
	color: #753BBD;
	position: absolute;
}

.uploadBtn {
	border: 1.5px solid #753BBD;
	border-radius: 5px;
	color: #753BBD;
	font-size: 16px;
	text-transform: capitalize;
	cursor: pointer;
	height: 40px;
	vertical-align: top;
	margin-left: 20px;
}

.uplodadFileList {
	margin-top: 30px;
}

.lableTextS {
	font-size: 12px;
	color: #753BBD;
	display: block;
	margin: 5px 0;
	display: block;
}

.filterDate {
	font-size: 12px;
	color: #999;
}

.noticesSec {
	border-radius: 5px;
	/* width: 88%; */
}

	.noticesSec p {
		font-size: 16px;
		text-align: justify;
		padding: 15px;
		margin-bottom: 0;
		padding-bottom: 0;
		background-color: #eee;
	}

		.noticesSec p:last-child {
			padding-bottom: 15px;
		}

.lastSec {
	margin-bottom: 100px;
}

.loopTable {
	height: 40px;
}

td {
	font-size: 14px;
}

.hidden {
	visibility: hidden;
	background: #eee;
}

.borderLeft {
	border-left: 1px solid #dee2e6;
}

.tableWrap {
	/* border-top: 1px solid #999; */
}

.labelPadding {
	padding: 0 0 0 10px;
}

.quoteLink {
	color: #753BBD;
	font-size: 16px;
	display: block;
}

.subHdrAlter {
	margin-top: 24px;
}

.contentQueryPage {
	overflow-y: inherit;
	height: auto;
	min-height: auto;
}

.downloadbtnWrap {
	position: relative;
	text-align: right;
}

	.downloadbtnWrap button {
		border-radius: 5px;
		padding: 0 58px 0 16px;
	}

		.downloadbtnWrap button a {
			line-height: 46px;
		}

	.downloadbtnWrap strong {
		font-weight: 100;
		font-size: 18px;
	}

	.downloadbtnWrap svg {
		width: 15px;
	}

.dtHorizontalExample {
	font-size: 14px !important;
	line-height: 20px !important;
	text-align: justify;
}

.pageActions {
	position: absolute;
	left: 0;
	bottom: 24px;
	width: 100%;
}

	.pageActions > div {
		padding: 0 24px 0 24px;
	}

.unitiTagText {
	font-size: 14px;
	font-weight: bold;
	color: #753BBD;
	display: block;
	margin: 5px 0;
}

.pricingCheckboxLabel {
	font-size: 14px;
}

.tableIcb {
	display: table-cell;
	width: auto !important;
}

	.tableIcb input[type=text] {
		font-size: 14px;
		width: 75%;
	}


	.tableIcb thead {
		background-color: #FFF;
		/* border-top: 1px solid #dee2e6; */
	}

	.tableIcb tbody {
		background-color: #eee;
	}

	.tableIcb tr {
		border-bottom: 1px solid #ddd;
	}

	.tableIcb th {
		border: 0 !important;
		white-space: nowrap;
	}

	.tableIcb td {
		width: 25%;
		font-size: 14px;
		color: #212529;
		white-space: nowrap;
	}

.specialComments {
	white-space: pre-line;
}

.variablePricingRow {
	flex-wrap: nowrap !important;
}

.smallText {
	font-size: 10px !important;
	font-style: italic;
}

.circuitBtn {
	border: 1.5px solid #753BBD;
	border-radius: 5px;
	color: #753BBD;
	font-size: 16px;
	text-transform: capitalize;
	cursor: pointer;
	height: 40px;
	vertical-align: top;
	margin-left: 20px;
}

/* hack to fix the calendar icon on the Quote View we will probably want to revist when we clean the css up */
input[type=date]::after, input[type=date]::-webkit-calendar-picker-indicator {
	display: block ;
	background: white url(../../images/svg/ico-calendar-on-light.svg) no-repeat right;
	background-size: contain !important;
	width: 20px;
	height: 20px;
	background-size: 80%;
	right: 0;
	cursor: pointer;
	content: none !important;
}
