.quoteDetailsPage {
	background-color: #FFF;
	width: 75%;
	height: auto !important;
	overflow-y: inherit;
}

.quoteBulkDetailsPage {
	background-color: #FFFFFF;
	overflow-y: inherit;
	overflow-x: scroll;
	width: 100%;
	height: 100%;
}

.quoteTabs {
	background-color: rgba(255, 255, 255, .1);
	width: 25%;
	margin-bottom: 130px;
}

.dFlex {
	display: flex;
}

.spaceBetween {
	justify-content: space-between;
}

.sectionTitle {
	font-size: 1.5em;
	margin-bottom: 39px;
}

.pageAction {
	left: auto;
	right: 0;
	width: auto;
}

	.pageAction button {
		line-height: 46px;
		font-size: 22px;
		border-radius: 4px;
		font-family: "montserrat", sans-serif !important;
		font-weight: 200;
		font-style: normal;
	}

.manageQuoteDesc {
	color: #75787B;
	font-size: 16px;
}

.filter-label {
	margin: 0;
	display: none;
}

.filter.text-filter {
	text-align: left;
}

.filter.text-filter {
	/*border: 0;*/
	/*border-bottom: 1px solid #dee2e6;*/
	/*border-radius: 0;*/
	/*background-size: 20px;*/
	/*padding-left: 30px;*/
}

	.filter.text-filter:focus {
		box-shadow: none;
		outline: 0;
	}

.filterWrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 25px;
}

	.filterWrap button svg {
		left: 20px;
		top: -7px;
	}

.quotetable {
	height: 45vh;
	overflow-y: auto;
	overflow-x: hidden;
}

	.quotetable::-webkit-scrollbar-thumb {
		height: 50px;
	}

.quoteName {
	font-size: 16px;
	color: #75787B;
	font-family: "montserrat", sans-serif !important;
	font-weight: 700;
	font-style: normal;
	margin: 0;
	line-height: 46px;
}

.rowGrp, .colGrp {
	margin: 10px 0;
}

.productList {
	margin: 0;
	list-style-type: none;
	width: 100%;
	display: flex;
	height: 100%;
}

	.productList li {
		display: inline-block;
		padding: 10px;
		background-color: #ccc;
		width: 40%;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		font-size: 16px;
		color: #000;
		font-weight: 700;
		height: 100%;
	}

		.productList li:last-child {
			background-color: #eee;
			width: 60%;
			border-radius: 0 5px 5px 0;
			font-weight: 400;
			height: 100%;
		}

.uploadFile {
	position: absolute;
	left: 0;
	opacity: 0;
	top: 0;
	bottom: 0;
	width: 100%;
}

.uploadLabelText {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	border: 1.5px solid #753BBD;
	border-radius: 5px;
	color: #753BBD;
	font-size: 16px;
	text-transform: capitalize;
	cursor: pointer;
}

.uploadLabel {
	display: inline-block;
	position: relative;
	height: 40px;
	width: 120px;
	margin-left: 20px;
}

.uploadArrow {
	position: absolute;
	right: 150px;
	width: 200px;
	height: 120px;
	border: 3px solid green;
}

.saveFile {
	position: absolute;
	left: 0;
	opacity: 0;
	top: 0;
	bottom: 0;
	width: 100%;
}

.saveLabel {
	display: inline-block;
	position: relative;
	height: 40px;
	width: 300px;
	text-align: left;
	margin-left: 20px;
}

.pagingButton button {
	position: relative;
	height: 40px;
	width: 300px;
	text-align: left;
	margin-left: 20px;
}

.uplodadFileList a {
	display: block;
}

.themeBtn {
	border-radius: 4px;
	color: #753BBD;
	padding: 8px 16px;
	border: 1.5px solid #753BBD;
}

.selectFld {
	width: 100%;
	height: 25px;
	border: 0;
	border-bottom: 1px solid #ccc;
	font-size: 12px;
	position: relative;
	background: url(../../images/svg/ico-select.svg) no-repeat center right 5px;
	background-size: 15px 7px;
	border-radius: 0;
	padding-left: 5px;
	padding-top: 0;
	padding-bottom: 0;
	width: 200px;
}

	.selectFld option {
		font-size: 13px;
	}

.uploadText {
	font-size: 16px;
	color: #753BBD;
	position: absolute;
}

.uploadBtn {
	border: 1.5px solid #753BBD;
	border-radius: 5px;
	color: #753BBD;
	font-size: 16px;
	text-transform: capitalize;
	cursor: pointer;
	height: 40px;
	vertical-align: top;
	margin-left: 20px;
}

.uplodadFileList {
	margin-top: 30px;
}

.lableTextS {
	font-size: 12px;
	color: #753BBD;
	display: block;
	margin: 5px 0;
	display: block;
}

.filterDate {
	font-size: 12px;
	color: #999;
}

.lastSec {
	margin-bottom: 100px;
}

.loopTable {
	height: 40px;
}

.hidden {
	visibility: hidden;
	background: #eee;
}

.borderLeft {
	border-left: 1px solid #dee2e6;
}

.tableWrap {
	/* border-top: 1px solid #999; */
}

.labelPadding {
	padding: 0 0 0 10px;
}

.quoteLink {
	color: #753BBD;
	font-size: 16px;
	display: block;
}

.subHdrAlter {
	margin-top: 24px;
}

.contentQueryPage {
	overflow-y: inherit;
	height: auto;
	min-height: auto;
}

/**/

.downloadbtnWrap {
	position: relative;
	text-align: right;
}

	.downloadbtnWrap button {
		border-radius: 5px;
		padding: 0 16px 0 16px;
	}

		.downloadbtnWrap button a {
			line-height: 46px;
		}

	.downloadbtnWrap strong {
		font-weight: 100;
		font-size: 18px;
	}

	.downloadbtnWrap svg {
		width: 15px;
	}

.bulkDownloadbtnWrap {
	position: relative;
	text-align: right;
}

	.bulkDownloadbtnWrap button {
		border-radius: 5px;
		padding: 0 10px 0 10px;
	}

		.bulkDownloadbtnWrap button a {
			line-height: 46px;
		}

	.bulkDownloadbtnWrap strong {
		font-weight: 100;
		font-size: 18px;
	}

	.bulkDownloadbtnWrap svg {
		width: 15px;
	}

.btn svg, button svg {
	position: relative;
	right: -67px;
}

.quote-actions .btn svg, button svg {
	position: relative;
	right: -20px;
}

.bulkQuoteTable td {
	font-size: 9px;
}

.bulkQuotesTableWrap .react-bootstrap-table-pagination-list {
	z-index: 1;
}

input.bulkOrderCheckbox {
	width: 9px;
	height: 9px;
}

.bulkQuoteTable.filter .bulkQuoteTable.text-filter .bulkQuoteTable.form-control .bulkQuoteTable.placeHolderInput {
	padding: 0;
	margin: 0;
}

.subHeader {
	margin-top: 35px;
}

.bulkQuoteLabel {
	font-size: 12px;
	word-wrap: normal;
	vertical-align: center;
	/*padding: 0 0 0 9px;*/
	background-clip: padding-box;
	background-color: transparent;
}

.bulkQuoteLink {
	color: #753BBD;
	font-size: 12px;
	display: block;
}
