.layerWrap {
    margin: 30px;
    border: 1px solid #ccc;
}
.layerTitle {
    background: #000;
    padding: 10px;
    color: #fff;
}
.innerLayer {
    padding: 10px 20px;
    background: #f5f5f5;
}
.innerLayer table tr td:first-child {
    width: 200px;
    color: #000;
}
.innerLayer table tr td {
    font-size: 14px;
}
.infoTip {
    background: #753bbd;
    border-radius: 43px;
    color: #FFF;
    cursor: pointer;
    display: inline-block !important;
    margin-left: 6px;
    padding: 1px 10px;
}