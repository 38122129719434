/* NotificationUpdater.module.css */
.HomeUpdaterRowGrp {
    margin: 10px 30px 30px 0px;
}

.HomeUpdaterLabelTextS {
    margin: 0;
    font-size: 16px;
    color: #753bbd;
}

.HomeUpdaterInputFld {
    width: 100%;
    height: 25px;
    font-size: 16px !important;
    border: 0;
    border-bottom: 1px solid #ccc;
    padding-left: 5px;
}

.textareaFld {
    width: 100%;
    height: 100px;
    font-size: 16px !important;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 4px;
}

.HomeUpdaterResponseMessage {
    margin-top: 20px;
    text-align: center;
    font-size: 1rem;
    color: #e74c3c;
}

.flexRow {
    display: flex;
    justify-content: space-between;
}

.ButtonRowGrp {
    margin: 10px 30px 30px 0px;
    display: flex;
    justify-content: center !important;
}

.smallText {
    text-transform: uppercase;
    font-size: 10px;
    color: #753BBD;
}

.tab-opt {
    padding: 10px 20px;
    text-decoration: none;
    color: #000;
  }

  .tab-opt.active {
    background-color: #f0f0f0;
    font-weight: bold;
    border-bottom: 2px solid #000;
  }

  .checkboxGroup {
    display: flex;
    gap: 20px;
    align-items: center;
}

.ico-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.ico-button.light-button {
    background: #f0f0f0;
    color: #000;
}

.ico-button svg {
    margin-left: 10px;
}

.buttonSpacing {
    margin-right: 10px;
}
