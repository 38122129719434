.productLink {
    position: absolute;
    z-index: 3;
    text-align: center;
    width: 280px;
    bottom: 12px;
    font-size: 22px;
    color: #292A2C;
    font-weight: 700 !important;
}
.productLinkActive {
    color: #FFF !important;
    background-color: #753BBD;
    border: 1px solid #753BBD;
}
.fWrap {
    flex-wrap: wrap;
}
.spaceAround {
    justify-content: space-around;
}
.borderLeft2px {
    border-left: 1.5px solid #75787B;
}
.productSubType {
    margin-left: 10px;
}

input[type=date] {
    color: #753BBD;
    position: relative;
    border: none;
    width: 150px;
    padding-left: 12px;
    font-size: 16px !important;
}

input[type="time"] {
    color: #753BBD;
    position: relative;
    border: none;
    width: 150px;
    padding-left: 12px;
    font-size: 16px !important;
    appearance: none; /* Reset the default appearance */
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background: white url('../../images/svg/ico-time-sm-light.svg') no-repeat center;
    background-size: 100% !important;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0; /* Hide the default indicator */
}

input[type="time"]::before {
    content: '';
    background: white url('../../images/svg/ico-time-sm-light.svg') no-repeat center;
    background-size: 100% !important;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.inputField, .inputFieldHalf, .dateFld {
    border: 1px solid #999 !important;
    color: #999;
    border-radius: 0;
    width: 100%;
    height: 38px;
    padding-left: 10px;
}
.inputField:focus, .inputFieldHalf:focus, .dateFld:focus {
    outline: 0;
    box-shadow: none;
}
.inputFieldHalf {
    width:40%;
}
.textarea {
    height: 65px;
}
.dateField:after {
    width: 25px !important;
    height: 20px !important;
    right: 5px !important;
}

.btnTheme {
    background: #753BBD;
    line-height: inherit;
    border-radius: 0;
    color: #FFF;

}
.selectWrap::after {
    content: '';
    height: 15px;
    width: 15px;
    position: absolute;
    top:50%;
    right: 5px;
    transform: translateY(-50%);
    background: url(../../images/svg/ico-select.svg) center center no-repeat;
    background-size: 100%;
}
.mandatory {
    color: #f00;
    font-weight: bolder;
}
.plusIcon {
    font-size: 28px;
    font-weight: lighter;
    display: inline-block;
    vertical-align: sub;
}
.widthLess25px {
    width: calc(100% - 25px) !important;
    margin-left: 25px;
}
.disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}
.rotateArrow {
    transform: rotate(180deg);
    top: 3px !important;
}
.prevArrow {
    position: absolute;
    top: calc(50% - 0px) !important;
    left: 0px !important;
    width: 36px;
    height: 100% !important;
    transform: translateY(-50%) rotate(180deg);
}
.overly {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: #000;
    opacity: 0.5;
}
.resetBtn {
    border: 1px solid #f00;
    line-height: inherit;
    margin: 30px 0;
    font-size: 14px;
    border-radius: 0;
    color: #f00;
    margin-top: 0;
    text-transform: uppercase;
}

.dateFld::after {
    display: none !important;
    background: transparent !important;
}
.errorFld {
    border: 1px solid #f00 !important;
}
.errorFldText {
    color:#f00;
    font-weight: 900;
}
.productThumb {
    width: 284px;
    height: 154px;
    position: relative;
    display: inline-block;
    cursor: pointer;
    /* -webkit-box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 50%);
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 50%); */
    transition: -webkit-box-shadow .4s, box-shadow .4s, opacity .4s;
    margin:25px;
    border: 2px solid transparent;
}
.productThumb:hover {
    -webkit-box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 70%);
    box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 70%);
    transition: -webkit-box-shadow .4s, box-shadow .4s;
}

.productType {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}
.productImg {
    position: relative;
    display: block;
    width: 192px;
    height: 80px;
    margin: 8px auto 0 auto;
}
.productThumb:hover, .productThumb.selected {
    border: 2px solid #ff9e2e;
    transition: .4s;
}
.ptCheck {
    position: absolute;
    z-index: 4;
    bottom: 16px;
    right: 16px;
    display: none;
}
.productThumb.selected {
    -webkit-box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 70%);
    box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 70%);
    opacity: 1;

}
.productThumb.selected .ptCheck {
    display: block;
}
.productThumb .ptCheck path {
    fill: #EF9123;
}
.productThumb.notSelected {
    opacity: .2;
}

.subGrp {
    display: inline-block;
    min-width: 160px;
    text-align: center;
    height: 101px;
    position: relative;
    cursor: pointer;
    margin: 25px;
}
.subGrp button:hover, .subGrp button.selected {
    color: #EF9123 !important;
}
.subGrp button:hover svg path, .subGrp button.selected svg path {
    fill: #EF9123 !important;
}
.subGrp button.selected svg.configOptIco {
    display: none;
}
.configOptCheck {
    display: none;
    margin: 0 auto;
    fill: #EF9123;
    position: unset;
}
.subGrp button.selected .configOptCheck {
    display: block;
}

label {
    margin: 0;
}
.labelText {
    color: #753bbd;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 30px;
}

.inputFldText {
    border: 0;
    border-bottom: 1px solid #ccc;
    width: 100%;
    border-radius: 0;
}
.inputFld {
    width: 100%;
    height: 25px;
    font-size: 16px;
    border: 0;
    border-bottom: 1px solid #ccc;
    padding-left: 5px;

}
.inputCityFld {
    width: 100%;
    height: 25px;
    font-size: 12px;
    border: 0;
    border-bottom: 1px solid #ccc;
}
.textareaFld {
    width: 100%;
    height: 90px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
    font-size: 16px;

}
.inputFldDate, .inputFldTime {
    width: 30%;
    border: 0;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
}
input[type=date].inputFldDate {
    text-transform: uppercase;
    font-size: 11px;
    border-bottom: 1px solid #ccc !important;
    padding-left: 0;
}
input[type=date].inputFldDate::after {
    background-size: contain;
    width: 22px;
    height: 22px;
    position: absolute;
    right: 0px;
    bottom: 2px;

}
.inputFldTime {
    margin-left: 20px;
}
.lableValueFld {
    font-size: 16px;
    color: #75787B;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
}
input[type=radio] {
    vertical-align: middle;
    margin-right: 3px;
}
input[type=text] {
    font-size: 16px;
}
.timeZoneFld {
	width: 20% !important
}
.selectFld {
    height: 25px;
    border: 0;
    border-bottom: 1px solid #ccc;
    font-size: 16px !important;
    position: relative;
    background: url(../../images/svg/ico-select.svg) no-repeat center right 5px;
    background-size: 15px 7px;
    border-radius: 0;
    padding-left: 5px;
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
    font-size: 16px !important;
    color:#753BBD
}
.selectFld option {
    font-size: 16px !important;
    color:#753BBD
}
.configOptIco {
    display: block;
    margin: 0 auto;
    position: unset;
}
.configOptIco path {
    fill: #753BBD;
}
.searchFld {
    border-bottom: 1px solid #D0D0D0;
    background: url(../../images/svg/ico-search-filled-light.svg) no-repeat right #ffffff;
    background-position-x: 4px;
    background-size: 24px 24px;
    padding: 10px;
    padding-left: 44px;
    font-size: 16px !important;
    margin-top: 15px;
}
.previousBtn {
    padding: 5px 10px !important;
    border-radius: 5px;
    margin-left: 30px !important;
}
.previousBtn svg {
    position: unset;
    padding-top: 2px;
}
.prevBtn {
    padding-left: 50px !important;
}
.prevBtn svg {
    position: absolute;
    top: calc(50% - 0px) !important;
    left: 0px !important;
    width: 36px;
    height: 100% !important;
    transform: translateY(-50%) rotate(180deg);
}
.nextBtn {
    padding-left: 16px !important;
    border-radius: 5px;
}
.nextBtn svg {
    position: unset;
    margin-top: 4px;
    margin-right: 0;
    margin-left: 10px;
}
.cancelBtn {
    padding: 3px 10px !important;
    border-radius: 5px;
}
.cancelBtn svg {
    position: unset;
    margin: 11px 0;
    margin-left: 10px;
}
.submitBtn svg {
    padding: 5px 10px !important;
    border-radius: 5px;
    height: 50px;
    position: unset;
}
.rowGap {
    margin-bottom: 80px;
}
.ticketCreatePage {
    overflow-y: inherit;
}

.hrsFld {
    width: calc(25% - 20px);
}
.extField {
    position: absolute;
    top: 0;
    left: 0;
}

.pageActions {
    position: absolute;
    left: 0;
    bottom: 24px;
    width: 100%;
}

.pageActions > div {
    padding: 0 24px 0 24px;
}
