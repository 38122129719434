.selectWrap {
    width: 350px;
}
.selectWrap svg path {
    fill: #753bbd;
}

.selectWrap .profile-autoComplete__indicator .profile-autoComplete__clear-indicator {
    display: none;
}

.mandatory, .errorMsg {
    color: #f00 !important;
    font-weight: bolder;
}
.errorMsg {
    font-size: 12px;
    font-weight: 400;
}
label {
    margin: 0;
}
.labelText {
    color: #753bbd;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 30px;
    text-transform: uppercase;
}
.userIdText {
    color: #753bbd;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
}
.inputFldText {
	border: 0;
	border-bottom: 1px solid #ccc;
	width: 400px;
	display: block;
	border-radius: 0;
	height: 40px;
	font-size: 16px !important;
}
.selectFld {
    border: 0;
    border-bottom: 1px solid #ccc;
    width: 400px;
    font-size: 12px;
    color: #753BBD;
    height: 40px;
    appearance: auto;
    display: block;
}
.inputFldPhone {
    width: 150px;
}
.quotingOption .labelText {
    margin-left: 10px;
    text-transform: none;
}
.agentLabelText {
	margin-left: 10px !important;
	text-transform: none;
}
.nextBtn {
    padding: 5px 10px !important;
    border-radius: 5px;
}
.nextBtn svg {
    position: unset;
    padding-top: 2px;
}
.cancelBtn {
    padding: 3px 10px !important;
    border-radius: 5px;
}
.cancelBtn svg {
    position: unset;
    margin: 11px 0;
    margin-left: 10px;
}
.overly {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: #000;
    opacity: 0.5;
}
.uploadFile {
    position: absolute;
    left: 0;
    opacity: 0;
    top: 0;
    bottom: 0;
    width: 100%;
}

.uploadLabelText {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 1.5px solid #753BBD;
    border-radius: 5px;
    color: #753BBD;
    font-size: 16px;
    text-transform: capitalize;
    cursor: pointer;
}

.uploadLabel {
    display: inline-block;
    position: relative;
    height: 40px;
    width: 100px;
}

.uploadLabelText.dragover {
    background-color: #aaa;
}
.select {
    height: 40px !important;
    border-bottom: 1px solid #CCC !important;
}
.dividers {
    height: 11px;
    background-color: #eee;
    margin: 0 -24px;
    border-top: 1px solid #ddd;
}
.w45 {
    width: 45%;
}
.placeHolderText::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #753BBD !important;
	opacity: 1; /* Firefox */
}

.placeHolderText:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #753BBD !important;
}

.placeHolderText::-ms-input-placeholder { /* Microsoft Edge */
	color: #753BBD !important;
}

#profileTableFilter thead tr th .filter-label .form-control {
	background-color: #75787b;
	color: white;
}

.downloadBtn {
	display: flex;
	align-items: center;
	justify-content: right;
	/*margin-right: 100px;*/
}
