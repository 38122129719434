/*******Billing Dispute Create*******/

/*Gap between rows*/
.disputeRowGap {
	margin: 10px 30px 30px 0px;
}

/*Dividers between sections*/
.disputeDividers {
    height: 11px;
    background-color: #eee;
    margin: 0 -24px;
    border-top: 1px solid #ddd;
}

/* Label styling for dispute forms */
.disputeLabel {
    margin: 0;
    font-size: 16px;
    color: #753bbd;
    text-transform: uppercase;
}

/* Required field indication */
.disputeRequired {
    font-size: 16px;
    color: #753bbd;
}

/* Highlight mandatory fields */
.disputeMandatory {
    color: #f00; /* Red color for mandatory indicators */
}

/* Input field styling for dispute form */
.disputeInput {
    width: 100%;
    height: 25px;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid #ccc;
    padding-left: 5px;
}

/* Textarea for dispute form */
.disputeTextArea {
    width: 100%;
    height: 150px;
}

/* Dropdown select styling for dispute form */
.disputeSelect {
	height: 25px;
	border: 0;
	border-bottom: 1px solid #ccc;
	font-size: 16px !important;
	position: relative;
	background: url(../../../images/svg/ico-select.svg) no-repeat center right 5px;
	background-size: 15px 7px;
	border-radius: 0;
	padding-left: 5px;
	padding-top: 0;
	padding-bottom: 0;
}

/* Option styling within select fields */
.disputeSelect option {
    font-size: 16px;
}

/*Button at the bottom of Billing Dispute Create*/
.disputeBtn {
    border-radius: 4px;
	color: #753BBD;
	padding-left: 16px !important;
}

.disputeBtn svg {
	position: unset;
	margin-top: 4px;
	margin-right: 0;
	margin-left: 10px;
}

/*******Billing Dispute Details*******/
.commentList,
.detailList li,
.descriptionList li {
    background-color: #ccc;
    border-radius: 5px;
    color: #000;
    font-size: 16px;
    padding: 8px 10px;
}

.commentList {
    margin-top: 10px;
}

.commentList li {
    display: block;
}

.commentList li:first-child {
    font-weight: 700;
}

.detailList,
.descriptionList {
    margin: 0;
    list-style-type: none;
    width: 100%;
    display: flex;
    height: 100%;
}

.detailList li,
.descriptionList li {
    display: inline-block;
    width: 40%;
    font-weight: 700;
    height: 100%;
    word-break: break-word;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.detailList li:last-child,
.descriptionList li:last-child {
    background-color: #eee;
    width: 60%;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-weight: 400;
}

.descriptionList li {
    width: 24%;
}

.descriptionList li:last-child {
    width: 100%;
}

.subSectionTitle {
    font-size: 1.5em;
    margin-bottom: 39px;
}

.rowGap {
    margin: 10px 0;
}

.breakLine {
    white-space: pre-line;
}