.leafletSmallCustomMarker {
    margin-left: -10px !important;
    margin-top: -36px !important;
}
.leafletCustomMarker {
    margin-left: -12px !important;
    margin-top: -41px !important;
}
.leafletTileLayerGreyScale {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
.leafletMap {
    height: 85vh;
    width: 100%;
}
.dropdownMenu {
    height: 85vh;
    overflow-y: auto;
    position: unset !important;
    width: calc(100% - 20px);
}
.individualCheckboxLabel {
    font-size: 14px !important;
    margin-left: 30px !important;
}
.checkboxLabel {
    font-size: 14px !important;
    margin-left: 5px !important;
}
.checkbox {
    height: 16px !important;
    width: 16px !important;
}
.searchByCircuitText::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #753BBD !important;
	opacity: 1; /* Firefox */
}

.searchByCircuitText:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #753BBD !important;
}

.searchByCircuitText::-ms-input-placeholder { /* Microsoft Edge */
	color: #753BBD !important;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: #000;
    opacity: 0.5;
}

.incidentToolTip a{
    color: white;
    text-decoration: underline !important;
}
