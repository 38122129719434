.my-slider .slick-dots {
    position: absolute !important;  /* Ensure it's positioned correctly */
    bottom: -30px;        /* Adjust this value to position the dots lower */
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1000 !important;       /* High z-index to be on top */
}

.my-slider .slick-dots li button:before {
    color: #753BBD !important; /* Dot color */
    font-size: 12px;
}

.my-slider .slick-dots li.slick-active button:before {
    color: #ef9123 !important; /* Active dot color */
}

/* Custom styles for the slider arrows */
.my-slider .slick-prev,
.my-slider .slick-next {
    color: #753BBD;  /* Change arrow color to purple */
    z-index: 1000; /* Ensure arrows are on top */
}

/* Optionally, you can use ::before to ensure the arrows inherit the color */
.my-slider .slick-prev::before,
.my-slider .slick-next::before {
    color: #753BBD; /* Change arrow color to purple */
    font-size: 24px; /* Adjust size as needed */
}

/* Adjust positioning if necessary */
.my-slider .slick-prev {
    left: -35px; /* Adjust the positioning of the left arrow */
}

.my-slider .slick-next {
    right: -35px; /* Adjust the positioning of the right arrow */
}