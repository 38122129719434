.labelFld {
	font-size: 12px;
	display: block;
	text-transform: capitalize;
}

.textArea {
	width: 60%;
	height: 80px;
	border-color: #CCC;
	border-radius: 1px;
}

.notesTab {
	margin-left: 15px;
}

.notesTab li {
	display: inline-block;
	list-style-type: none;
	background-color: #4dc7cc;
	font-size: 16px;
	padding: 5px 10px;
	width: 25%;
	text-align: center;
}

.notesTab li:first-child {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
	border-right: 1px solid #FFF;
}

.notesTab li:last-child {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}

.notesTab li.active {
	background-color: #7202E1;
}

.notesTab li a {
	width: 100%;
	display: block;
}

.notesBtn {
	background-color: #753BBD;
	color: #FFF;
	text-transform: uppercase;
	display: block;
	margin-top: 10px;
	font-size: 14px;
	padding: 8px 10px;
	border-radius: 5px;
}

.notesBtn2 {
	color: #FFF;
}

.noteList {
	background-color: #ccc;
	border-radius: 5px;
	margin-top: 10px;
}

.noteList li {
	display: block;
	color: #000;
	font-size: 16px;
	padding: 8px 10px;
}

.noteList li:first-child {
	font-weight: 700;
}

.productList {
	margin: 0;
	list-style-type: none;
	width: 100%;
	display: flex;
	height: 100%;
}

.productList li {
	display: inline-block;
	padding: 10px;
	background-color: #ccc;
	width: 40%;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	font-size: 16px;
	color: #000;
	font-weight: 700;
	height: 100%;
	word-break: break-word;
}

.productList li:last-child {
	background-color: #eee;
	width: 60%;
	border-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	font-weight: 400;
	height: 100%;
}

.rowGrp {
	margin: 10px 0;
}

.sectionTitle {
	font-size: 1.5em;
	margin-bottom: 39px;
}

.breakLine {
	white-space: pre-line;
}
