.orderCreatePage {
    overflow-y: inherit;
    height: auto;
    min-height: auto;
    padding-bottom: 30px !important;
}
.dividers {
    height: 11px;
    background-color: #eee;
    margin: 0 -24px;
    border-top: 1px solid #ddd;
}
.spaceBetween {
    justify-content: space-between;
}
.sectionTitle {
    font-size: 1.5em;
    margin-bottom: 39px;
}
.subHdrAlter {
    margin-top: 24px;
}
.section1 {
    margin: 30px 0;
}
.labelText {
    text-transform: uppercase;
    font-size: 16px;
    color: #753BBD;
    display: block;
}
.selectFld, .inputFld, .dateFld {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #999;
    color: #75787b;
    height: 30px;
    position: relative;
    font-size: 16px !important;
}
.textAreaFld {
    min-height: 80px;
    width: 100%;
    border: 1px solid #999;
    font-size: 14px;
    color: #75787b;
}
.mandatory {
    font-size: 12px;
    color: #F00;
}
.selectFld {
    background: url(../../images/svg/ico-select.svg) right center no-repeat;
    background-size: 16px;
}
.dateFld {
    width: 100% !important;
    border-bottom: 1px solid #999 !important;
}
.dateFld::after {
    top:50%;
    transform: translateY(-50%) !important;
    height: 30px !important;
    width: 30px !important;


}
.colGrp {
    margin: 20px 0;
}
.cancelBtn, .nextBtn {
    border-radius: 4px;
    color: #753BBD;
}
.nextBtn {
    padding-left: 16px !important;
}
.nextBtn svg {
    position: unset;
    margin-top: 4px;
    margin-right: 0;
    margin-left: 10px;
}
.prevBtn svg {
    transform: rotate(180deg);
    margin-top: 0;
    margin-bottom: 4px;
    margin-left: 0px;
    margin-right: 10px;
}
.cancelBtn {
    padding-right: 16px !important;
    padding-left: 16px !important;

}
.cancelBtn svg {
    position: unset;
    margin: 11px 0;
    margin-left: 10px;
}
.uploadText {
    font-size: 16px;
    color: #753BBD;
    position: absolute;
}
.uploadBtn {
    border: 1.5px solid #753BBD;
    border-radius: 5px;
    color: #753BBD;
    font-size: 16px;
    text-transform: capitalize;
    cursor: pointer;
    height: 40px;
    vertical-align: top;
    margin-left: 20px;
}
.uploadFile {
    position: absolute;
    left: 0;
    opacity: 0;
    top: 0;
    bottom: 0;
    width: 100%;
  }

  .uploadLabelText {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 1.5px solid #753BBD;
    border-radius: 5px;
    color: #753BBD;
    font-size: 16px;
    text-transform: capitalize;
    cursor: pointer;
  }

  .uploadLabel {
    display: inline-block;
    position: relative;
    height: 40px;
    width: 80px;
    margin-left: 20px;
  }

  .uploadLabelText.dragover {
    background-color: #aaa;
  }
.uploadSelect {
    width: 75%;
}
.leaseTable thead {
    background-color: #ccc;
}
.leaseTable tbody {
    background-color: #eee;
}
.leaseTable thead th {
    border: 0;
}
.leaseTable thead th,
.leaseTable tbody td {
    font-size: 16px;
    border-bottom:0;
}
.denoteText {
    margin: 30px 0;
}
.lableTextS {
    font-size: 12px;
    color: #753BBD;
    display: block;
    margin: 5px 0;
    display: block;
}
.filterDate {
    font-size: 12px;
    color: #999;
}
.overly {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: #000;
    opacity: 0.5;
}
a:hover {
    text-decoration: none;
}

/*Jurisdiction Tool Tip*/
.infoTip {
	position: relative;
	padding-left: 10px;
}

.infoTip img {
	height: 22px;
	width: 22px;
}

.contentHover {
	display: none;
}

.infoTip:hover .contentHover {
	display: block;
	width: 400px;
	border: 1px solid #ccc;
	padding: 10px;
	position: absolute;
	top: -20px;
	left: calc( 100% + 5px);
	font-size: 13px;
	border-radius: 3px;
	background-color: #fff;
	z-index: 999;
}

.w_10 {
	width: 10%;
}


/* MACD */

.productThumb {
    width: 284px;
    height: 154px;
    position: relative;
    display: block;
    cursor: pointer;
    transition: -webkit-box-shadow .2s, box-shadow .2s, opacity .2s;
    display: inline-block;
    margin: 0 12px 36px 12px;
    border: 2px solid transparent;
}
.productThumb:hover {
    -webkit-box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 70%);
    box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 70%);
    transition: -webkit-box-shadow .4s, box-shadow .4s;
    border: 2px solid #ff9e2e;
}
.productThumb.notSelected {
    opacity: .2;
}

.productType {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}
.productLink {
    position: absolute;
    z-index: 3;
    text-align: center;
    width: 280px;
    bottom: 12px;
    font-size: 22px;
    color: #292A2C;
    font-weight: 700;
}
.productImg {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid #ffffff;
    top: 0;
    left: 0;
}
.productImgQuotePage {
    position: relative;
    display: block;
    width: 192px;
    height: 80px;
    margin: 8px auto 0 auto;
}
.productThumb:hover .productImg, .productThumb.selected .productImg {
    border: 2px solid #ff9e2e;
    transition: .4s;
}
.ptCheck {
    position: absolute;
    z-index: 4;
    bottom: 16px;
    right: 16px;
    display: none;
}
.productThumb.selected {
    -webkit-box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 70%);
    box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 70%);
    opacity: 1;
    border: 2px solid #ff9e2e;
}
.productThumb.selected .ptCheck {
    display: block;
}
.productThumb .ptCheck path {
    fill: #EF9123;
}
.productThumb.notSelected {
    opacity: .2;
}
.subGrp {
    display: inline-block;
    min-width: 160px;
    text-align: center;
    height: 101px;
    position: relative;
    cursor: pointer;
    margin: 25px;
}
.subGrp a:hover, .subGrp a.selected {
    color: #EF9123 !important;
}
.subGrp a:hover svg path, .subGrp a.selected svg path {
    fill: #EF9123 !important;
}
.subGrp a.selected svg.configOptIco {
    display: none;
}
.configOptCheck {
    display: none;
    margin: 0 auto;
    fill: #EF9123;
}
.subGrp a.selected .configOptCheck {
    display: block;
}
.configOptIco {
    display: block;
    margin: 0 auto;
}
.configOptIco path {
    fill: #753BBD;
}
.disabled {
    opacity: 0.4;
}

.supBtn {
	background-color: #753BBD;
	color: #FFF;
	text-transform: uppercase;
	display: block;
	margin-top: 10px;
	font-size: 14px;
	padding: 8px 10px;
	border-radius: 5px;
}

.uploadFile {
    position: absolute;
    left: 0;
    opacity: 0;
    top: 0;
    bottom: 0;
    width: 100%;
}

.pageActions {
    position: unset;
    left: 0;
    bottom: 24px;
    width: 100%;
}

.pageActions > div {
    padding: 0 24px 0 24px;
}
