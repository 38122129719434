.contentQueryPage {
	overflow-y: inherit;
	height: auto;
	min-height: auto;
}

.subHeader {
	margin-top: 100px;
}

.sectionTitle {
	font-size: 24px;
	/* text-transform: uppercase; */
	margin: 0;
	margin-bottom: 15px;
}

.flexContainer {
	display: flex;
	justify-content: flex-end;
	/* Aligns the checkbox to the right */
	align-items: center;
	/* Center vertically */
	margin-top: 10px;
	/* Add some spacing from the top */
}

/* Editor Styles */
.mandatory {
    font-size: 12px;
    color: #F00;
}

.errorText {
    color: red;
    font-size: 0.8em;
    margin-top: 0.5em;
    display: block; /* Ensure error message appears below the input */
}

.rowGrp,
.colGrp {
	margin: 10px 0;
}

.popDetails {
	margin: 0;
	list-style-type: none;
	width: 100%;
	display: flex;
	height: 100%;
}

.popDetails li {
	display: inline-block;
	padding: 10px;
	background-color: #ccc;
	width: 40%;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	font-size: 16px;
	color: #000;
	font-weight: 700;
	height: 100%;
	word-break: break-word;
}

.popDetails li:last-child {
	background-color: #eee;
	width: 60%;
	border-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	font-weight: 400;
	height: 100%;
}

.selectFld {
	width: 100%;
	height: 25px;
	border: 0;
	border-bottom: 1px solid #ccc;
	font-size: 16px !important;
	position: relative;
	background: url(../../images/svg/ico-select.svg) no-repeat center right 5px;
	background-size: 15px 7px;
	border-radius: 0;
	padding-left: 5px;
	padding-top: 0;
	padding-bottom: 0;
}

.selectFld option {
	font-size: 16px !important;
}

.selectFld[readonly] {
    background-color: #f0f0f0; /* Light gray background */
    color: #6c757d; /* Optional: Gray text color */
    border-color: #ced4da; /* Optional: Light gray border */
    pointer-events: none; /* Disable interactions */
}

.inputFld.error {
    border-color: red;
}

.inputFld[readonly] {
    background-color: #f0f0f0; /* Light gray background */
    color: #6c757d; /* Optional: Gray text color */
    border-color: #ced4da; /* Optional: Light gray border */
    cursor: not-allowed; /* Optional: Cursor indicates not editable */
}

.submitButtonRow {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.clonePopFloat {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
}

/* Logs */
.noteList {
	background-color: #ccc;
	border-radius: 5px;
	margin-top: 10px;
  }

  .noteList li {
	display: block;
	color: #000;
	font-size: 16px;
	padding: 8px 10px;
  }

  .noteList li:first-child {
	font-weight: 700;
  }
