/*****
Widget
*****/

.wgtEmployeeContent {
    margin: 2.5%;
    font-size: 14px;
}

.wgtEmployeeContent p {
    background-color: #292a2c;
}

.wgtEmployeeContent.bg-w p {
    background-color: #292a2c;
}

.wgtEmployeeContent .subEmployeeTile {
    float: left;
    width: 100%;
    margin: 24px 2.35% 0 0;
}

.subEmployeeTile .tileEmployeeContent {
    background-color: #292a2c;
}

.tileEmployeeHdr {
    background-color: #1a1a1c;
    min-height: 50px;
    position: relative;
}

.tileEmployeeHdr h2 {
    line-height: 49px;
    margin-left: 16px;
}

.tileEmployeeHdr::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    top: -2px;
    left: 0;
}

.tileEmployeeContent {
    padding: 10px 16px 10px 16px;
}

.tileEmployeeContent.flush {
    padding: 0;
}

.workListTableDiv {
    padding-right: 2%;
    padding-left: 2%;
}

.myWorkList th {
    font-size: 14px;
}

.myWorkList td {
    color: white;
    font-size: 14px;
}

.myWorkList a {
    color: #4DC7CC;
    font-size: 14px;
}

.addressInputField {
    padding-bottom: 5px !important;
    margin-top: 10px !important;
}

.errorCheckInput {
    border-bottom: 1px solid #dc3545 !important;
}
