@media screen and (max-width: 1280px) {
    .subcat {
        width: 105px;
    }
    .subcat span {
        font-size: 15px;
    }
    .corner-nav .nav-title {
        font-size: 30px;
    }
}
@media screen and (max-width: 1200px) {
    /* .ico {
       
    }
    .subcat .ico {
        
    }
    .subcat span {
        font-size: 14px;
    }
    .topLeft .corner-nav .left-nav-title, .topRight .corner-nav .right-nav-title,
    .bottomLeft .corner-nav .left-nav-title, .bottomRight .corner-nav .right-nav-title {
        font-size: 30px;
    }
    .subcat-nav {
        height: calc(100vh - 340px);
    }
    .topLeft .nav-sub.hvr .left-subs .subcat-nav, .topRight .nav-sub.hvr .right-subs .subcat-nav {
        min-height: 275px;
    } */

    /***********************
    Primary Navigation: Subs
    ***********************/
    .subcat {
        width: 120px;
    }
    .subcat span {
        font-size: 14px;
    }
    .corner-nav .nav-title {
        font-size: 28px;
    }

    .topLeft .nav-sub .left-subs, 
    .topRight .nav-sub .right-subs, 
    .bottomLeft .nav-sub .left-subs, 
    .bottomRight .nav-sub .right-subs { visibility: hidden;  }

    .topRight .nav-sub .right-subs {
        position: absolute;
        left: 0;
        right: 0;
        top: 109px;
        transition-delay:unset;
    }

    .topLeft .nav-sub.hvr .left-subs,
    .bottomLeft .nav-sub.hvr .left-subs {
        visibility:visible;
        margin-left: 320px; /* 280 default */
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
    }

    .topLeft .nav-sub.hvr .left-subs { top: 109px; }
    .bottomLeft .nav-sub.hvr .left-subs { bottom: 109px; }

    .topRight .nav-sub.hvr .right-subs, .bottomRight .nav-sub.hvr .right-subs {
        visibility:visible;
        /* margin-left:-320px; */
        margin-left:0px;
        position: absolute;
        top: 109px;
        background: rgba(0,0,0,.1);
        left: 0;
        right: 0;
        width: 100%;
        transition-delay: .2s;
    }

    .bottomRight .nav-sub.hvr .right-subs {
        top: auto;
        bottom: 109px;
        right: 0;
    }

    .topLeft .nav-sub.hvr .left-subs .subcat-nav,
    .topRight .nav-sub.hvr .right-subs .subcat-nav {
        top: 100px;
        bottom: auto;
        margin:0;
        position:absolute;
        /*height:auto; */
        min-height:340px;
    }

    .topLeft .nav-sub.hvr .left-subs .subcat-nav { left: 0; }
    .topRight .nav-sub.hvr .right-subs .subcat-nav {  right: 0; }


    .subcat-trim-l { width:1%; top:-109px; background-color:#ffffff; border-left:4px solid #000; }
    .topRight .nav-sub.hvr .subcat-trim-l { width:100%; transition:width .4s ease; }

    .right-subs { margin-right:550px; }

    .topRight .nav-sub .subcat-nav .subcat { float: left;}

    .subcat-user { top:0; }

    /************************
    Primary Navigation: Title
    ***********************/

    .topLeft .corner-nav .left-nav-title, .bottomLeft .corner-nav .left-nav-title { right:100px; }
    .topRight .corner-nav .right-nav-title, .bottomRight .corner-nav .right-nav-title { left:100px; }

    .topLeft .corner-nav:hover .left-nav-title,
    .bottomLeft .corner-nav:hover .left-nav-title, 
    .left-nav-title.hvr { right: 100px; }

    .topRight .corner-nav:hover .right-nav-title,
    .bottomRight .corner-nav:hover .right-nav-title, 
    .right-nav-title.hvr { left: 100px; }
    
}

@media screen and (max-width: 992px) {

}

@media screen and (max-width: 768px) {
    /* .left-subs .subcat {
        float: none;
    }
    .subcat-nav .subcat {
        float: left;
    }
    .topLeft .nav-sub.hvr .left-subs .subcat-nav {
        left: 120px;
    }
    .topLeft .nav-sub.hvr .left-subs .subcat-nav, .topRight .nav-sub.hvr .right-subs .subcat-nav {
        top: 0px;
    }
    .topLeft .nav-sub.hvr .left-subs, .bottomLeft .nav-sub.hvr .left-subs {
        margin-left: 285px;
    } */
}
