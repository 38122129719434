.labelFld {
	font-size: 12px;
	display: block;
	text-transform: capitalize;
  }

  .textArea {
	width: 60%;
	height: 80px;
	border-color: #CCC;
	border-radius: 1px;
  }

  .notesBtn {
	background-color: #753BBD;
	color: #FFF;
	text-transform: uppercase;
	display: block;
	margin-top: 10px;
	font-size: 14px;
	padding: 8px 10px;
	border-radius: 5px;
  }

  .notesBtn2 {
	color: #FFF;
  }

  .noteList {
	background-color: #ccc;
	border-radius: 5px;
	margin-top: 10px;
  }

  .noteList li {
	display: block;
	color: #000;
	font-size: 16px;
	padding: 8px 10px;
  }

  .noteList li:first-child {
	font-weight: 700;
  }

  .productList {
	margin: 0;
	list-style-type: none;
	width: 100%;
	display: flex;
	height: 100%;
  }

  .productList li {
	display: inline-block;
	padding: 10px;
	background-color: #ccc;
	width: 40%;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	font-size: 16px;
	color: #000;
	font-weight: 700;
	height: 100%;
	word-break: break-word;
  }

  .productList li:last-child {
	background-color: #eee;
	width: 60%;
	border-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	font-weight: 400;
	height: 100%;
  }

  .rowGrp {
	margin: 10px 0;
  }

  .sectionTitle {
	font-size: 1.5em;
	margin-bottom: 39px;
  }

  .breakLine {
	white-space: pre-line;
  }

  .pagination {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
  }

  .pageItem {
	margin: 0 5px;
  }

  .pageLink {
	background-color: transparent;
	border: none;
	color: #753BBD; /* Purple color */
	padding: 8px 12px;
	border-radius: 5px;
	cursor: pointer;
  }

  .pageLink:hover,
  .pageItem.active .pageLink {
	background-color: #753BBD; /* Purple color */
	color: #fff;
  }

  .subHdrAlter {
	display: flex;
	align-items: center;
	justify-content: space-between;
  }

  .refreshButton {
	margin-left: auto;
  }