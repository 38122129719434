input, input:focus, input:hover,
textarea, textarea:hover, textarea:focus,
select, select:hover, select:focus {
	outline: none;
	box-shadow: none !important;
}

.contentQueryPage {
	overflow-y: inherit;
	height: auto;
	min-height: auto;
}

.stepsOuter {
	margin: 25px 0;
}

.cancelBtn, .nextBtn {
	border-radius: 4px;
	color: #753BBD;
}

.nextBtn {
	padding-left: 16px !important;
}

	.nextBtn svg {
		position: unset;
		margin-top: 4px;
		margin-right: 0;
		margin-left: 10px;
	}

.prevBtn svg {
	transform: rotate(180deg);
	margin-top: 0;
	margin-bottom: 4px;
	margin-left: 0px;
	margin-right: 10px;
}

.cancelBtn {
	padding-right: 16px !important;
	padding-left: 16px !important;
}

	.cancelBtn svg {
		position: unset;
		margin: 11px 0;
		margin-left: 10px;
	}

.errorFldText {
	color: #f00;
}

.productThumb {
	width: 284px;
	height: 154px;
	position: relative;
	display: block;
	cursor: pointer;
	transition: -webkit-box-shadow .2s, box-shadow .2s, opacity .2s;
	display: inline-block;
	margin: 0 12px 36px 12px;
	border: 2px solid transparent;
}

	.productThumb:hover {
		-webkit-box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 70%);
		box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 70%);
		transition: -webkit-box-shadow .4s, box-shadow .4s;
		border: 2px solid #ff9e2e;
	}

	.productThumb.notSelected {
		opacity: .2;
	}

.productType {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
}

.productLink {
	position: absolute;
	z-index: 3;
	text-align: center;
	width: 280px;
	bottom: 12px;
	font-size: 22px;
	color: #292A2C;
	font-weight: 600 !important;
}

.productImg {
	z-index: 1;
	position: absolute;
	width: 100%;
	height: 100%;
	border: 2px solid #ffffff;
	top: 0;
	left: 0;
}

.productImgQuotePage {
	position: relative;
	display: block;
	width: 192px;
	height: 80px;
	margin: 8px auto 0 auto;
}

.productThumb:hover .productImg, .productThumb.selected .productImg {
	border: 2px solid #ff9e2e;
	transition: .4s;
}

.ptCheck {
	position: absolute;
	z-index: 4;
	bottom: 16px;
	right: 16px;
	display: none;
}

.productThumb.selected {
	-webkit-box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 70%);
	box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 70%);
	opacity: 1;
	border: 2px solid #ff9e2e;
}

	.productThumb.selected .ptCheck {
		display: block;
	}

.productThumb .ptCheck path {
	fill: #EF9123;
}

.productThumb.notSelected {
	opacity: .2;
}

.rowGrp {
	margin: 10px 30px 30px 0px;
}

.lableText {
	margin-bottom: 20px;
	font-size: 13px;
	color: #753BBD;
	display: block;
}

.lableTextS {
	margin: 0;
	font-size: 16px;
	color: #753BBD;
}

.lableTextB {
	font-size: 16px;
	color: #000;
	display: block;
	margin-bottom: 20px;
}

.inputFld {
	width: 100%;
	height: 25px;
	font-size: 16px !important;
	border: 0;
	border-bottom: 1px solid #ccc;
	padding-left: 5px;
}

.inputFldShort {
	width: 50%;
	height: 25px;
	font-size: 16px !important;
	border: 0;
	border-bottom: 1px solid #ccc;
	padding-left: 5px;
}

.selectFld {
	width: 100%;
	height: 25px;
	border: 0;
	border-bottom: 1px solid #ccc;
	font-size: 16px !important;
	position: relative;
	background: url(../../images/svg/ico-select.svg) no-repeat center right 5px;
	background-size: 15px 7px;
	border-radius: 0;
	padding-left: 5px;
	padding-top: 0;
	padding-bottom: 0;
}

	.selectFld option {
		font-size: 16px !important;
	}

.textareaFld {
	width: 100%;
	height: 200px;
	font-size: 16px !important;
	border: 1px solid #ccc;
	padding-left: 5px;
}

hr {
	border-color: #000;
}

.checkboxText {
	margin-left: 5px;
	margin-right: 15px;
	font-size: 16px !important;
	color: #753BBD;
}

.mandatory {
	color: #f00;
}

.labelSelectRequiredFld {
	font-size: 16px !important;
	color: #753BBD;
}

.infoTip {
	position: relative;
}

	.infoTip img {
		height: 22px;
		width: 22px;
	}

.infoTipKMZ {
	position: absolute;
	top: 0px;
	left: 245px;
}

.infoTipVendor {
	position: absolute;
	top: 0px;
	left: 190px;
}

.infoWaveBw {
	position: absolute;
	top: 0px;
	left: 0px;
}

.infoTipProtocol {
	position: absolute;
	top: 50%;
	left: 100%;
	transform: translateY(-50%);
	z-index: 9;
}

.overly {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1050;
	width: 100%;
	height: 100%;
	overflow: hidden;
	outline: 0;
	background-color: #000;
	opacity: 0.5;
}

a:hover {
	text-decoration: none;
}

.contentHover {
	display: none;
}

.infoTip:hover .contentHover {
	display: block;
	width: 400px;
	border: 1px solid #ccc;
	padding: 10px;
	position: absolute;
	top: -20px;
	left: calc( 100% + 5px);
	font-size: 13px;
	border-radius: 3px;
	background-color: #fff;
	z-index: 999;
}

.dividers {
	height: 11px;
	background-color: #eee;
	margin: 0 -24px;
	border-top: 1px solid #ddd;
}

.sectionTitle {
	font-size: 24px;
	/* text-transform: uppercase; */
	margin: 0;
	margin-bottom: 15px;
}

.configSpace {
	margin: 0 10px;
}

.w_10 {
	width: 10%;
}

.w_90 {
	width: 90%;
}

.plusIcon {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	border: 1px solid #753BBD;
	display: block;
	margin: 0 auto;
}

	.plusIcon svg {
		margin: 7px;
	}

		.plusIcon svg path {
			fill: #753BBD;
		}

.fRight {
	float: right;
}

.textBlack {
	color: #000;
}

.subHeader {
	margin-top: 35px;
}

label {
	text-transform: uppercase;
}

.alignFlexEnd {
	align-items: flex-end;
}

.actionBtn {
	width: auto;
}

	.actionBtn svg {
		position: unset !important;
		margin: 0 5px;
	}
/* .bandwidthWrap .rowGrp:nth-child(2) .svgWarp svg:last-child {
    display: none;
} */
.bandwidthWrap .rowGrp:nth-child(3) .svgWarp svg:first-child {
	display: none;
}
/* .bandwidthWrap .rowGrp:nth-child(3) .svgWarp svg:nth-child(2) {
    margin-left: 40px;
} */
.textTransformNone {
	text-transform: none;
}

.uploadFile {
	position: absolute;
	left: 0;
	opacity: 0;
	top: 0;
	bottom: 0;
	width: 100%;
}

.uploadLabelText {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	border: 1.5px solid #753BBD;
	border-radius: 5px;
	color: #753BBD;
	font-size: 16px;
	text-transform: capitalize;
	cursor: pointer;
}

.uploadLabel {
	display: inline-block;
	position: relative;
	height: 40px;
	width: 100px;
}

.uploadLabelText.dragover {
	background-color: #aaa;
}

.uplodadFileList a {
	display: block;
}

.mt__30 {
	margin-top: -30px;
}

.themeBtn {
	border-radius: 4px;
	color: #753BBD;
	padding: 8px 16px;
	border: 1.5px solid #753BBD;
}

.subGrp {
	display: inline-block;
	min-width: 160px;
	text-align: center;
	height: 101px;
	position: relative;
	cursor: pointer;
	margin: 25px;
}

	.subGrp a:hover, .subGrp a.selected {
		color: #EF9123 !important;
	}

		.subGrp a:hover svg path, .subGrp a.selected svg path {
			fill: #EF9123 !important;
		}

		.subGrp a.selected svg.configOptIco {
			display: none;
		}

.configOptCheck {
	display: none;
	margin: 0 auto;
	fill: #EF9123;
}

.subGrp a.selected .configOptCheck {
	display: block;
}

.configOptIco {
	display: block;
	margin: 0 auto;
}

	.configOptIco path {
		fill: #753BBD;
	}

.stepGroup {
	display: flex;
	flex-wrap: wrap;
}

.stepRow {
	flex: 0 0 100%;
}

.waveBwTable th {
	background-color: #ccc;
}

.waveBwTable td {
	background-color: #eee;
	font-size: 16px;
}

.pageActions {
	position: absolute;
	left: 0;
	bottom: 24px;
	width: 100%;
}

	.pageActions > div {
		padding: 0 24px 0 24px;
	}

.dateFld {
	width: 100%;
	border: 0;
	border-bottom: 1px solid #999;
	color: #75787b;
	height: 30px;
	position: relative;
	font-size: 14px !important;
}

.dateFld {
	width: 100% !important;
	border-bottom: 1px solid #999 !important;
}

	.dateFld::after {
		top: 50%;
		transform: translateY(-50%) !important;
		height: 30px !important;
		width: 30px !important;
	}

.renewalDateFld {
	border: none;
	color: #753bbd;
	font-size: 16px !important;
	padding-left: 12px;
	position: relative;
	width: 170px !important;
}

.renewalTextArea {
	overflow: auto;
	resize: vertical;
	width: 100% !important;
	height: 150px;
}

.renewalCheckboxes {
	accent-color: #753bbd;
	height: 20px !important;
	vertical-align: middle;
	width: 20px !important;
}

.renewalCheckboxesLabel {
	color: #753bbd;
	font-size: 12px !important;
	margin-left: 5px;
	margin-right: 7px;
}

/* OfficeSuite */
.osInfoTip {
	position: relative;
	display: inline-flex !important; /* Keeps the icon inline with the text */
	cursor: pointer;
}

.osTextWithInfoTip {
	padding-right: 10px; /* Ensures some space between the text and the info icon */
	display: inline-flex; /* Keeps the text and icon aligned on the same line */
	align-items: center; /* Vertically centers the text with respect to the icon */
}

.osContentHover {
	width: auto; /* Adjust based on your needs */
	max-width: 650px; /* Adjust based on your needs */
	height: auto; /* This will maintain the aspect ratio */
	display: none; /* Hide the tooltip by default */
	z-index: 100;
	background-color: white; /* Background of the tooltip */
	border: 1px solid #ccc; /* Border around the tooltip */
	border-radius: 4px; /* Rounded corners for the tooltip */
	box-shadow: 0 2px 4px rgba(0,0,0,0.18); /* Adds a shadow for better visibility */
	top: 20px; /* Adjust this value as needed to create space between the icon and the tooltip */
	left: 100%; /* Positions the tooltip to the right of the icon */
}

.osInfoTip img.infoImg {
	width: 20px;
	height: 20px;
}

.osContentHover img {
	width: 100%; /* Makes the image fill the container */
	height: auto; /* Maintains the aspect ratio */
}

.osInfoTip:hover .osContentHover {
	display: block; /* Show the tooltip on hover */
}

.smallText {
	text-transform: uppercase;
	font-size: 11px;
	color: #753BBD;
}