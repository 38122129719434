.errorNumber {
    font-family: "montserrat", sans-serif !important;
    font-weight: 200;
    font-style: normal;
    font-size: 50px;
    color: #FFFFFF;
    padding: 125px 0 0 125px;
    margin-bottom: -15px
}

.errorText {
    font-family: "montserrat", sans-serif !important;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    color: #FFFFFF;
    padding: 0 0 0 125px;
}

.errLabelText {
    font-family: "montserrat", sans-serif !important;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    color: #753BBD;
    padding: 0 0 0 125px;
}
