
.mandatory, .errorMsg {
    color: #f00 !important;
    font-weight: bolder;
}

.errorMsg {
    font-size: 12px;
    font-weight: 400;
}

.select {
    height: 40px !important;
    border-bottom: 1px solid #CCC !important;
}

.inputFldText {
    border: 0;
    border-bottom: 1px solid #ccc;
    width: 400px;
    display: block;
    border-radius: 0;
    height: 40px;
    font-size: 16px !important;
}
