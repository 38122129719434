.serviceInfo {
  font-family: Arial, sans-serif;
  margin: 20px auto; /* Center the container */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  width: 50%;
}

.contactInfo {
  margin-bottom: 20px;
}

.dispatchRates {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.dispatchRates table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.dispatchRates th, .dispatchRates td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left !important;
  background-color: white;
}

.dispatchRates th {
  background-color: #ccc;
}

.dispatchRates p {
  margin: 10px 0;
}

.dispatchRates ul {
  list-style-type: disc !important; /* Ensure bullets are displayed */
  padding-left: 40px !important;
  margin: 10px 0 !important;
  display: block !important;
}

.dispatchRates ul li {
  margin-bottom: 10px !important;
  display: list-item !important; /* Ensure list items are displayed as list items */
  list-style-position: inside !important;
}

.escortedSiteAccessHeader {
  font-weight: bold;
  margin-top: 20px;
  text-align: left !important;
}