.contentOrderPage {
	overflow-y: inherit;
	height: auto;
	min-height: auto;
}

.dividers {
	height: 11px;
	background-color: #eee;
	margin: 0 -24px;
	border-top: 1px solid #ddd;
}

.advancedSearchSec button svg {
	top: -7px;
}

.spaceBetween {
	justify-content: space-between;
}

.sectionTitle {
	font-size: 1.5em;
	margin-bottom: 39px;
}

.subHdrAlter {
	margin-top: 24px;
}

.filterWrap {
	display: flex;
	align-items: center;
	justify-content: right;
	/*margin-right: 100px;*/
}

.dateSelect {
	border: 1px solid #ccc;
	border-radius: 12px;
	width: 145px;
	background: url(../../images/svg/ico-time-sm-light.svg) left 10px center no-repeat;
	background-size: 18px;
	padding-left: 40px;
	color: #753BBD;
}

.dateRange {
	border: 1px solid #ccc;
	padding: 8px;
	border-radius: 12px;
	opacity: 0.4;
	transition: 0.15s linear;
}

.dateRange:hover {
	opacity: 1;
	cursor: pointer;
}

.dateRange label {
	font-size: 14px;
	text-transform: capitalize;
}

.filterWrap div {
	margin: 10px;
}

.filterIco button svg {
	position: unset;
}

#quoteSummaryTable {
	margin-top: 100px;
}

#quoteSummaryTable tr td {
	height: 71px;
}

#quoteSummaryTable tr:nth-child(even) {
	background-color: #efefef;
}

#quoteSummaryTable tr td {
	color: #75787B;
}

.filterList {
	display: inline-block;
	margin: 5px 8px;
	font-size: 12px;
	color: #75787b;
}

.filterList:first-child {
	margin-left: 0;
}

.quoteLink {
	color: #753BBD;
	font-size: 16px;
	display: block;
}

.addToggleAlt {
	display: inline-block !important;
	padding-right: 15px;
}

.manageTallyAlt {
	left: auto !important;
	right: 8px !important;
	top: -35px !important;
}

.manageTallyAltSpan {
	font-size: 34px;
	font-weight: 400;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.advancedSearchWrap {
	transition: 0.35s linear;
	height: 0;
	overflow-y: hidden;
}

.advancedSearchAlt {
	height: 100px;
}

.downloadbtnWrap {
	position: relative;
	text-align: right;
}

.downloadbtnWrap button {
	border-radius: 5px;
	padding: 0 58px 0 16px;
}

.downloadbtnWrap button a {
	line-height: 46px;
}

.downloadbtnWrap strong {
	font-weight: 100;
	font-size: 18px;
}

.downloadbtnWrap svg {
	width: 15px;
}

.reportOptionWrap {
	background-color: rgba(0,0,0,.05);
	padding: 12px 16px;
	transition: 0.35s linear;
	border-radius: 10px;
	border-top-right-radius: 0;
}

.reportOptionWrap {
	font-family: "montserrat", sans-serif !important;
	font-weight: 400;
	font-style: normal;
}

.reportFilter h4 {
	color: #292A2C;
	font-size: 22px;
	font-weight: 400;
}

.filterIco {
	position: relative;
}

.filterIcoActive::after {
	content: '';
	display: block;
	position: absolute;
	bottom: -13px;
	right: 0;
	width: 100%;
	height: 4px;
	background-color: #ff9e2e;
}

.tipText {
	font-size: 15px;
}

.paramsList {
	margin: 10px 0;
	width: 80%;
}

.latestSearchWrap {
	padding-left: 50px;
	margin-left: -16px;
	background-color: #FFF;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

.subNib {
	top: 0 !important;
	left: -61px !important;
}

.loadBtn {
	line-height: 28px;
	font-size: 16px;
	text-transform: uppercase;
	padding: 0 12px 0 12px;
	margin-left: 10px;
	border-radius: 5px;
}

.applyBtn {
	padding: 10px 14px;
	border-radius: 4px;
	font-weight: 400;
	font-size: 22px;
}

.labelText {
	text-transform: capitalize;
	font-size: 14px;
	color: #75787b;
	display: block;
}

.dateFld {
	width: 80%;
	border: 0;
	border-bottom: 1px solid #999;
	color: #75787b;
	height: 30px;
	position: relative;
	font-size: 12px !important;
}

.dateFld {
	width: 80% !important;
	border-bottom: 1px solid #999 !important;
}

.dateFld::after {
	top: 50%;
	transform: translateY(-50%) !important;
	height: 30px !important;
	width: 30px !important;
}