.contentQueryPOR {
    overflow-y: inherit;
    height: auto;
    min-height: auto;
}

.sectionTitlePOR {
    font-size: 1.5em;
    margin-bottom: 39px;
}

.subHdrAlterPOR {
    margin-top: 24px;
}

.dataGridContainerPOR {
    height: 90vh;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
}
