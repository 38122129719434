.uploadFile {
    position: absolute;
    left: 0;
    opacity: 0;
    top: 0;
    bottom: 0;
    width: 100%;
}

.uploadLabelText {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 1.5px solid #753BBD;
    border-radius: 5px;
    color: #753BBD;
    font-size: 16px;
    text-transform: capitalize;
    cursor: pointer;
}

.uploadLabel {
    display: inline-block;
    position: relative;
    height: 40px;
    width: 120px;
    margin-left: 20px;
}

/*.saveFile {
	position: absolute;
	left: 0;
	opacity: 0;
	top: 0;
	bottom: 0;
	width: 100%;
}*/

/*.saveFile {
    font-family: "montserrat", sans-serif !important;
    font-weight: 400 !important;
    font-style: normal !important;
    margin: 20px 0 0 0 !important;
}

.fileName {
    font-size: 11px !important;
    min-width: 500px !important;
}

.filterDate {
    font-size: 11px !important;
    min-width: 100px !important;
    color: #999 !important;
}*/

.saveLabel {
    display: inline-block;
    position: relative;
    height: 40px;
    width: 200px;
    margin-left: 20px;
}

.selectFld {
    height: 25px;
    border: 0;
    border-bottom: 1px solid #ccc;
    font-size: 12px;
    position: relative;
    background: url(../../images/svg/ico-select.svg) no-repeat center right 5px;
    background-size: 15px 7px;
    border-radius: 0;
    padding-left: 5px;
    padding-top: 0;
    padding-bottom: 0;
    width: 400px;
}

.selectFld option {
    font-size: 13px;
}

.uploadText {
    font-size: 16px;
    color: #753BBD;
    position: absolute;
}

.uploadBtn {
    border: 1.5px solid #753BBD;
    border-radius: 5px;
    color: #753BBD;
    font-size: 16px;
    text-transform: capitalize;
    cursor: pointer;
    height: 40px;
    vertical-align: top;
    margin-left: 20px;
}

.uplodadFileList {
    margin-top: 30px;
}

.uplodadFileList a {
    display: block;
}

.kmzViewerList {
    height: 85vh;
    overflow-y: auto;
    position: unset !important;
    width: calc(100% - 20px);
}
