.rowGrp {
    margin: 10px 30px 30px 0px;
}
.lableText {
    margin-bottom: 20px;
    font-size: 13px;
    color: #753BBD;
    display: block;
}
.lableTextS {
    margin: 0;
    font-size: 14px;
    color: #753BBD;
}
.lableTextB {
    font-size: 13px;
    color: #000;
    display: block;
    margin-bottom: 20px;

}
.inputFld {
    width: 100%;
    height: 25px;
    font-size: 14px;
    border: 0;
    border-bottom: 1px solid #ccc;
    padding-left: 5px;
}
.selectFld {
    width: 100%;
    height: 25px;
    border: 0;
    border-bottom: 1px solid #ccc;
    font-size: 14px;
    position: relative;
    background: url(../../images/svg/ico-select.svg) no-repeat center right 5px;
    background-size: 15px 7px;
    border-radius: 0;
    padding-left: 5px;
    padding-top: 0;
    padding-bottom: 0;
}
.selectFld option {
    font-size: 13px;
}
.textareaFld {
    width: 100%;
    height: 80px;
    font-size: 14px;
    border: 1px solid #ccc;
    padding-left: 5px;
}

hr {
    border-color: #000;
}
.checkboxText {
    margin-left: 5px;
    margin-right: 15px;
    font-size: 14px;
    color: #753BBD;
}
.mandatory {
    color: #f00;
}
.labelSelectRequiredFld {
    font-size: 14px;
    color: #753BBD;
}

.invoiceTable td {
	max-height: 500px;
	font-size: 14px;
}

.invoiceTable th {
	font-size: 14px;
	color: #753BBD;
}

.parentChildTable td {
	max-height: 500px;
	font-size: 14px;
}

.parentChildTable th {
	font-size: 14px;
	color: #753BBD;
}

.troubleTicketTable td {
	max-height: 500px;
	font-size: 14px;
}

.troubleTicketTable th {
	font-size: 14px;
	color: #753BBD;
}
