.inputWrap {
    align-items: center;
}

.inputWrap .autoComp,
.availablityBtn {
    border: 1px solid #ccc !important;
}

.availablityBtn {
    padding: 7px 15px;
    border-radius: 3px;
    font-size: 13px;
    margin-top: 25px;
    background: #753BBD;
    color: #fff;
}

.map {
    height: 54vh;
    width: 100%;
    overflow: hidden;
    float: left;
}

.mapWrap {
    margin-top: 10px;
    padding: 24px;
}
.footerBtn, .footerBtn:hover {
    background-color: #753BBD;
    color: #fff !important;
    cursor: pointer;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    font-size: 16px !important;
}
.modalDialog {
    width: 400px;
}
.spaceBetween {
    justify-content: space-between !important;
}
.modalContent {
    border-radius: 0;
}
/* .modalBodyCapture {
    height: 75vh;
} */
.modalBody {
    height: 80vh;
}
.modalBodyCapture, .modalBody {
    overflow-y: auto;
    overflow-x: hidden;
}
.modalBodyGE {
    height: 65vh;
    padding: 0;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: #000;
    opacity: 0.5;
}

.routeCreatorDataTable thead, th{
    font-weight: bolder !important;
    text-align: center !important;
    font-size: 14px;
    vertical-align: middle;
}

.routeCreatorDataTable td {
    text-align: center !important;
    vertical-align: middle;
}

.hopsIco path, svg {
    fill: #753BBD !important;
}

.availabilityIco path, svg {
    fill: #753BBD !important;
    right: -10px !important;
}

.availabilityFilterLabels {
    font-size: 12px;
}

.availabilityFilterCheckboxes {
    height: 20px !important;
    width: 20px !important;
}

.availabilityLegend {
    background: #fff;
    padding: 10px;
    margin: 10px;
    border: 3px solid #000 !important;
}

.routeCreatorPadding {
    padding: 0px 24px 0px 24px;
}
