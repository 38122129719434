/* Fonts */
/* Typography */
/*@font-face {*/
/*	font-family:'Montserrat';*/
/*	src:url('../font/Montserrat-Regular.otf');*/
/*	src:url('../font/Montserrat-Regular.otf?#iefix') format('opentype');*/
/*	font-weight:400;*/
/*	font-style:normal;*/
/*}*/
/*@font-face {*/
/*	font-family:'Montserrat-UltraLight.otf';*/
/*	src:url('../font/Montserrat-Bold.otf');*/
/*	src:url('../font/Montserrat-Bold.otf?#iefix') format('opentype');*/
/*	font-weight:600;*/
/*	font-style:normal;*/
/*}*/
/*@font-face {*/
/*	font-family:'Montserrat-UltraLight.otf';*/
/*	src:url('../font/Montserrat-UltraLight.otf');*/
/*	src:url('../font/Montserrat-UltraLight.otf?#iefix') format('opentype');*/
/*	font-weight:200;*/
/*	font-style:normal;*/
/*}*/

@font-face {
	font-family:'Linecons';
	src:url('../font/Linecons.eot');
	src:url('../font/Linecons.eot?#iefix') format('embedded-opentype'),
		url('../font/Linecons.woff') format('woff'),
		url('../font/Linecons.ttf') format('truetype'),
		url('../font/Linecons.svg') format('svg')
}

@font-face {
	font-family:'cgerikh';
	src:url('../font/2021/Linecons.ttf') format('truetype');
}
@font-face {
    font-family: 'ws-glyphsnormal';
    src: url('../font/ws-glyphs-normal-webfont.woff2') format('woff2'),
         url('../font/ws-glyphs-normal-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
	font-family:'Icons';
	src:url('../font/Icons.eot');
	src:url('../font/Icons.eot?#iefix') format('embedded-opentype'),
		url('../font/Icons.woff') format('woff'),
		url('../font/Icons.ttf') format('truetype'),
		url('../font/Icons.svg') format('svg')
}