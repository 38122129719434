.mandatory {
    color: #f00 !important;
    font-weight: bolder;
}

.labelText {
    color: #753bbd;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 30px;
    text-transform: uppercase;
}

.inputFldText {
    border: 0;
    border-bottom: 1px solid #ccc;
    width: 400px;
    display: block;
    border-radius: 0;
    height: 40px;
    font-size: 16px !important;
}

.nextBtn {
    padding: 5px 10px !important;
    border-radius: 5px;
}

.nextBtn svg {
    position: unset;
    padding-top: 2px;
}