
.inputFldText {
	border: 0;
	border-bottom: 1px solid #ccc;
	width: 400px;
	display: block;
	border-radius: 0;
	height: 40px;
	font-size: 16px !important;
}

.nextBtn {
    padding: 5px 10px !important;
    border-radius: 5px;
}
.nextBtn svg {
    position: unset;
    padding-top: 2px;
}
